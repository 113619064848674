import React from 'react'

import {
  DescriptionToSelectItemContainer,
  DescriptionToSelectItemActionButton
} from './styles'
import AddPicto from '../../../../../../../components/Icon/addPicto'
import P from '../../../../../../../components/Text/Paragraph'

type DescriptionToSelectItemProps = {
  description: string
  onAdd: () => void
  disableAdd?: boolean
}

const DescriptionToSelectItem = ({
  description,
  onAdd,
  disableAdd
}: DescriptionToSelectItemProps) => {
  return (
    <DescriptionToSelectItemContainer>
      <P style={{ maxWidth: '85%' }}>{description}</P>
      <DescriptionToSelectItemActionButton
        disabled={disableAdd}
        onClick={onAdd}
      >
        <AddPicto width="12px" color="#059f05" weight={'2'} />
      </DescriptionToSelectItemActionButton>
    </DescriptionToSelectItemContainer>
  )
}

export default DescriptionToSelectItem

import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import { YETIC_NEW_FONT_FAMILY } from '../../../constants/fontFamily'
import Margin from '../../../constants/margin'
import Colors from '../../../constants/colors'

export const SurveyWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
`

export const SurveyNavigationWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
`

export const SurveyContent = styled.div<{ hasAnswers: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const SurveyContentHeader = styled.div`
  margin-bottom: ${Margin.m3};
  position: relative;
`

export const SurveyGoBackButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(6px) translateX(calc(-100% - 24px));
  cursor: pointer;
`

export const SurveyChoiceButtonsWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-direction: column;
`

export const SurveyCenteredContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  max-width: 800px;
`

export const SurveyChoiceButton = styled.button<{
  activeBorderColor?: string
  activeBackgroundColor?: string
  flex: number
}>`
  font-family: ${YETIC_NEW_FONT_FAMILY};
  border: none;
  box-shadow: 0px 0px 4px #142a4c40;
  flex: ${({ flex }) => flex};
  min-width: 300px;
  min-height: 45px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  padding: 8px;
  text-align: left;
  cursor: pointer;

  background-color: rgba(10, 10, 10, 0.1);
  box-shadow: rgba(10, 10, 10, 0.6) 0px 0px 0px 1px inset;
  color: rgb(10, 10, 10);
  border: solid transparent 1px;

  transition: background-color 0.3s ease, border 0.3s ease;

  &:hover {
    transition: all 0.2s;
    border: 1px solid ${Colors.NEW_YETIC_BLUE_DARK};
    background-color: ${Colors.NEW_YETIC_GREY};
  }

  &:focus {
    transition: all 0.2s;
    border: 1px solid ${Colors.NEW_YETIC_BLUE_DARK};
  }
`

export const SurveyChoiceButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const surveyChoiceTitleStyle: CSSProperties = {
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: 0
}

export const surveyChoiceDescriptionStyle: CSSProperties = {
  fontSize: '14px',
  fontWeight: '300',
  marginBottom: 0
}

export const surveyButtonStyle: CSSProperties = {
  marginTop: Margin.m5
}

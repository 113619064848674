import React from 'react'

import { HeaderItemsCountContainer } from './styles'
import Colors from '../../../../../../../constants/colors'
import P from '../../../../../../../components/Text/Paragraph'

type HeaderItemsCountProps = {
  count: number
  min?: number
  max: number
}

const HeaderItemsCount = ({ count, min, max }: HeaderItemsCountProps) => {
  const countColor =
    (min && count < min) || count > max
      ? Colors.NEW_YETIC_RED
      : Colors.NEW_YETIC_BLUE_DARK

  const countWeight = count === max ? 'bold' : '500'

  return (
    <HeaderItemsCountContainer>
      <P
        big
        style={{ color: countColor, marginBottom: 0, fontWeight: countWeight }}
      >
        {count}
      </P>
      <P big>/</P>
      <P big>{max}</P>
    </HeaderItemsCountContainer>
  )
}

export default HeaderItemsCount

import styled from '@emotion/styled'

import Colors from '../../../../../../../constants/colors'
import Padding from '../../../../../../../constants/padding'

export const SelectedDescriptionsListContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100%;
`

export const SelectedDescriptionsEmptyContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SelectedDescriptionItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding: ${Padding.p5} ${Padding.p3};
  border-bottom: 1px solid ${Colors.YETIC_GREY_DARK};
  width: 100%;
`

export const SelectedDescriptionEditingItemContainer = styled(
  SelectedDescriptionItemContainer
)`
  flex-direction: column;
  align-items: flex-end;
`

export const SelectedDescriptionItemActionButtons = styled.div`
  display: flex;
  gap: 8px;
`

export const SelectedDescriptionItemActionButton = styled.button<{
  backgroundColor: string
  hoverBackgroundColor: string
}>`
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 28px;
  height: 28px;
  padding: 0;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    transition: background-color 0.3s;
  }
`

import React from 'react'

import {
  SelfEditorAIFlowHeadlinesGenContentBody,
  SelfEditorAIFlowHeadlinesGenContentColumn,
  SelfEditorAIFlowHeadlinesGenContentGenerateButton,
  SelfEditorAIFlowHeadlinesGenContentWrapper,
  TitlesEmptyContainer,
  invalidSectionTextStyle,
  titleStyle
} from './styles'
import Card from '../components/Card'
import CardHeader from '../components/Card/Header'
import Button from '../../../../components/Button'
import CardContent from '../components/Card/CardContent'
import HeaderItemsCount from '../components/Card/Header/HeaderItemsCount'
import SelectedTitles from '../components/Titles/SelectedTitles'
import TitlesToSelect from '../components/Titles/TitlesToSelect'
import AnimatedLoader from '../../../../components/AnimatedLoader'
import StarsIcon from '../../../../components/Icon/stars'
import Colors from '../../../../constants/colors'
import { maxNumberOfHeadlines, minNumberOfHeadlines } from '../mock/headlines'
import SelfEditorAIFlowHeadlinesGenButtonsBar from './SelfEditorAIFlowHeadlinesGenButtonsBar'
import SelfEditorAIFlowHeadlinesGenModal from './SelfEditorAIFlowHeadlinesGenModal'
import P from '../../../../components/Text/Paragraph'
import Stepper from '../../../../components/Stepper'
import { aiFlowSteps } from '../constants'
import Margin from '../../../../constants/margin'
import H2 from '../../../../components/Text/Headlines/H2'

export type SelfEditorAIFlowHeadlinesGenContentProps = {
  summary: Record<string, string>
  updateSummary: (summary: Record<string, string>) => void
  headlines: string[]
  updateHeadlines: (headlines: string[]) => void
  generatedPendingHeadlines: string[]
  updateGeneratedPendingHeadlines: (generatedPendingHeadlines: string[]) => void
  handleGenerateHeadlines: () => void
  isGenerationPending: boolean
  handleNext: () => void
  handlePrevious: () => void
  isSummaryModalOpened: boolean
  handleClickOpenModal: () => void
}

const SelfEditorAIFlowHeadlinesGenContent = ({
  summary,
  updateSummary,
  headlines,
  updateHeadlines,
  generatedPendingHeadlines,
  updateGeneratedPendingHeadlines,
  handleGenerateHeadlines,
  isGenerationPending,
  handleNext,
  handlePrevious,
  isSummaryModalOpened,
  handleClickOpenModal
}: SelfEditorAIFlowHeadlinesGenContentProps) => {
  const isSectionValid =
    headlines.length >= minNumberOfHeadlines &&
    headlines.length <= maxNumberOfHeadlines

  return (
    <SelfEditorAIFlowHeadlinesGenContentWrapper>
      <Stepper steps={aiFlowSteps} currentIndex={2} marginBottom={Margin.m6} />
      <H2 style={titleStyle}>{`Génération des titres de mon annonce texte`}</H2>
      <SelfEditorAIFlowHeadlinesGenContentBody>
        <SelfEditorAIFlowHeadlinesGenContentColumn>
          <Card style={{ borderRadius: 0, boxShadow: 'none' }}>
            <CardHeader
              title="Génération des titres (3 minimum)"
              action={
                <Button newFont onClick={handleGenerateHeadlines} width="130px">
                  {isGenerationPending ? (
                    <AnimatedLoader color="white" />
                  ) : (
                    <SelfEditorAIFlowHeadlinesGenContentGenerateButton>
                      <StarsIcon color="white" width="16px" />
                      {generatedPendingHeadlines.length > 0
                        ? 'Regénérer'
                        : 'Générer'}
                    </SelfEditorAIFlowHeadlinesGenContentGenerateButton>
                  )}
                </Button>
              }
            />
            <CardContent height="300px">
              {generatedPendingHeadlines.length > 0 ? (
                <TitlesToSelect
                  pendingTitles={generatedPendingHeadlines}
                  onPendingTitlesChange={updateGeneratedPendingHeadlines}
                  selectedTitles={headlines}
                  onSelectedTitlesChange={updateHeadlines}
                  selectedTitlesMax={maxNumberOfHeadlines}
                />
              ) : (
                <TitlesEmptyContainer>
                  <P>{`Cliquez sur "Générer" pour obtenir des titres pour votre annonce`}</P>
                </TitlesEmptyContainer>
              )}
            </CardContent>
          </Card>
        </SelfEditorAIFlowHeadlinesGenContentColumn>
        <SelfEditorAIFlowHeadlinesGenContentColumn>
          <Card
            style={{
              borderRadius: 0,
              boxShadow: 'none',
              borderLeft: `1px solid ${Colors.YETIC_GREY_DARK}`
            }}
          >
            <CardHeader
              title="Titres sélectionnés"
              action={
                <HeaderItemsCount
                  count={headlines.length}
                  max={maxNumberOfHeadlines}
                  min={minNumberOfHeadlines}
                />
              }
            />
            <CardContent height="300px">
              {headlines.length > 0 ? (
                <SelectedTitles
                  titles={headlines}
                  onTitlesChange={updateHeadlines}
                />
              ) : (
                <TitlesEmptyContainer>
                  <P>{`Aucun titre sélectionné`}</P>
                  <P>{`Veuillez ajouter des titres depuis la liste de titres générés`}</P>
                </TitlesEmptyContainer>
              )}
            </CardContent>
          </Card>
        </SelfEditorAIFlowHeadlinesGenContentColumn>
      </SelfEditorAIFlowHeadlinesGenContentBody>
      <SelfEditorAIFlowHeadlinesGenButtonsBar
        isSectionValid={isSectionValid}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleClickOpenModal={handleClickOpenModal}
      />
      {!isSectionValid && (
        <P small style={invalidSectionTextStyle}>
          {`Vous devez sélectionner au moins ${minNumberOfHeadlines} titres pour continuer.`}
        </P>
      )}
      {isSummaryModalOpened && (
        <SelfEditorAIFlowHeadlinesGenModal
          onClose={handleClickOpenModal}
          summary={summary}
          updateSummary={updateSummary}
        />
      )}
    </SelfEditorAIFlowHeadlinesGenContentWrapper>
  )
}

export default SelfEditorAIFlowHeadlinesGenContent

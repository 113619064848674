import React, { CSSProperties, ReactNode } from 'react'

import { CardContentContainer } from '../styles'
import DefaultText from '../../../../../../components/DefaultText'

const CardContent = ({
  children,
  height,
  style
}: {
  children: ReactNode
  height?: string
  style?: CSSProperties
}) => {
  return (
    <CardContentContainer height={height} style={style}>
      {typeof children === 'string' ? (
        <DefaultText size="medium">{children}</DefaultText>
      ) : (
        children
      )}
    </CardContentContainer>
  )
}

export default CardContent

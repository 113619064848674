import React, { CSSProperties, MouseEventHandler } from 'react'

import Colors from '../../constants/colors'

const CalendarPicto = ({
  style,
  color,
  width = '24px',
  height = '24px',
  onClick
}: {
  style?: CSSProperties
  color?: string
  width?: string
  height?: string
  onClick?: MouseEventHandler<SVGElement>
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={color ?? Colors.YETIC_BLUE_DARK}
      className="w-6 h-6"
      style={style}
      width={width}
      height={height}
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
      />
    </svg>
  )
}

export default CalendarPicto

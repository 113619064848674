import React from 'react'

import Colors from '../../../../constants/colors'
import AddPicto from '../../../Icon/addPicto'
import EditIcon from '../../../Icon/edit'
import Trash from '../../../Icon/trash'
import { TagActionEnum } from '../types'

type IconActionByEnumType = {
  [key in TagActionEnum]: {
    icon: () => JSX.Element
    backgroundColor: string
    hoverBackgroundColor: string
  }
}

export const actionStyleByEnum: IconActionByEnumType = {
  DELETE: {
    icon: () => <Trash width="15px" color="#b82727" />,
    backgroundColor: '#bf060630',
    hoverBackgroundColor: '#bf060650'
  },
  EDIT: {
    icon: () => <EditIcon width="15px" />,
    backgroundColor: Colors.YETIC_BACKGROUND_BUTTON,
    hoverBackgroundColor: Colors.YETIC_GREY_DARK
  },
  ADD: {
    icon: () => <AddPicto width="12px" color="#059f05" weight={'1.5'} />,
    backgroundColor: '#0cab0330',
    hoverBackgroundColor: '#0cab0350'
  }
}

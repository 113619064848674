import Dots from './dots'
import ArrowRight from './arrowRight'
import ArrowBack from './arrowBack'
import ArrowNext from './arrowNext'
import ArrowDropDown from './arrowDropDown'
import ArrowDropUp from './arrowDropUp'
import { ArrowCloseLeft, ArrowCloseRight } from './arrowClose'
import ChronometerIcon from './chronometer'

export {
  Dots,
  ArrowRight,
  ArrowBack,
  ArrowNext,
  ArrowDropDown,
  ArrowCloseLeft,
  ArrowCloseRight,
  ArrowDropUp,
  ChronometerIcon
}

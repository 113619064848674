import React, { useEffect, useState } from 'react'

import { mockedTechSurveyData } from '../../../../components/Survey/mockedData/retail2'
import SelfEditorAIFlowContent from '../SelfEditorAIFlowQuestions/Content'
import { SurveyContentType } from '../../../../components/Survey/types'

export type SelfEditorAIFlowQuestionsContainerProps = {
  surveyData: SurveyContentType
  onValidate: (id: string) => void
}

const SelfEditorAIFlowQuestionsContainer = ({
  surveyData,
  onValidate
}: SelfEditorAIFlowQuestionsContainerProps) => {
  const [surveyCurrentQuestionId, setSurveyCurrentQuestionId] = useState('')

  useEffect(() => {
    setSurveyCurrentQuestionId(surveyData[0].id)
  }, [])

  const onSurveyAnswerClick = (answerId: string) => {
    setSurveyCurrentQuestionId(answerId)
  }

  return (
    <SelfEditorAIFlowContent
      content={surveyData}
      currentQuestionId={surveyCurrentQuestionId}
      updateCurrentQuestionId={setSurveyCurrentQuestionId}
      onAnswerClick={onSurveyAnswerClick}
      onClose={() => {
        setSurveyCurrentQuestionId(surveyData[0].id)
      }}
      onValidate={onValidate}
    />
  )
}

export { SelfEditorAIFlowQuestionsContainer }

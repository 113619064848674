import React, { useState } from 'react'

import SelfEditorAIFlowKeywordsGenModalContent from './Content'

type SelfEditorAIFlowKeywordsGenModalProps = {
  onClose: () => void
  summary: Record<string, string>
  headlines: string[]
  updateSummary: (summary: Record<string, string>) => void
}

const SelfEditorAIFlowKeywordsGenModal = ({
  onClose,
  summary,
  headlines,
  updateSummary
}: SelfEditorAIFlowKeywordsGenModalProps) => {
  const [isEditing, setIsEditing] = useState(false)

  const summaryWithHeadlines = {
    ...summary,
    Titres: headlines.map((curr) => `${curr}`).join(' | ')
  }

  return !isEditing ? (
    <SelfEditorAIFlowKeywordsGenModalContent
      onClose={onClose}
      summary={summaryWithHeadlines}
      updateSummary={updateSummary}
    />
  ) : (
    <div>Edition en cours...</div>
  )
}

export default SelfEditorAIFlowKeywordsGenModal

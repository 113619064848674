import React, { CSSProperties } from 'react'

import { StyledH1 } from './styles'

type H1Props = {
  children: string
  style?: CSSProperties
}

const H1 = ({ children, style }: H1Props) => {
  return <StyledH1 style={style}>{children}</StyledH1>
}

export default H1

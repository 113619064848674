import React, { ChangeEvent } from 'react'

import {
  DisplayedValueWrapper,
  SelectionItem,
  SelectionListWrapper,
  descriptionStyle,
  displayedValueComplementStyle,
  displayedValueStyle,
  otherTitleStyle,
  titleStyle
} from './styles'
import P from '../../../../../components/Text/Paragraph'
import Input from '../../../../../components/Input'
import { inputStyle } from '../styles'
import Margin from '../../../../../constants/margin'

export type SelectionOption = {
  title: string
  description?: string
  displayedValue: string
  displayedValueComplement?: string
  value: number | null
}

type SelectionInputWithOtherProps = {
  options: SelectionOption[]
  selectedIndex: number | null
  setSelectedIndex: (selectedIndex: number) => void
  customValue: string
  handleCustomValueChange: (value: number | null) => void
  height?: string
  width?: string
  label: string
  placeholder?: string
  type: 'number' | 'text'
}

const SelectionInputWithOther = ({
  options,
  selectedIndex,
  setSelectedIndex,
  customValue,
  handleCustomValueChange,
  height,
  width,
  label,
  placeholder,
  type
}: SelectionInputWithOtherProps) => {
  const onCustomValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleCustomValueChange(parseFloat(event.target.value))
  }

  return (
    <>
      <SelectionListWrapper>
        {options.map((option, index) => (
          <SelectionItem
            key={option.value}
            selected={index === selectedIndex}
            onClick={setSelectedIndex.bind(null, index)}
            height={height}
            width={width}
          >
            <P style={titleStyle}>{option.title}</P>
            {option.description && (
              <P style={descriptionStyle}>{option.description}</P>
            )}
            {option.displayedValue && (
              <DisplayedValueWrapper>
                <P style={displayedValueStyle}>{option.displayedValue}</P>
                {option.displayedValueComplement && (
                  <P style={displayedValueComplementStyle}>
                    {option.displayedValueComplement}
                  </P>
                )}
              </DisplayedValueWrapper>
            )}
          </SelectionItem>
        ))}
        <SelectionItem
          height={height}
          width={width}
          selected={selectedIndex === options.length}
          onClick={setSelectedIndex.bind(null, options.length)}
        >
          <P style={otherTitleStyle}>Autre</P>
        </SelectionItem>
      </SelectionListWrapper>
      {selectedIndex === options.length && (
        <Input
          style={inputStyle}
          type={type}
          label={label}
          placeholder={placeholder}
          withBorder
          newFont
          onChange={onCustomValueChange}
          value={customValue}
        />
      )}
    </>
  )
}

export default SelectionInputWithOther

import styled from '@emotion/styled'

import Colors from '../../../../../constants/colors'
import { YETIC_NEW_FONT_FAMILY } from '../../../../../constants/fontFamily'

export const StyledH2 = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  margin: 0;
  font-family: ${YETIC_NEW_FONT_FAMILY};
  color: ${Colors.YETIC_BLUE_DARK};
`

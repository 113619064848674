import React, { useState } from 'react'

import {
  SelectedDescriptionsEmptyContainer,
  SelectedDescriptionsListContainer,
  SelectedDescriptionItemActionButton,
  SelectedDescriptionItemContainer,
  SelectedDescriptionItemActionButtons,
  SelectedDescriptionEditingItemContainer
} from './styles'
import Trash from '../../../../../../components/Icon/trash'
import EditIcon from '../../../../../../components/Icon/edit'
import Colors from '../../../../../../constants/colors'
import Button from '../../../../../../components/Button'
import Input from '../../../../../../components/Input'
import P from '../../../../../../components/Text/Paragraph'

type SelectedDescriptionsProps = {
  descriptions: string[]
  onDescriptionsChange: (descriptions: string[]) => void
}

const SelectedDescriptions = ({
  descriptions,
  onDescriptionsChange
}: SelectedDescriptionsProps) => {
  const [editedDescription, setEditedDescription] = useState<{
    index: number
    value: string
  } | null>(null)

  const onDescriptionDelete = (index: number) => {
    const updatedDescriptions = [...descriptions]
    updatedDescriptions.splice(index, 1)
    onDescriptionsChange(updatedDescriptions)
  }

  const onEditDescriptionChange = (description: string, index: number) => {
    setEditedDescription({ index, value: description })
  }

  const onDescriptionEditSave = () => {
    if (editedDescription) {
      const updatedDescriptions = [...descriptions]
      updatedDescriptions[editedDescription.index] = editedDescription.value
      onDescriptionsChange(updatedDescriptions)
      setEditedDescription(null)
    }
  }

  const onDescriptionEditCancel = () => {
    setEditedDescription(null)
  }

  const renderSelectedDescriptionItem = (
    description: string,
    index: number
  ) => {
    const isEditing = index === editedDescription?.index

    if (isEditing) {
      return (
        <SelectedDescriptionEditingItemContainer
          key={`${index}-${description}`}
        >
          <Input
            type="textarea"
            value={editedDescription?.value || ''}
            onChange={(e) => {
              onEditDescriptionChange(e.target.value, index)
            }}
            containerStyle={{
              width: '100%',
              border: `1px solid ${Colors.YETIC_GREY_DARK}`,
              borderRadius: '5px'
            }}
          />
          <SelectedDescriptionItemActionButtons>
            <Button newFont type="secondary" onClick={onDescriptionEditCancel}>
              Annuler
            </Button>
            <Button newFont type="primary" onClick={onDescriptionEditSave}>
              Enregistrer
            </Button>
          </SelectedDescriptionItemActionButtons>
        </SelectedDescriptionEditingItemContainer>
      )
    }

    return (
      <SelectedDescriptionItemContainer key={`${index}-${description}`}>
        <P style={{ maxWidth: '85%' }}>{description}</P>
        {!editedDescription && (
          <SelectedDescriptionItemActionButtons>
            <SelectedDescriptionItemActionButton
              backgroundColor={Colors.YETIC_BACKGROUND_BUTTON}
              hoverBackgroundColor={Colors.YETIC_GREY_DARK}
              onClick={() => {
                setEditedDescription({ index, value: description })
              }}
            >
              <EditIcon height="18px" width="18px" />
            </SelectedDescriptionItemActionButton>
            <SelectedDescriptionItemActionButton
              backgroundColor="#bf060630"
              hoverBackgroundColor="#bf060650"
              onClick={() => {
                onDescriptionDelete(index)
              }}
            >
              <Trash height="18px" width="18px" color="#b82727" />
            </SelectedDescriptionItemActionButton>
          </SelectedDescriptionItemActionButtons>
        )}
      </SelectedDescriptionItemContainer>
    )
  }

  return (
    <SelectedDescriptionsListContainer>
      {descriptions.map((description, index) =>
        renderSelectedDescriptionItem(description, index)
      )}
    </SelectedDescriptionsListContainer>
  )
}

export default SelectedDescriptions

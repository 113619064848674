import React, { useState } from 'react'

import { SurveyContentType } from './types'
import {
  SurveyCenteredContent,
  SurveyContent,
  SurveyContentHeader,
  SurveyGoBackButton,
  SurveyWrapper,
  surveyButtonStyle
} from './styles'
import SurveyNavigation from './SurveyNavigation'
import SurveyNextButtons from './SurveyNextButtons'
import SurveyQuestion from './SurveyQuestion'
import Button from '../Button'
import { ArrowBack } from '../Icon'

type SurveyProps = {
  backButtonIncluded?: boolean
  content: SurveyContentType
  currentQuestionId: string
  updateCurrentQuestionId: (questionId: string) => void
  onAnswerClick: (answerId: string) => void
  onClose: () => void
  onValidate?: (id: string) => void
}

const Survey = ({
  backButtonIncluded,
  content,
  currentQuestionId,
  updateCurrentQuestionId,
  onAnswerClick,
  onClose,
  onValidate
}: SurveyProps) => {
  const [history, setHistory] = useState<string[]>([content[0].id])
  const pageContent = content.find(
    (question) => question.id === currentQuestionId
  )
  const numberOfAnswers = pageContent?.next?.length ?? 0

  const updatedOnAnswerClick = (
    answerId: string,
    switchToOpenedQuestions?: boolean
  ) => {
    setTimeout(() => {
      if (switchToOpenedQuestions) {
        onValidate && onValidate(answerId)
        return
      }

      onAnswerClick(answerId)
      setHistory([...history, answerId])
    }, 100)
  }

  const handleOnPreviousClick = () => {
    const previousQuestionId = history[history.length - 2]
    updateCurrentQuestionId(previousQuestionId)
    setHistory(history.slice(0, -1))
  }

  const onBtnClickActions: Record<string, () => void> = {
    restart: () => {
      updateCurrentQuestionId(content[0].id)
      setHistory([content[0].id])
    },
    close: onClose,
    previous: handleOnPreviousClick,
    validate: () => {
      onValidate && onValidate(pageContent?.id ?? '')
    }
  }

  return (
    <SurveyWrapper>
      {backButtonIncluded === true && (
        <SurveyNavigation handleOnPreviousClick={handleOnPreviousClick} />
      )}
      {pageContent && (
        <SurveyCenteredContent>
          <SurveyContent hasAnswers={numberOfAnswers > 0}>
            <SurveyContentHeader>
              {history.length > 1 && (
                <SurveyGoBackButton onClick={handleOnPreviousClick}>
                  <ArrowBack />
                </SurveyGoBackButton>
              )}
              <SurveyQuestion text={pageContent.text} />
            </SurveyContentHeader>
            {pageContent.next && (
              <SurveyNextButtons
                nextButtons={pageContent.next}
                updatedOnAnswerClick={updatedOnAnswerClick}
              />
            )}
            {pageContent.btn && (
              <Button
                newFont
                large
                onClick={onBtnClickActions[pageContent.btn.onClickId]}
                style={surveyButtonStyle}
              >
                {pageContent.btn.text}
              </Button>
            )}
          </SurveyContent>
        </SurveyCenteredContent>
      )}
    </SurveyWrapper>
  )
}

export default Survey

import React, { ChangeEvent, Ref } from 'react'

import {
  StyledCalendarContainer,
  StyledDateInput,
  StyledInput,
  StyledInputContainer,
  StyledInputWrapper,
  StyledMiddle,
  StyledTop,
  StyledWrapper
} from './styles'
import { isDayValid } from './helpers/isDayValid'
import { getErrorMessage } from './helpers/getErrorMessage'
import { isMonthValid } from './helpers/isMonthValid'
import P from '../../Text/Paragraph'
import CalendarPicto from '../../Icon/calendarPicto'
import Colors from '../../../constants/colors'
import FontSize from '../../../constants/fontSize'
import Margin from '../../../constants/margin'

type DatePickerContentProps = {
  label: string
  date: { day: string; month: string; year: string }
  minDate?: Date
  maxDate?: Date
  handleDayChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleMonthChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleYearChange: (e: ChangeEvent<HTMLInputElement>) => void
  dayRef: Ref<HTMLInputElement>
  monthRef: Ref<HTMLInputElement>
  yearRef: Ref<HTMLInputElement>
  calendarInputRef: Ref<HTMLInputElement>
  handleDateInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleCalendarClick: () => void
}

const DatePickerContent = ({
  label,
  date,
  minDate,
  maxDate,
  handleDayChange,
  handleMonthChange,
  handleYearChange,
  dayRef,
  monthRef,
  yearRef,
  handleCalendarClick,
  handleDateInputChange,
  calendarInputRef
}: DatePickerContentProps) => {
  return (
    <StyledWrapper>
      <StyledInputWrapper>
        {label && <P style={{ marginBottom: Margin.m4 }}>{label}</P>}
        <StyledTop>
          <P
            style={{
              width: '84px',
              border: 'none',
              margin: '0',
              paddingLeft: '2px'
            }}
          >
            Jour
          </P>
          <P
            style={{
              width: '84px',
              border: 'none',
              margin: '0',
              paddingLeft: '2px'
            }}
          >
            Mois
          </P>
          <P
            style={{
              width: '100px',
              border: 'none',
              margin: '0',
              paddingLeft: '2px'
            }}
          >
            Année
          </P>
        </StyledTop>
        <StyledMiddle>
          <StyledInputContainer>
            <StyledInput
              ref={dayRef}
              onChange={handleDayChange}
              placeholder="JJ"
              value={date.day}
              isError={
                !isDayValid(date.day) ||
                !!getErrorMessage(date, minDate, maxDate)
              }
            />
            <P style={{ fontSize: FontSize.NEW_YETIC_H2 }}>/</P>
          </StyledInputContainer>
          <StyledInputContainer>
            <StyledInput
              ref={monthRef}
              onChange={handleMonthChange}
              placeholder="MM"
              value={date.month}
              isError={
                !isMonthValid(date.month) ||
                !!getErrorMessage(date, minDate, maxDate)
              }
            />
            <P style={{ fontSize: FontSize.NEW_YETIC_H2 }}>/</P>
          </StyledInputContainer>
          <StyledInputContainer>
            <StyledInput
              ref={yearRef}
              onChange={handleYearChange}
              value={date.year}
              style={{ width: '80px' }}
              placeholder="AAAA"
              isError={!!getErrorMessage(date, minDate, maxDate)}
            />
          </StyledInputContainer>
          <StyledCalendarContainer>
            <StyledDateInput
              onChange={handleDateInputChange}
              min={minDate?.toISOString().split('T')[0]}
              max={maxDate?.toISOString().split('T')[0]}
              ref={calendarInputRef}
              type="date"
              id="dateInput"
            />
            <CalendarPicto onClick={handleCalendarClick} />
          </StyledCalendarContainer>
        </StyledMiddle>
        <P style={{ color: Colors.YETIC_RED, height: '24px' }} small>
          {getErrorMessage(date, minDate, maxDate)}
        </P>
      </StyledInputWrapper>
    </StyledWrapper>
  )
}

export default DatePickerContent

import React, { CSSProperties } from 'react'

import { StyledH2 } from './styles'

type H2Props = {
  children: string
  style?: CSSProperties
}

const H2 = ({ children, style }: H2Props) => {
  return <StyledH2 style={style}>{children}</StyledH2>
}

export default H2

import React, { CSSProperties, ReactNode } from 'react'

import { StyledP } from './styles'

type PProps = {
  children: ReactNode
  style?: CSSProperties
  big?: boolean
  small?: boolean
}

const P = ({ children, style, big, small }: PProps) => {
  return (
    <StyledP style={style} big={big} small={small}>
      {children}
    </StyledP>
  )
}

export default P

import React from 'react'

import H1 from '../../../../components/Text/Headlines/H1'
import P from '../../../../components/Text/Paragraph'
import {
  IconWrapper,
  StyledCenteredDiv,
  StyledContent,
  StyledWrapper
} from './styles'
import Colors from '../../../../constants/colors'
import Button from '../../../../components/Button'
import Margin from '../../../../constants/margin'
import Stepper from '../../../../components/Stepper'
import { aiFlowSteps } from '../constants'
import CheckIcon from '../../../../components/Icon/check'

type SelfEditorAIFlowIntroductionCampaignParamsContentProps = {
  handleClick: () => void
}

const SelfEditorAIFlowIntroductionCampaignParamsContent = ({
  handleClick
}: SelfEditorAIFlowIntroductionCampaignParamsContentProps) => (
  <StyledWrapper>
    <Stepper steps={aiFlowSteps} currentIndex={1} marginBottom={Margin.m8} />
    <StyledContent>
      <IconWrapper>
        <CheckIcon width="100%" height="100%" color={Colors.YETIC_BLUE_DARK} />
      </IconWrapper>
      <H1>{`Vos réponses ont été enregistrées avec succès !`}</H1>
      <P
        style={{ marginTop: Margin.m5 }}
      >{`Plus qu'une étape avant de générer votre contenu.`}</P>
      <P style={{ marginTop: Margin.m5, marginBottom: Margin.m8 }}>
        {`Il vous sera demandé 3 informations : la date de début de votre campagne, la durée de la campagne et 
        le prix journalier de la campagne. Ne vous inquiétez pas si vous n'avez pas d'idées au sujet de la durée ou du prix, 
        nous vous suggérerons des exemples.`}
      </P>
      <StyledCenteredDiv>
        <Button
          newFont
          type="primary"
          onClick={handleClick}
          large
          style={{ width: 'fit-content' }}
        >
          Configurer ma campagne
        </Button>
      </StyledCenteredDiv>
    </StyledContent>
  </StyledWrapper>
)

export default SelfEditorAIFlowIntroductionCampaignParamsContent

import React from 'react'

import { IconType } from '../../types/icon'

const ChronometerIcon = ({
  height = '20px',
  width = '20px',
  style,
  color,
  handleClick
}: IconType) => (
  <svg
    onClick={handleClick}
    stroke={color ?? '#000000'}
    fill="none"
    style={style}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeWidth="1.5"
      d="M28.054 18.571c0 6.933-5.62 12.554-12.554 12.554s-12.554-5.621-12.554-12.554c0-6.722 5.286-12.192 11.927-12.521v-2.166h-0.941c-0.555 0-1.005-0.45-1.005-1.005s0.45-1.004 1.005-1.004h3.012c0.555 0 1.005 0.449 1.005 1.004s-0.45 1.005-1.005 1.005h-1.066v2.153c6.757 0.2 12.176 5.729 12.176 12.534zM14.873 9.909v-2.291c-3.109 0.176-5.866 1.64-7.766 3.858l1.653 1.653-0.71 0.711-1.565-1.566c-1.274 1.798-2.032 3.987-2.032 6.359 0 0.106 0.015 0.251 0.015 0.251h2.432v1.005h-2.36c0.244 2.052 1.057 3.987 2.268 5.534l1.243-1.243 0.71 0.71-1.296 1.297c1.879 1.996 4.488 3.297 7.408 3.462v-2.729h1.004v2.742c2.989-0.102 5.67-1.393 7.599-3.41l-1.424-1.424 0.71-0.71 1.377 1.376c1.24-1.56 2.074-3.521 2.322-5.604h-2.55v-1.005h2.621c0.003-0.104 0.016-0.145 0.016-0.251 0-2.438-0.8-4.681-2.138-6.505l-1.648 1.648-0.71-0.71 1.726-1.725c-1.947-2.203-4.753-3.631-7.9-3.737v2.304h-1.005zM15.939 18.885h3.516c0.277 0 0.502 0.226 0.502 0.502 0 0.277-0.225 0.503-0.502 0.503h-4.018c-0.277 0-0.502-0.226-0.502-0.503v-6.967c0-0.277 0.225-0.502 0.502-0.502s0.502 0.225 0.502 0.502v6.465z"
    ></path>
  </svg>
)

export default ChronometerIcon

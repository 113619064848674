import styled from '@emotion/styled'

import Margin from '../../../../../constants/margin'
import Padding from '../../../../../constants/padding'

export const StyledWrapper = styled.div`
  padding: ${Padding.p3} ${Padding.p10} ${Padding.p10};
  margin-top: ${Margin.m8};
`

export const StyledContent = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 500px;
`

export const StyledCenteredDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const IconWrapper = styled.div<{ backgroundColor: string }>`
  width: 64px;
  height: 64px;
  margin: 0 auto;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom: 16px;
`

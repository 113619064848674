import React from 'react'

import { IconType } from '../../types/icon'
import Colors from '../../constants/colors'

const CrossClose = ({
  height = '25px',
  width = '25px',
  style,
  color = Colors.YETIC_BLUE_DARK,
  weight = '1.5'
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8L8 16M8.00001 8L16 16"
        stroke={color}
        strokeWidth={weight}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CrossClose

import styled from '@emotion/styled'

import Padding from '../../../../../../constants/padding'
import Colors from '../../../../../../constants/colors'

export const StyledWrapper = styled.div<{
  activeBorderColor?: string
  activeBackgroundColor?: string
}>`
  border: none;
  box-shadow: 0px 0px 4px #142a4c40;

  width: 20vw;
  border-radius: 6px;
  padding: ${Padding.p8};
  padding-bottom: ${Padding.p10};
  display: flex;
  flex-direction: column;
  cursor: pointer;

  background-color: #fff;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0px 0px 12px
      ${({ activeBorderColor }) => activeBorderColor ?? '#00000030'};
    transition: all 0.2s;
  }

  &:focus {
    background-color: ${({ activeBackgroundColor }) =>
      activeBackgroundColor ?? '#f1f3ff'};
    box-shadow: 0px 0px 4px #142a4c40;
    transition: all 0.2s;
  }
`

export const IconWrapper = styled.div<{ backgroundColor: string }>`
  width: 64px;
  height: 64px;
  margin: 0 auto;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom: 16px;
`

import { OpenedQuestionsDataType, SurveyContentType } from '../types'

export const mockedCommonSurveyData: SurveyContentType = [
  {
    id: 'out',
    group: 'validate',
    text: 'Vos réponses ont bien été enregistrées !',
    btn: {
      text: 'Continuer',
      onClickId: 'validate'
    }
  }
]

export const mockedCommonOpenedQuestions: OpenedQuestionsDataType = {}

import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FormatEnum } from '../../types/format'
import { GoalsEnum } from '../../types/goals'
import { DeviceCampaign, OSCampaign } from '../../types/creation/campaign'
import {
  PlatformAPIEnum,
  PlatformEnum,
  getPlatformEnum
} from '../../types/platform'
import { AppContext } from '../../contexts/AppContext'
import { PanelType } from '../../types/templates/panels'
import { RadioListTmp } from '../../tmpComponents/RadioList'
import { formatsByPlatform } from '../../constants/campaignFormat'
import {
  campaignCreationFormatTranslation,
  campaignCreationGoalTranslation
} from './translations'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { CreationPageTemplate } from '../../templates/CreationPageTemplate'
import CampaignPeriod from './UI/CampaignPeriod'
import Budget from './UI/Budget'
import Keywords from './UI/Keywords'
import Input from '../../components/Input'
import Margin from '../../constants/margin'
import { SectionType } from '../../types/templates/section'
import Devices from './UI/Devices'
import OS from './UI/OS'
import Language from './UI/Language'
import {
  AudienceLocation,
  checkGetCampaignCreationData,
  getCampaignCreationData
} from '../../helpers/queries/campaign/getCampaignCreationData'
import { createCampaign } from '../../helpers/queries/campaign/create'
import {
  getNumberOfDaysBetweenDates,
  parseStringDate,
  verifyStringIsValidDate
} from '../../helpers/date'
import { CampaignCreationError } from '../../types/pages/campaignCreation'
import {
  checkIfCreateCampaignErrorCodeValid,
  CreateCampaignErrorFromApi
} from '../../types/error/campaign/create'
import { InputError } from '../../components/Input/Styles'
import Breadcrumb from '../../components/BreadCrumb'
import { CampaignNavigation } from '../../types/campaign/navigation'
import { DevicesEnum } from '../../types/campaign/devices'
import KeywordsLocation from './UI/Keywords/KeywordsLocation'
import { KeywordDetails } from '../../types/campaign/keyword'
import {
  checkIfGetSuggestedKeywords,
  getSuggestedKeywords
} from '../../helpers/queries/campaign/getSuggestedKeywords'
import LoadingPage from '../LoadingPage'
import Dropdown, { Option } from '../../components/Dropdown'
import ErrorPage from '../ErrorPage'
import LocationModal from './UI/Keywords/LocationModal'
import { Status, StatusTranslation, getIsEditable } from '../../types/status'
import { BidStrategyEnum } from '../../types/bidStrategy'
import { floorToTwoDecimals } from '../../helpers/rounds'
import { CO2ScoreContainer } from './UI/Devices/styles'
import CO2Tag from '../../components/CO2Tag'
import { bidStrategiesByFormat } from './UI/BidStrategy/bidStrategiesByFormat'
import ConfirmationModal from '../../components/Modal/ConfirmationModal'
import { ModalContent } from './styles'
import DefaultText from '../../components/DefaultText'
import { FlowEnum } from '../../types/flow'

type CampaignCreationErrors = {
  name: string
  audience: string
  budget: string
  goal: string
  startDate: string
  endDate: string
  keyword: string
  errorFromApi: string
}

const EditorCampaignCreation = () => {
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformEnum>(
    PlatformEnum.GOOGLE
  )
  const [format, setFormat] = useState<FormatEnum>(FormatEnum.TEXT)
  const [goal, setGoal] = useState<GoalsEnum | null>(null)
  const [period, setPeriod] = useState<{
    isSamePeriod: boolean
    start?: string
    end?: string
  }>({
    isSamePeriod: true
  })
  const [audience, setAudience] = useState<{
    id: string
    name: string
    locations: AudienceLocation[]
  } | null>(null)
  const [budget, setBudget] = useState<{
    isBudgetOnPeriod: boolean
    amount: number
  }>({
    isBudgetOnPeriod: false,
    amount: 0
  })
  const [keywords, setKeywords] = useState<string[]>([])
  const [keywordToSearch, setKeywordToSearch] = useState('')
  const [keywordDetails, setKeywordDetails] = useState<KeywordDetails[] | null>(
    null
  )
  const [keywordApiError, setKeywordApiError] = useState('')
  const [isLocationsModalOpen, setIsLocationsModalOpen] = useState(false)

  const [name, setName] = useState('')
  const [lang, setLang] = useState<string>('fr')
  const [deviceAndOS, setDeviceAndOS] = useState<{
    devices: DeviceCampaign
    os: OSCampaign
  }>({
    devices: {
      smartphone: true,
      desktop: false,
      tablet: false,
      tv: false
    },
    os: {
      desktop: true,
      android: true,
      ios: true
    }
  })
  const [bidStrategy, setBidStrategy] = useState<BidStrategyEnum>(
    bidStrategiesByFormat[format][0].label
  )
  const [isAllFormUnlocked, setIsAllFormUnlocked] = useState(false)
  const [availableGoals, setAvailableGoals] = useState<GoalsEnum[]>([])
  const [availableAudiences, setAvailableAudiences] = useState<
    { id: string; name: string; locations: AudienceLocation[] }[]
  >([])
  const [availableBudget, setAvailableBudget] = useState(0)
  const [mediaPlanStartDate, setMediaPlanStartDate] = useState('')
  const [mediaPlanEndDate, setMediaPlanEndDate] = useState('')
  const [mediaPlanStatus, setMediaPlanStatus] = useState<Status | null>(null)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const [errorFromApi, setErrorFromApi] = useState('')
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const [hasFetchError, setHasFetchError] = useState(false)

  const [navigation, setNavigation] = useState<CampaignNavigation | null>(null)
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false)

  const isSuggestedKeywordsRequestAllowed = useRef(true)

  const { mediaPlanId } = useParams()

  const { isEditor } = useContext(AppContext)

  const navigate = useNavigate()

  const params = new URLSearchParams(location.search)
  const platform = params.get('platform') as PlatformAPIEnum | null
  const flow = params.get('flow') as FlowEnum | null

  useEffect(() => {
    if (mediaPlanId) {
      let newPlatform = selectedPlatform
      if (platform) {
        newPlatform = getPlatformEnum(platform)
      }

      const selectedFormat =
        newPlatform === PlatformEnum.GOOGLE ? FormatEnum.TEXT : FormatEnum.IMAGE

      ;(async function () {
        const data = await getCampaignCreationData({
          mediaPlanId,
          platform: platform ?? undefined
        })
        if (checkGetCampaignCreationData(data)) {
          const {
            goals,
            audiences,
            availableBudget,
            mediaPlanStartDate,
            mediaPlanEndDate,
            mediaPlanStatus,
            navigation
          } = data
          setAvailableGoals(goals)
          if (goals.length === 1) {
            setGoal(goals[0])
          }
          setAvailableAudiences(audiences)
          audiences.length === 1 && setAudience(audiences[0])
          setAvailableBudget(availableBudget)
          setMediaPlanStartDate(mediaPlanStartDate.split('T')[0])
          setMediaPlanEndDate(mediaPlanEndDate.split('T')[0])
          setMediaPlanStatus(mediaPlanStatus)
          setNavigation(navigation)
        } else {
          console.error(
            'Something went wrong during request to /campaign-creation/data'
          )
          setHasFetchError(true)
        }
      })()

      setSelectedPlatform(newPlatform)
      setFormat(selectedFormat)
      setBidStrategy(bidStrategiesByFormat[selectedFormat][0].label)
    } else {
      setHasFetchError(true)
    }
  }, [mediaPlanId, platform])

  useEffect(() => {
    if (keywordToSearch) {
      if (!audience) {
        displaySuggestedKeywordsError(
          'Veuillez sélectionner une audience pour récupérer les statistiques liées à ce mot-clé.'
        )
      } else {
        isSuggestedKeywordsRequestAllowed.current = false
        ;(async function () {
          const data = await getSuggestedKeywords({
            keyword: keywordToSearch,
            audienceId: audience.id
          })
          isSuggestedKeywordsRequestAllowed.current = true
          if (checkIfGetSuggestedKeywords(data)) {
            setKeywordDetails(data.keywords)
          } else {
            console.error(
              'Something went wrong during request to get keyword details from google'
            )
            displaySuggestedKeywordsError(
              'Impossible de récupérer les statistiques liées à ce mot-clé. Veuillez réessayer plus tard.'
            )
          }
        })()
      }
    }
  }, [keywordToSearch, audience])

  useEffect(() => {
    if (isSubmitLoading) {
      ;(async function () {
        try {
          if (mediaPlanId && goal && audience) {
            const campaignToCreate = {
              name,
              platform: selectedPlatform,
              format,
              goal,
              os: deviceAndOS.os,
              devices: deviceAndOS.devices,
              budget: budget.amount,
              isBudgetPerDay: !budget.isBudgetOnPeriod,
              lang,
              startDate:
                !period.isSamePeriod && period.start
                  ? new Date(period.start)
                  : undefined,
              endDate:
                !period.isSamePeriod && period.end
                  ? new Date(period.end)
                  : undefined,
              bidStrategy,
              mediaPlanId,
              audience,
              keywords
            }

            const result = await createCampaign(campaignToCreate)

            setIsSubmitLoading(false)
            if (result.error) {
              if (!checkIfCreateCampaignErrorCodeValid(result.error)) {
                setErrorFromApi(CreateCampaignErrorFromApi.UNKNOWN)
              } else {
                setErrorFromApi(CreateCampaignErrorFromApi[result.error])
              }
              setTimeout(() => {
                setErrorFromApi('')
              }, 6000)
            } else {
              if (flow === FlowEnum.view) {
                navigate(`/media-plan/${mediaPlanId}/details`)
              } else {
                navigate(`/campaign/${result.campaignId as string}/review`)
              }
            }
          }
        } catch (error) {
          console.error(error)
        }

        if (!hasBeenSubmitted) {
          setHasBeenSubmitted(true)
        }
        setIsSubmitLoading(false)
      })()
    }
  }, [isSubmitLoading])

  if (hasFetchError || !mediaPlanId) {
    return <ErrorPage />
  }

  if (
    !availableGoals ||
    !availableAudiences ||
    availableBudget == null ||
    !mediaPlanStartDate ||
    !navigation ||
    !mediaPlanStatus
  ) {
    return <LoadingPage />
  }

  if (!getIsEditable({ status: mediaPlanStatus, isEditor })) {
    return (
      <ErrorPage
        message={`Vous ne pouvez pas ajouter de campagne à ce plan média au statut ${StatusTranslation[
          mediaPlanStatus
        ].toLocaleLowerCase()}.`}
        action={{
          text: 'Retour au plan média',
          onClick: () => {
            navigate(`/media-plan/${navigation.mediaPlan.id}/details`)
          }
        }}
      />
    )
  }

  const displaySuggestedKeywordsError = (error: string) => {
    setKeywordToSearch('')
    setKeywordApiError(error)
    setTimeout(() => {
      setKeywordApiError('')
    }, 5000)
  }

  const addToPanels = (
    panels: PanelType[],
    title: string,
    sections: (SectionType | JSX.Element)[],
    isDropdown?: boolean,
    titleTooltip?: string
  ) => {
    panels.push({
      title,
      sections,
      isDropdown: isDropdown ?? false,
      titleTooltip,
      disabled: false
    })
  }

  const checkName = () => name.length > 1

  const checkAudience = () => audience !== null

  const checkBudget = () => budget.amount > 0

  const checkCorrectBudget = () => {
    if (mediaPlanStartDate && mediaPlanEndDate) {
      if (budget.isBudgetOnPeriod) {
        return budget.amount <= availableBudget
      } else {
        if (period.isSamePeriod) {
          return (
            budget.amount *
              getNumberOfDaysBetweenDates(
                mediaPlanStartDate,
                mediaPlanEndDate
              ) <=
            availableBudget
          )
        } else {
          return (
            !period.start ||
            !period.end ||
            (period.start &&
              period.end &&
              budget.amount *
                getNumberOfDaysBetweenDates(period.start, period.end) <=
                availableBudget)
          )
        }
      }
    }
  }

  const checkGoal = () => {
    return goal !== undefined
  }

  const checkStartDate = () => {
    if (period.isSamePeriod) {
      return true
    } else {
      if (period.start !== undefined) {
        const isStartDateValid = verifyStringIsValidDate(period.start)

        if (isStartDateValid) {
          const parsedStartDate = parseStringDate(period.start)
          const tommorow = new Date()
          tommorow.setDate(tommorow.getDate() + 1)
          const isStartGreaterThanToday =
            parsedStartDate.getTime() > tommorow.getTime()
          return isStartGreaterThanToday
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }

  const checkEndDate = () => {
    if (period.isSamePeriod) {
      return true
    } else {
      if (period.start !== undefined && period.end !== undefined) {
        const isStartDateValid = verifyStringIsValidDate(period.start)
        const isEndDateValid = verifyStringIsValidDate(period.end)

        if (isStartDateValid && isEndDateValid) {
          const parsedStartDate = parseStringDate(period.start)
          const parsedEndDate = parseStringDate(period.end)
          const isEndGreaterThanStart =
            parsedEndDate.getTime() > parsedStartDate.getTime()
          return isEndGreaterThanStart
        } else {
          return isEndDateValid
        }
      } else {
        return false
      }
    }
  }

  const checkKeyword = () => {
    return keywords.length > 0 || format !== FormatEnum.TEXT
  }

  const availableDailyBudget =
    period.start && period.end
      ? Math.floor(
          (availableBudget /
            getNumberOfDaysBetweenDates(period.start, period.end)) *
            10
        ) / 10
      : mediaPlanStartDate && mediaPlanEndDate
      ? Math.floor(
          (availableBudget /
            getNumberOfDaysBetweenDates(mediaPlanStartDate, mediaPlanEndDate)) *
            10
        ) / 10
      : ''

  const errors: CampaignCreationErrors = {
    name: checkName() ? '' : CampaignCreationError.NAME,
    audience: checkAudience() ? '' : CampaignCreationError.AUDIENCE,
    budget: checkBudget()
      ? checkCorrectBudget()
        ? ''
        : `${CampaignCreationError.AMOUNT_BUDGET_MAX} ${
            !budget.isBudgetOnPeriod &&
            (period.start !== period.end ||
              (period.start === undefined && period.end === undefined))
              ? 'un budget journalier de ' + availableDailyBudget
              : availableBudget
          }€.`
      : CampaignCreationError.AMOUNT_BUDGET_MIN,
    goal: checkGoal() ? '' : CampaignCreationError.GOALS,
    startDate: checkStartDate() ? '' : CampaignCreationError.START_DATE,
    endDate: checkEndDate() ? '' : CampaignCreationError.END_DATE,
    keyword: checkKeyword() ? '' : CampaignCreationError.KEYWORDS,
    errorFromApi: errorFromApi ?? ''
  }

  const handleUnlockAllTheForm = () => {
    setIsAllFormUnlocked(true)
  }

  const handleChangeFormat = (index: number) => {
    const newFormat = formatsByPlatform[selectedPlatform][index]

    setFormat(newFormat)
    setBidStrategy(bidStrategiesByFormat[newFormat][0].label)
  }

  const handleOpenLeaveModal = () => {
    setIsLeaveModalOpen(true)
  }

  const handleCloseLeaveModal = () => {
    setIsLeaveModalOpen(false)
  }

  const breadcrumb = [
    {
      onClick: () => {
        navigate('/')
      },
      label: 'Accueil'
    },
    {
      onClick: () => {
        navigate('/customers')
      },
      label: 'Clients'
    },
    {
      onClick: () => {
        handleOpenLeaveModal()
      },
      label: navigation.company.name
    },
    {
      onClick:
        flow === FlowEnum.view
          ? () => {
              navigate(`/media-plan/${mediaPlanId}/details`)
            }
          : () => {
              navigate(`/media-plan/${mediaPlanId}/review`)
            },
      label: navigation.mediaPlan.name
    },
    {
      label: 'Création de campagne'
    }
  ]

  const handleChangeGoal = (index: number) => {
    setGoal(availableGoals[index])
  }

  const handleChangeAudience = (audienceName: string) => {
    setAudience(availableAudiences.find((a) => a.name === audienceName) || null)
  }

  const handleChangeIsSamePeriod = (isSamePeriod: boolean) => {
    setPeriod({
      isSamePeriod,
      start: undefined,
      end: undefined
    })
  }

  const handleChangeStartDate = (date: string) => {
    setPeriod({
      ...period,
      start: date
    })
  }

  const handleChangeEndDate = (date: string) => {
    setPeriod({
      ...period,
      end: date
    })
  }

  const handleBudgetTypeChange = (isBudgetOnPeriod: boolean) => {
    setBudget({
      amount: budget.amount,
      isBudgetOnPeriod
    })
  }

  const handleBudgetChange = (amount: string) => {
    // TODO check the format of amount
    setBudget({
      amount: parseFloat(amount),
      isBudgetOnPeriod: budget.isBudgetOnPeriod
    })
  }

  const handleRemoveKeyword = (index: number) => {
    setKeywords(keywords.filter((_, i) => i !== index))
  }

  const handleAddKeyword = (tag: string) => {
    if (!keywords.includes(tag) && keywords.length < 15) {
      setKeywords([...keywords, tag])
    }
  }

  const handleOpenLocationsModal = () => {
    setIsLocationsModalOpen(true)
  }

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleChangeLang = (newLang: string) => {
    setLang(newLang)
  }

  const handleChangeDevice = (attribute: string) => {
    setDeviceAndOS({
      os: { ...deviceAndOS.os },
      devices: {
        ...deviceAndOS.devices,
        [attribute]: !deviceAndOS.devices[attribute as DevicesEnum]
      }
    })
  }

  const handleChangeDesktopOS = () => {
    setDeviceAndOS({
      os: {
        ...deviceAndOS.os,
        desktop: !deviceAndOS.os.desktop
      },
      devices: { ...deviceAndOS.devices }
    })
  }

  const handleChangeAndroid = () => {
    setDeviceAndOS({
      os: {
        ...deviceAndOS.os,
        android: !deviceAndOS.os.android
      },
      devices: { ...deviceAndOS.devices }
    })
  }

  const handleChangeIOS = () => {
    setDeviceAndOS({
      os: {
        ...deviceAndOS.os,
        ios: !deviceAndOS.os.ios
      },
      devices: { ...deviceAndOS.devices }
    })
  }

  const handleChangeBidStrategy = (value: BidStrategyEnum) => {
    setBidStrategy(value)
  }

  const handleSubmitCampaign = () => {
    setIsSubmitLoading(true)
  }

  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const handleCancelConfirm = () => {
    if (flow === FlowEnum.view) {
      navigate(`/media-plan/${mediaPlanId}/details`)
    } else {
      navigate(`/media-plan/${mediaPlanId}/review`)
    }
  }

  const formatSection = {
    description: 'Choisissez un format de campagne',
    content: (
      <RadioListTmp
        values={formatsByPlatform[selectedPlatform].map((format) => ({
          label: campaignCreationFormatTranslation[format].label,
          description: campaignCreationFormatTranslation[format].description,
          disabled: campaignCreationFormatTranslation[format].disabled,
          tooltipView:
            format === FormatEnum.TEXT ? (
              <CO2ScoreContainer>
                <CO2Tag
                  positive
                  wording="CO2 - Meilleur choix"
                  modalText="Les publicités diffusées sur smartphone ont tendance a avoir un impact moins élevé en raison de la petite taille de l'écran."
                />
              </CO2ScoreContainer>
            ) : format === FormatEnum.VIDEO ? (
              <CO2ScoreContainer>
                <CO2Tag positive={false} wording="CO2 - Pire choix" />
              </CO2ScoreContainer>
            ) : undefined
        }))}
        selectedValue={formatsByPlatform[selectedPlatform].indexOf(format)}
        onChange={handleChangeFormat}
        style={{ marginBottom: Margin.m5 }}
      />
    )
  }

  const goalSection = {
    description: 'Choisissez un objectif de campagne',
    content: (
      <>
        <RadioListTmp
          values={availableGoals.map((goal) => ({
            label: campaignCreationGoalTranslation[goal].label,
            description: campaignCreationGoalTranslation[goal].description
          }))}
          selectedValue={goal ? availableGoals.indexOf(goal) : -1}
          onChange={handleChangeGoal}
        />
        {hasBeenSubmitted && errors.goal && (
          <InputError>{errors.goal}</InputError>
        )}
      </>
    )
  }

  const audienceSection = {
    description: 'Sélectionnez une audience',
    content: (
      <>
        <Dropdown
          placeholder="Choisissez une audience"
          onChange={(option: Option | null) => {
            handleChangeAudience(option?.label ?? '')
          }}
          options={availableAudiences.map((audience, index) => ({
            id: audience.id,
            label: audience.name
          }))}
          value={audience ? audience.name : ''}
        />
        {hasBeenSubmitted && errors.audience && (
          <InputError>{errors.audience}</InputError>
        )}
      </>
    )
  }

  const periodSection = {
    description: 'Période de diffusion de la campagne',
    content: (
      <CampaignPeriod
        isSamePeriod={period.isSamePeriod}
        handleChangePeriod={handleChangeIsSamePeriod}
        startDate={period.start}
        endDate={period.end}
        handleChangeStartDate={handleChangeStartDate}
        handleChangeEndDate={handleChangeEndDate}
        startDateError={hasBeenSubmitted ? errors.startDate : ''}
        endDateError={hasBeenSubmitted ? errors.endDate : ''}
        mediaPlanStartDate={mediaPlanStartDate}
        mediaPlanEndDate={mediaPlanEndDate}
      />
    )
  }

  const startDate =
    !period.isSamePeriod && period.start ? period.start : mediaPlanStartDate
  const endDate =
    !period.isSamePeriod && period.end ? period.end : mediaPlanEndDate

  const totalEnteredBudget = budget.isBudgetOnPeriod
    ? budget.amount
    : budget.amount * getNumberOfDaysBetweenDates(startDate, endDate)

  const numberOfDaysBetweenDates = getNumberOfDaysBetweenDates(
    startDate,
    endDate
  )
  const maxBudgetPerPeriod = floorToTwoDecimals(
    availableBudget / numberOfDaysBetweenDates
  )

  const dynamicAvailableBudget = budget.amount
    ? Math.floor((availableBudget - totalEnteredBudget) * 10) / 10
    : availableBudget

  const totalBudget =
    floorToTwoDecimals(
      budget.amount * getNumberOfDaysBetweenDates(startDate, endDate)
    ) || 0
  const budgetSection = {
    description: 'Budget de la campagne',
    content: (
      <Budget
        isBudgetOnPeriod={budget.isBudgetOnPeriod}
        budget={budget.amount}
        handleBudgetTypeChange={handleBudgetTypeChange}
        handleBudgetChange={(event) => {
          handleBudgetChange(event.target.value)
        }}
        handleBudgetInputChange={handleBudgetChange}
        maxBudgetPerPeriod={maxBudgetPerPeriod}
        availableBudget={dynamicAvailableBudget}
        error={hasBeenSubmitted ? errors.budget : ''}
        totalBudget={totalBudget}
      />
    )
  }

  const keywordsSection =
    format === FormatEnum.TEXT ? (
      {
        // TODO : add the media plan audiences locations to the data retrieved when entering the page
        cornerAction: (
          <KeywordsLocation handleOpenModal={handleOpenLocationsModal} />
        ),
        description: `Trouvez des mots-clés adaptés à votre campagne`,
        content: (
          <>
            <Keywords
              selectedTags={keywords}
              removeTag={handleRemoveKeyword}
              addNewTag={handleAddKeyword}
              keywordToSearch={keywordToSearch}
              setKeywordToSearch={setKeywordToSearch}
              selectedKeywords={keywords}
              keywordDetails={keywordDetails}
              setKeywordDetails={setKeywordDetails}
              error={hasBeenSubmitted ? errors.keyword : ''}
              apiError={keywordApiError}
              isSubmitActivated={isSuggestedKeywordsRequestAllowed.current}
            />
            {isLocationsModalOpen && (
              <LocationModal
                locations={audience?.locations}
                onClose={() => {
                  setIsLocationsModalOpen(false)
                }}
              />
            )}
          </>
        )
      }
    ) : (
      <></>
    )

  const inputName = {
    description: 'Définissez un nom pour votre campagne',
    content: (
      <Input
        type="text"
        onChange={handleChangeName}
        placeholder="Nom de la campagne"
        value={name}
        error={hasBeenSubmitted ? errors.name : ''}
        withBorder
      />
    )
  }

  const langSection = {
    title: `Langue de diffusion`,
    description: `Sélectionnez la langue de diffusion`,
    content: (
      <Language language={lang} handleLanguageChange={handleChangeLang} />
    )
  }

  const devicesSection = {
    title: 'Types de périphériques',
    description: `Choisissez les types de périphérique
      sur lesquels vous souhaitez diffuser votre campagne`,
    content: (
      <Devices
        isDesktopSelected={deviceAndOS.devices.desktop}
        isSmartphoneSelected={deviceAndOS.devices.smartphone}
        isTabletSelected={deviceAndOS.devices.tablet}
        isTVSelected={deviceAndOS.devices.tv}
        handleChangeDevice={handleChangeDevice}
        tooltip={true}
      />
    )
  }

  const osSection = {
    title: `Systèmes d'exploitation`,
    description: `Choisissez les systèmes d'exploitation
    sur lesquels vous souhaitez diffuser votre campagne`,
    content: (
      <OS
        isDesktopSelected={deviceAndOS.os.desktop}
        isAndroidSelected={deviceAndOS.os.android}
        isIOSSelected={deviceAndOS.os.ios}
        handleChangeIsDesktopSelected={handleChangeDesktopOS}
        handleChangeIsAndroidSelected={handleChangeAndroid}
        handleChangeIsIOSSelected={handleChangeIOS}
      />
    )
  }

  // const bidStrategySection = {
  //   title: `Type d'enchère`,
  //   description: `Définissez un type d'enchère pour votre campagne`,
  //   content: (
  //     <BidStrategy
  //       bidStrategy={bidStrategy}
  //       handleChangeBidStrategy={handleChangeBidStrategy}
  //       campaignFormat={format}
  //     />
  //   )
  // }

  const panels: PanelType[] = []

  addToPanels(panels, 'Nom', [inputName])
  addToPanels(panels, 'Format', [formatSection])
  addToPanels(panels, 'Objectifs', [goalSection])
  addToPanels(panels, 'Audience', [audienceSection])
  addToPanels(panels, 'Diffusion', [periodSection])
  addToPanels(panels, 'Budget', [budgetSection])

  format === FormatEnum.TEXT &&
    addToPanels(panels, 'Mots-clé', [keywordsSection], false)

  if (platform === PlatformAPIEnum.GOOGLE) {
    addToPanels(
      panels,
      'Autres paramètres (facultatifs)',
      [langSection, devicesSection],
      true
    )
  }

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <Breadcrumb items={breadcrumb} />
      <CreationPageTemplate
        // TODO see if we should include the name of the media plan
        title="Ma nouvelle campagne"
        subTitle="Définissez les caractéristiques globales de votre campagne"
        panels={panels}
        errors={
          hasBeenSubmitted
            ? {
                errors: [
                  [
                    errorFromApi,
                    errors.name,
                    errors.audience,
                    errors.goal,
                    errors.startDate,
                    errors.endDate,
                    errors.budget,
                    errors.keyword
                  ].filter((e) => e)[0]
                ]
              }
            : undefined
        }
        validation={{
          wording: 'Continuer',
          isLoading: isSubmitLoading,
          action: () => {
            if (Object.values(errors).filter((e) => e).length < 1) {
              handleSubmitCampaign()
            } else {
              setHasBeenSubmitted(true)
            }
          },
          disabled:
            hasBeenSubmitted &&
            Object.values(errors).filter((e) => e).length > 0
        }}
        cancel={{
          wording: 'Annuler',
          action: () => {
            setIsCancelModalOpen(true)
          },
          disabled: false
        }}
      />
      {isCancelModalOpen && (
        <ConfirmationModal
          title="La campagne est en cours de création"
          textConfirm="Oui, annuler"
          textReject="Non, continuer"
          onClickReject={handleCloseCancelModal}
          onClickConfirm={handleCancelConfirm}
          onClose={handleCloseCancelModal}
        >
          <ModalContent>
            <DefaultText>
              {'Êtes-vous sûr de vouloir annuler la création de la campagne ?'}
            </DefaultText>
            <DefaultText>
              {'Toutes les informations saisies seront perdues.'}
            </DefaultText>
          </ModalContent>
        </ConfirmationModal>
      )}
      {isLeaveModalOpen && (
        <ConfirmationModal
          onClose={handleCloseLeaveModal}
          textConfirm="Quitter"
          textReject="Annuler et continuer"
          onClickConfirm={() => {
            navigate(`/customer/${navigation.company.id}/details`)
          }}
          onClickReject={handleCloseLeaveModal}
          title="Êtes-vous sûr de vouloir voir la fiche client ?"
        >
          <ModalContent>
            <DefaultText>{`Voulez-vous vraiment voir la fiche client ?`}</DefaultText>
            <DefaultText>{`Vous quitterez la création. Toutes les données saisies seront perdues.`}</DefaultText>
          </ModalContent>
        </ConfirmationModal>
      )}
    </AuthenticatedTemplate>
  )
}

export default EditorCampaignCreation

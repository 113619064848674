import styled from '@emotion/styled'

import Margin from '../../../../../../constants/margin'

export const ButtonsBarWrapper = styled.div`
  width: 100%;
  margin-top: ${Margin.m7};
  display: flex;
  justify-content: space-between;
`

export const ButtonsBarRightPart = styled.div`
  display: flex;
  gap: 12px;
`

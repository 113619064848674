import styled from '@emotion/styled'

import Colors from '../../../../../../constants/colors'

export const CardContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: ${Colors.YETIC_WHITE};
  display: flex;
  flex-direction: column;
`

export const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  border-bottom: 1px solid #f0f0f0;
  min-height: 40px;
  gap: 8px;
`

export const CardContentContainer = styled.div<{ height?: string }>`
  padding: 18px;
  box-sizing: border-box;
  width: 100%;
  height: ${({ height }) => height ?? '100%'};
`

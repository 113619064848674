import React, { useEffect, useState } from 'react'

import SelfEditorAIFlowHeadlinesGenContent from './Content'
import { mockedHeadlines } from '../mock/headlines'

export type SelfEditorAIFlowHeadlinesGenContainerProps = {
  summary: Record<string, string>
  updateSummary: (summary: Record<string, string>) => void
  headlines: string[]
  updateHeadlines: (headlines: string[]) => void
  handleNext: () => void
  handlePrevious: () => void
}

const SelfEditorAIFlowHeadlinesGenContainer = ({
  summary,
  updateSummary,
  headlines,
  updateHeadlines,
  handleNext,
  handlePrevious
}: SelfEditorAIFlowHeadlinesGenContainerProps) => {
  const [generatedPendingHeadlines, setGeneratedPendingHeadlines] = useState<
    string[]
  >([])
  const [isGenerationPending, setIsGenerationPending] = useState(false)
  const [isSummaryModalOpened, setIsSummaryModalOpened] = useState(false)

  useEffect(() => {
    if (isGenerationPending) {
      setTimeout(() => {
        setGeneratedPendingHeadlines(mockedHeadlines)
        setIsGenerationPending(false)
      }, 1000)
    }
  }, [isGenerationPending])

  const handleGenerateHeadlines = () => {
    setIsGenerationPending(true)
  }

  const handleOpenOrCloseModal = () => {
    setIsSummaryModalOpened((prev) => !prev)
  }

  return (
    <SelfEditorAIFlowHeadlinesGenContent
      summary={summary}
      updateSummary={updateSummary}
      headlines={headlines}
      updateHeadlines={updateHeadlines}
      generatedPendingHeadlines={generatedPendingHeadlines}
      updateGeneratedPendingHeadlines={setGeneratedPendingHeadlines}
      handleGenerateHeadlines={handleGenerateHeadlines}
      isGenerationPending={isGenerationPending}
      isSummaryModalOpened={isSummaryModalOpened}
      handleClickOpenModal={handleOpenOrCloseModal}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
    />
  )
}

export { SelfEditorAIFlowHeadlinesGenContainer }

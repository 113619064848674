import React from 'react'

import { DescriptionsToSelectContainer } from './styles'
import DescriptionToSelectItem from './DescriptionToSelectItem'

type DescriptionsToSelectProps = {
  pendingDescriptions: string[]
  selectedDescriptions: string[]
  onPendingDescriptionsChange: (descriptions: string[]) => void
  onSelectedDescriptionsChange: (descriptions: string[]) => void
  selectedDescriptionsMax: number
}

const DescriptionsToSelect = ({
  pendingDescriptions,
  selectedDescriptions,
  onPendingDescriptionsChange,
  onSelectedDescriptionsChange,
  selectedDescriptionsMax
}: DescriptionsToSelectProps) => {
  const onDescriptionAddToSelection = (description: string, index: number) => {
    if (selectedDescriptions.length >= selectedDescriptionsMax) return
    onDescriptionDelete(index)
    onSelectedDescriptionsChange([...selectedDescriptions, description])
  }

  const onDescriptionDelete = (index: number) => {
    const updatedDescriptions = [...pendingDescriptions]
    updatedDescriptions.splice(index, 1)
    onPendingDescriptionsChange(updatedDescriptions)
  }

  return (
    <DescriptionsToSelectContainer>
      {pendingDescriptions.map((description, index) => (
        <DescriptionToSelectItem
          key={Math.random()}
          description={description}
          onAdd={() => {
            onDescriptionAddToSelection(description, index)
          }}
          disableAdd={selectedDescriptions.length >= selectedDescriptionsMax}
        />
      ))}
    </DescriptionsToSelectContainer>
  )
}

export default DescriptionsToSelect

import React, { ReactNode } from 'react'

import { CardHeaderContainer } from '../styles'
import H3 from '../../../../../../components/Text/Headlines/H3'

type CardHeaderProps = {
  title: string
  action?: ReactNode
}

const CardHeader = ({ title, action }: CardHeaderProps) => {
  return (
    <CardHeaderContainer>
      <H3>{title}</H3>
      {action}
    </CardHeaderContainer>
  )
}

export default CardHeader

import React, { CSSProperties, ReactNode } from 'react'

import { CardContainer } from './styles'

type CardProps = {
  children: ReactNode
  style?: CSSProperties
}

const Card = ({ children, style }: CardProps) => {
  return <CardContainer style={style}>{children}</CardContainer>
}

export default Card

import React from 'react'

import { StyledSurveyInput } from './styles'

type SurveyInputProps = {
  placeHolder?: string
  width?: string
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SurveyInput = ({
  placeHolder,
  width,
  onChange,
  value
}: SurveyInputProps) => {
  return (
    <StyledSurveyInput
      placeholder={placeHolder}
      width={width}
      onChange={onChange}
      value={value}
    />
  )
}

export default SurveyInput

import React, { ReactNode } from 'react'
import { NavigateFunction } from 'react-router-dom'

import { Page } from './styles'
import LeftMenu from '../../pages/parts/LeftMenu'
import NewLeftMenu from '../../templates/LeftMenuTemplate'
import Header from '../Header'
import Colors from '../../constants/colors'

type AuthenticatedPageProps = {
  isEditor: boolean
  children: ReactNode
  user?: any // TODO declare the type
  companyId?: string
  navigate: NavigateFunction
  pageRef?: React.RefObject<HTMLDivElement>
  newUi?: boolean
}

const AuthenticatedTemplate = ({
  isEditor,
  children,
  companyId,
  navigate,
  pageRef,
  newUi
}: AuthenticatedPageProps) => {
  return (
    <>
      {newUi ? (
        <NewLeftMenu activeIndex={1} companyId={companyId ?? ''} />
      ) : (
        <LeftMenu newUi={newUi} />
      )}
      <Page
        ref={pageRef}
        backgroundColor={newUi ? Colors.NEW_YETIC_BACKGROUND_COLOR : null}
      >
        {newUi == null && (
          <Header
            callback={() => {}}
            isEditor={isEditor}
            navigate={navigate}
            newUi={newUi}
          />
        )}
        {children}
      </Page>
    </>
  )
}

export default AuthenticatedTemplate

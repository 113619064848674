import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'
import { YETIC_NEW_FONT_FAMILY } from '../../../../constants/fontFamily'

export const StyledP = styled.div<{ big?: boolean; small?: boolean }>`
  font-size: ${(props) => (props.big ? '24px' : props.small ? '14px' : '16px')};
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  font-family: ${YETIC_NEW_FONT_FAMILY};
  color: ${Colors.YETIC_BLUE_DARK};
`

import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Padding from '../../../../../../constants/padding'
import Colors from '../../../../../../constants/colors'
import Margin from '../../../../../../constants/margin'

export const SelectionListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: ${Margin.m6};
`

export const SelectionItem = styled.div<{
  height?: string
  width?: string
  selected?: boolean
}>`
  padding: ${Padding.p5};
  box-sizing: border-box;
  min-height: ${({ height }) => height || '120px'};
  width: ${({ width }) => width || '200px'};
  border-radius: 8px;
  background-color: ${Colors.YETIC_WHITE};
  transition: all 0.2s;

  &:hover {
    background-color: ${Colors.NEW_YETIC_GREY_LIGHT};
  }

  ${({ selected }) =>
    selected
      ? `
    border: 2px solid ${Colors.NEW_YETIC_BLUE_DARK};
  `
      : `
    box-shadow: 0 0 0 1px ${Colors.YETIC_GREY_DARK};
    border: 2px solid transparent;
  `}

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const titleStyle: CSSProperties = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: Margin.m3
}

export const otherTitleStyle: CSSProperties = {
  ...titleStyle,
  textAlign: 'center'
}

export const descriptionStyle: CSSProperties = {
  fontSize: 12,
  lineHeight: 1.2,
  marginBottom: Margin.m5
}

export const DisplayedValueWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const displayedValueStyle: CSSProperties = {
  fontSize: 20,
  lineHeight: 1.2,
  fontWeight: 'bold'
}

export const displayedValueComplementStyle: CSSProperties = {
  fontSize: 12,
  lineHeight: 1.2,
  marginLeft: Margin.m2,
  marginTop: Margin.m2
}

import { isDayValid } from './isDayValid'
import { isMonthValid } from './isMonthValid'
import { isValidDate } from './isValidDate'
import { roundDateToDay } from './roundDateToDay'

export const getErrorMessage = (
  date: { day: string; month: string; year: string },
  minDate?: Date,
  maxDate?: Date
) => {
  if (
    date.day.length === 2 &&
    date.month.length === 2 &&
    date.year.length === 4 &&
    isDayValid(date.day) &&
    isMonthValid(date.month)
  ) {
    const d = roundDateToDay(new Date(`${date.year}-${date.month}-${date.day}`))

    if (
      isNaN(d.getTime()) ||
      !isValidDate(Number(date.year), Number(date.month), Number(date.day))
    ) {
      return 'La date entrée est invalide'
    }

    const formatedMinDate = minDate ? roundDateToDay(minDate) : null

    if (formatedMinDate && d < formatedMinDate) {
      return 'La date entrée est inférieure à la date minimale requise.'
    }

    const formatedMaxDate = maxDate ? roundDateToDay(maxDate) : null

    if (formatedMaxDate && d > formatedMaxDate) {
      return 'La date entrée est supérieur à la date maximale requise.'
    }
  }

  return ''
}

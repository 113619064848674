import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Colors from '../../../constants/colors'
import Margin from '../../../constants/margin'
import Padding from '../../../constants/padding'

export const textStyle: CSSProperties = {
  marginBottom: Margin.m7
}

export const warningTextStyle: CSSProperties = {
  color: Colors.YETIC_BLUE_DARK,
  marginTop: Margin.m3,
  opacity: 0.7
}

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 ${Padding.p3} 0;
`

export const SelfEditorMediaPlanCreationFlowChoicePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`

export const SelfEditorMediaPlanCreationFlowChoiceCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
`

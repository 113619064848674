import styled from '@emotion/styled'

import Colors from '../../../../../../../constants/colors'

export const TitlesToSelectContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-content: flex-start;

  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.NEW_YETIC_GREY};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: ${Colors.YETIC_WHITE};
  }
`

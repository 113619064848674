import styled from '@emotion/styled'

import Colors from '../../../../../../../../constants/colors'
import Padding from '../../../../../../../../constants/padding'

export const DescriptionToSelectItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding: ${Padding.p5} ${Padding.p3};
  border-bottom: 1px solid ${Colors.YETIC_GREY_DARK};
  width: 100%;
`

export const DescriptionToSelectItemActionButton = styled.button<{
  disabled?: boolean
}>`
  background-color: ${({ disabled }) =>
    !disabled ? '#0cab0330' : 'transparent'};
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ disabled }) =>
      !disabled ? '#0cab0350' : 'transparent'};
    transition: background-color 0.3s;
  }
`

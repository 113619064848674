/*
export const mockedDescriptions = [
  'Optimisez vos recrutements grâce à l’IA. Testez la puissance maintenant!',
  'Découvrez comment l’IA révolutionne le recrutement. Essayez dès aujourd’hui!',
  'Recrutez plus efficacement avec l’IA. Découvrez nos solutions innovantes.',
  'L’IA au service de vos recrutements. Découvrez nos outils performants.'
]
*/

export const mockedDescriptions = [
  "Venez découvrir notre nouvelle recette de Poutine dès aujourd'hui.",
  'Chez Le yéti du comptoir, venez profiter de notre cuisine maison. ',
  'Commandez notre délicieuse Poutine et profitez de la livraison rapide.',
  'Savourez nos plats maison avec livraison offerte à Rouen.',
  "Essayez notre Poutine maison et profitez d'une livraison gratuite."
]

export const minNumberOfDescriptions = 2
export const maxNumberOfDescriptions = 3

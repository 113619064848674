/*
export const mockedHeadlines = [
  'Rév. gestion RH: IA!',
  'Boostez productivité RH: IA.',
  'Simplifiez processus RH: IA.',
  'Réinventez vos RH: Test IA.',
  'Transformez RH: IA chez nous.',
  'IA et RH: HR Innovations.',
  'IA pour gestion RH.',
  'Efficacité RH: IA.',
  'Optimisez RH: IA.',
  'Gestion RH 2.0: IA.',
  'Réinventez RH: IA.',
  'IA dédiée aux RH.'
]
*/

export const mockedHeadlines = [
  'Le yéti du comptoir',
  'Brasserie à Rouen',
  'Nouvelle recette de Poutine',
  'Livraison offerte',
  'Commandez en ligne',
  'Cuisine faite maison',
  'Livraison à domicile',
  'Commandez dès maintenant',
  'Dégustez notre Poutine',
  'Poutine livrée chez vous'
]

export const minNumberOfHeadlines = 3
export const maxNumberOfHeadlines = 9

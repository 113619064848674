export const checkValue = (val: string, isYear?: boolean) => {
  const validChars = ['0', '1', '2', '3', '4', '5', '7', '8', '9']
  if (isYear && val.length > 4) {
    return false
  } else if (!isYear && val.length > 2) {
    return false
  }

  if (!val.split('').every((c) => validChars.includes(c))) {
    return false
  }

  return true
}

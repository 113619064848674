import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AudienceCreationPage from './AudienceCreationPageSelfEditor'
import AudiencePanel from './UI/Audience'
import { MediaPlanGoal, MediaPlanGoalsDescription } from '../../types/goals'
import { PanelType } from '../../types/templates/panels'
import { CreationPageTemplate } from '../../templates/CreationPageTemplate'
import Input from '../../components/Input'
import { CheckboxListTmp } from '../../tmpComponents/CheckboxList'
import Period from '../components/Period'
import { createMediaPlan } from '../../helpers/queries/mediaPlan/create'
import { FormMediaPlanType } from '../../types/pages/mediaPlanCreation'
import {
  checkIfCreateMediaPlanErrorCodeValid,
  CreateMediaPlanErrorFromApi
} from '../../types/error/mediaPlan/create'
import {
  checkIfGetMediaPlanCreationDataOutputType,
  getMediaPlanCreationData
} from '../../helpers/queries/mediaPlanAudience/getMediaPlanCreationData'
import Breadcrumb from '../../components/BreadCrumb'
import {
  MediaPlanNavigation,
  emptyMediaPlanNavigation
} from '../../types/mediaPlan/navigation'
import { SectionType } from '../../types/templates/section'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import { getAudienceByNameAndCompanyId } from '../../helpers/queries/audiences/getAudienceByName'
import { MediaPlanAudience } from '../../types/mediaPlanAudience'
import ModalAudienceDetails from '../../components/ModalAudienceDetails'
import { deepClone } from '../../helpers/clone'
import AudienceImport from './UI/Audience/AudienceImportButton'
import ExistingAudiencesModal from './UI/Audience/ExistingAudiencesModal'
import {
  GetCompanyAudiencesOutput,
  checkIfGetCompanyAudiencesOutput,
  getCompanyAudiences
} from '../../helpers/queries/audiences/getCompanyAudiences'
import { getAudienceById } from '../../helpers/queries/audiences/getAudienceById'
import {
  DuplicateAudienceOutputError,
  DuplicateAudienceOutputType,
  checkIfDuplicateAudienceOutputTypeArray,
  duplicateExistingAudience
} from '../../helpers/queries/mediaPlanAudience/duplicate'
import { getErrors } from './helpers/errors'
import RenamingSelectedAudiencesModal from './UI/Audience/RenamingSelectedAudiencesModal'
import ConfirmationModal from '../../components/Modal/ConfirmationModal'
import { ModalContent } from './styles'
import DefaultText from '../../components/DefaultText'
import { PeriodTips } from './PeriodTips'
import { AudienceTipsSelfEditor } from './AudienceTips/selfEditor'

const emptyFormMediaPlan = {
  name: '',
  isEditorPaying: true,
  startDate: '',
  endDate: '',
  amountBudget: 0,
  selectedGoals: [],
  selectedAudiences: []
}

export type ExistingAudiences = GetCompanyAudiencesOutput

const SelfEditorMediaPlanCreationDefaultFlow = () => {
  const [formMediaPlan, setFormMediaPlan] =
    useState<FormMediaPlanType>(emptyFormMediaPlan)
  const [isEditAudience, setIsEditAudience] = useState<boolean>(false)
  const [dataAudienceModal, setDataAudienceModal] =
    useState<MediaPlanAudience | null>(null)
  const [audiences, setAudiences] = useState<string[]>([])
  const [isExistingAudiencesModalOpen, setIsExistingAudiencesModalOpen] =
    useState(false)
  const [existingAudiences, setExistingAudiences] =
    useState<ExistingAudiences | null>(null)
  const [selectedAudiencesToImport, setSelectedAudiencesToImport] = useState<
    Record<string, boolean>[] | null
  >([])
  const [isRenamingAudiencesModalOpen, setIsRenamingAudiencesModalOpen] =
    useState(false)
  const [selectedAudiencesNewNames, setSelectedAudiencesNewNames] = useState<
    Record<string, string>[]
  >([])
  const [errorFromApi, setErrorFromApi] = useState<string>('')
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [navigation, setNavigation] = useState<MediaPlanNavigation>(
    emptyMediaPlanNavigation
  )
  const [hasFetchError, setHasFetchError] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [duplicateAudienceError, setDuplicateAudienceError] = useState('')
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  const pageRef = useRef<HTMLDivElement>(null)

  const { companyId } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    if (companyId) {
      ;(async function () {
        const mediaPlanAudiences = await getMediaPlanCreationData(companyId)
        const newExistingAudiences = await getCompanyAudiences({
          companyId
        })

        if (checkIfGetMediaPlanCreationDataOutputType(mediaPlanAudiences)) {
          setAudiences(mediaPlanAudiences.audienceNames)
          setNavigation(mediaPlanAudiences.navigation)
          setHasFetchError(false)
        } else {
          setHasFetchError(true)
        }

        if (checkIfGetCompanyAudiencesOutput(newExistingAudiences)) {
          setExistingAudiences(newExistingAudiences)
          setSelectedAudiencesToImport(
            newExistingAudiences.map((audience) => ({ [audience.id]: false }))
          )
        }
      })()
    } else {
      setHasFetchError(true)
    }
    scrollTo(0, 0)
  }, [companyId])

  useEffect(() => {
    if (!isEditAudience && pageRef.current) {
      pageRef.current.scrollTo(0, pageRef.current.scrollHeight)
    }
  }, [isEditAudience])

  useEffect(() => {
    if (isSubmitLoading) {
      if (companyId) {
        ;(async function () {
          const result = await createMediaPlan({
            ...formMediaPlan,
            companyId
          })

          if (result.error) {
            setErrorFromApi(
              checkIfCreateMediaPlanErrorCodeValid(result.error)
                ? CreateMediaPlanErrorFromApi[result.error]
                : CreateMediaPlanErrorFromApi.UNKNOWN
            )
            setTimeout(() => {
              setErrorFromApi('')
            }, 5000)
          } else {
            navigate(`/media-plan/${result.mediaPlanId as string}/review`)
          }

          if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true)
          }
          setIsSubmitLoading(false)
        })()
      }
    }
  }, [isSubmitLoading])

  if (hasFetchError || !companyId) {
    return <ErrorPage />
  }

  if (
    !audiences ||
    !navigation ||
    !existingAudiences ||
    !selectedAudiencesToImport
  ) {
    return <LoadingPage />
  }

  const selectedAudiencesIds = selectedAudiencesToImport
    ?.filter((obj) => Object.values(obj)[0])
    .map((obj) => Object.keys(obj)[0])

  const selectedAudienceNames = existingAudiences
    ?.filter((obj) => selectedAudiencesIds?.includes(obj.id))
    .map((obj) => obj.name)

  const addToPanels = (
    panels: (JSX.Element | PanelType)[],
    title: string,
    sections: (SectionType | JSX.Element)[],
    titleTooltip?: string
  ) => {
    panels.push({
      title,
      disabled: false,
      sections,
      isDropdown: false,
      titleTooltip
    })
  }

  const errors = getErrors(formMediaPlan, errorFromApi)

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    // TODO : to see if debounce is needed
    setFormMediaPlan({
      ...formMediaPlan,
      name: event.target.value
    })
  }

  // Add the goal with checkboxId if not selected,
  // else remove it from selected goals
  const handleChangeGoal = (checkboxId: string) => {
    if (Object.keys(MediaPlanGoal).includes(checkboxId)) {
      const typedCheckboxId = checkboxId as keyof typeof MediaPlanGoal
      if (formMediaPlan.selectedGoals.includes(typedCheckboxId)) {
        setFormMediaPlan({
          ...formMediaPlan,
          selectedGoals: [...formMediaPlan.selectedGoals].filter(
            (goal) => goal !== typedCheckboxId
          )
        })
      } else {
        setFormMediaPlan({
          ...formMediaPlan,
          selectedGoals: [...formMediaPlan.selectedGoals, typedCheckboxId]
        })
      }
    }
  }

  const handleChangeStartDate = (startDate: string) => {
    setFormMediaPlan({
      ...formMediaPlan,
      startDate
    })
  }

  const handleChangeEndDate = (endDate: string) => {
    setFormMediaPlan({
      ...formMediaPlan,
      endDate
    })
  }

  const handleChangeAmountBudget = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // TODO : to see if debounce is needed
    if (event.target.value && !Number.isNaN(parseFloat(event.target.value))) {
      setFormMediaPlan({
        ...formMediaPlan,
        amountBudget: parseFloat(event.target.value)
      })
    }
  }

  const addSelectedAudience = (newSelectedAudience: string) => {
    setFormMediaPlan({
      ...formMediaPlan,
      selectedAudiences: [
        ...formMediaPlan.selectedAudiences,
        newSelectedAudience
      ]
    })
  }

  const removeSelectedAudience = (indexToDelete: number) => {
    setFormMediaPlan({
      ...formMediaPlan,
      selectedAudiences: [
        ...formMediaPlan.selectedAudiences.filter(
          (_, index: number) => index !== indexToDelete
        )
      ]
    })
  }

  const addAudience = (newAudience: string) => {
    setAudiences([...audiences, newAudience])
    setFormMediaPlan({
      ...formMediaPlan,
      selectedAudiences: [...formMediaPlan.selectedAudiences, newAudience]
    })
  }

  if (isEditAudience) {
    return (
      <AudienceCreationPage
        customerId={companyId}
        back={() => {
          setIsEditAudience(false)
        }}
        validate={(newAudience: string) => {
          addAudience(newAudience)
          setIsEditAudience(false)
        }}
      />
    )
  }

  const handleOpenAudienceListModal = () => {
    setIsExistingAudiencesModalOpen(true)
  }

  const handleChangeSelectedAudiencesToImport = (audienceId: string) => {
    const newSelectedAudiencesToImport = deepClone(selectedAudiencesToImport)

    const currentIndex = newSelectedAudiencesToImport
      .map((obj) => Object.keys(obj)[0])
      .indexOf(audienceId)

    newSelectedAudiencesToImport[currentIndex] = {
      [audienceId]: !newSelectedAudiencesToImport[currentIndex][audienceId]
    }

    setSelectedAudiencesToImport(newSelectedAudiencesToImport)
  }

  const handleOpenRenamingAudiencesModal = () => {
    setSelectedAudiencesNewNames(
      selectedAudiencesToImport
        ?.map((obj) => ({
          [Object.keys(obj)[0]]:
            existingAudiences?.find(
              (audience) => audience.id === Object.keys(obj)[0]
            )?.name ?? ''
        }))
        .filter((obj) => selectedAudiencesIds.includes(Object.keys(obj)[0]))
    )
    setIsRenamingAudiencesModalOpen(true)
  }

  const handleChangeSelectedAudienceName = (
    event: ChangeEvent<HTMLInputElement>,
    audienceId: string
  ) => {
    const newSelectedAudiencesNewNames = deepClone(selectedAudiencesNewNames)

    const currentIndex = newSelectedAudiencesNewNames
      .map((obj) => Object.keys(obj)[0])
      .indexOf(audienceId)

    newSelectedAudiencesNewNames[currentIndex] = {
      [audienceId]: event.target.value
    }

    setSelectedAudiencesNewNames(newSelectedAudiencesNewNames)
  }

  const handleImportAudiences = async () => {
    if (selectedAudiencesNewNames) {
      for (let i = 0; i < selectedAudiencesNewNames.length; i++) {
        if (
          selectedAudienceNames?.includes(
            Object.values(selectedAudiencesNewNames[i])[0].trim()
          ) ||
          audiences.includes(
            Object.values(selectedAudiencesNewNames[i])[0].trim()
          )
        ) {
          setDuplicateAudienceError(
            'Vous ne pouvez pas importer une audience avec un nom déjà existant. Veuillez renommer les audiences concernées.'
          )
          setTimeout(() => {
            setDuplicateAudienceError('')
          }, 5000)
          return
        }
      }

      if (selectedAudienceNames && selectedAudiencesNewNames.length > 0) {
        const results: (
          | DuplicateAudienceOutputType
          | DuplicateAudienceOutputError
        )[] = []

        for (let i = 0; i < selectedAudiencesNewNames.length; i++) {
          const result = await duplicateExistingAudience({
            id: Object.keys(selectedAudiencesNewNames[i])[0],
            newName: Object.values(selectedAudiencesNewNames[i])[0]
          })

          results.push(result)
        }

        if (checkIfDuplicateAudienceOutputTypeArray(results)) {
          setIsRenamingAudiencesModalOpen(false)
          setIsExistingAudiencesModalOpen(false)
          setAudiences([
            ...audiences,
            ...results.map((result) => result.audienceName)
          ])
          setFormMediaPlan({
            ...formMediaPlan,
            selectedAudiences: [
              ...formMediaPlan.selectedAudiences,
              ...results.map((result) => result.audienceName)
            ]
          })
          setSelectedAudiencesToImport([
            ...selectedAudiencesToImport.map((obj) => ({
              [Object.keys(obj)[0]]: false
            }))
          ])
        } else {
          setDuplicateAudienceError(
            "Une erreur est survenue lors de l'importation des audiences. Veuillez réessayer."
          )
          setTimeout(() => {
            setDuplicateAudienceError('')
          }, 5000)
        }
      }
    }
  }

  const importedAudiences = selectedAudiencesToImport
    .filter((audience) => audiences.includes(Object.keys(audience)?.[0]))
    .map((audience) => Object.keys(audience)?.[0])

  const handleSubmitMediaPlan = () => {
    if (Object.values(errors).filter((e) => e).length < 1) {
      setIsSubmitLoading(true)
    } else {
      setHasBeenSubmitted(true)
    }
  }

  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const handleCancelConfirm = () => {
    navigate(`/`)
  }

  const panels: (JSX.Element | PanelType)[] = []

  const inputName = {
    description:
      'Indiquez le nom du produit, service ou événement à promouvoir',
    content: (
      <Input
        type="text"
        onChange={handleChangeName}
        placeholder="Nom du plan publicitaire"
        value={formMediaPlan.name}
        error={hasBeenSubmitted ? errors.name : ''}
        withBorder
      />
    )
  }

  const goalsSelection = {
    description:
      'Sélectionnez un ou plusieurs objectifs pour définir clairement ce que vous souhaitez accomplir',
    content: (
      <CheckboxListTmp
        values={(
          Object.keys(MediaPlanGoal) as (keyof typeof MediaPlanGoal)[]
        ).map((key) => ({
          id: key,
          label: MediaPlanGoal[key],
          description: MediaPlanGoalsDescription[key],
          selected: formMediaPlan.selectedGoals.includes(key)
        }))}
        onChange={handleChangeGoal}
        error={hasBeenSubmitted ? errors.goals : ''}
        withBorder
        height="50px"
      />
    )
  }

  const dayAfterTomorrow = new Date()
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 3)

  const periodTips = <PeriodTips mediaPlanTranslation="plan publicitaire" />

  const periods = {
    description: `Choisissez quand vos publicités seront en ligne en fonction d'événements ou de moments clé pour votre activité.`,
    content: (
      <Period
        startDate={formMediaPlan.startDate}
        endDate={formMediaPlan.endDate}
        minStartDate={dayAfterTomorrow.toISOString().split('T')[0]}
        minEndDate={dayAfterTomorrow.toISOString().split('T')[0]}
        onChangeStartDate={handleChangeStartDate}
        onChangeEndDate={handleChangeEndDate}
        startDateError={hasBeenSubmitted ? errors.startDate : ''}
        endDateError={hasBeenSubmitted ? errors.endDate : ''}
      />
    )
  }

  const handleClickAudienceTag = async (audienceName: string) => {
    const result = await getAudienceByNameAndCompanyId({
      name: audienceName,
      companyId
    })
    if (result) {
      setDataAudienceModal(result)
    }
  }

  const handleSeeExistingAudience = async (audienceId: string) => {
    const result = await getAudienceById({
      id: audienceId
    })
    if (result) {
      setDataAudienceModal(result)
    }
  }

  const audienceTips = <AudienceTipsSelfEditor />

  const audiencePanel = {
    cornerAction: (
      <AudienceImport handleOpenModal={handleOpenAudienceListModal} />
    ),
    description: 'Identifiez toutes les personnes à qui vous vous adressez',
    content: (
      <>
        <AudiencePanel
          audiences={audiences}
          selectedAudiences={formMediaPlan.selectedAudiences}
          addSelectedAudience={addSelectedAudience}
          removeAudience={removeSelectedAudience}
          textButtonAudienceCreation="Créer un nouveau public cible"
          openAudienceCreation={() => {
            setIsEditAudience(true)
          }}
          onClickTag={(audienceName: string) => {
            handleClickAudienceTag(audienceName)
          }}
          error={hasBeenSubmitted ? errors.audience : ''}
        />
        {isExistingAudiencesModalOpen && (
          <ExistingAudiencesModal
            onClose={() => {
              setIsExistingAudiencesModalOpen(false)
            }}
            data={existingAudiences}
            selectedAudiences={selectedAudiencesToImport}
            importedAudiences={importedAudiences}
            handleCheckboxChange={handleChangeSelectedAudiencesToImport}
            handleSeeAudienceDetails={handleSeeExistingAudience}
            handleSubmit={handleOpenRenamingAudiencesModal}
          />
        )}
        {isRenamingAudiencesModalOpen && (
          <RenamingSelectedAudiencesModal
            onClose={() => {
              setIsRenamingAudiencesModalOpen(false)
            }}
            audiencesData={existingAudiences}
            newNames={selectedAudiencesNewNames}
            handleNameChange={handleChangeSelectedAudienceName}
            handleSubmit={() => {
              handleImportAudiences()
            }}
            error={duplicateAudienceError}
          />
        )}
        {isCancelModalOpen && (
          <ConfirmationModal
            title="Votre plan publicitaire est en cours de création"
            textConfirm="Oui, annuler"
            textReject="Non, continuer"
            onClickReject={handleCloseCancelModal}
            onClickConfirm={handleCancelConfirm}
            onClose={handleCloseCancelModal}
          >
            <ModalContent>
              <DefaultText>
                {
                  'Êtes-vous sûr de vouloir annuler la création de votre plan publicitaire ?'
                }
              </DefaultText>
              <DefaultText>
                {'Toutes les informations saisies seront perdues.'}
              </DefaultText>
            </ModalContent>
          </ConfirmationModal>
        )}
      </>
    )
  }

  const inputBudget = {
    description: 'Définissez le montant global que vous êtes prêt à dépenser',
    content: (
      <Input
        type="devise"
        onChange={handleChangeAmountBudget}
        placeholder="Budget en €"
        value={
          formMediaPlan.amountBudget ? String(formMediaPlan.amountBudget) : ''
        }
        style={{ paddingRight: '30px' }}
        error={hasBeenSubmitted ? errors.budget : ''}
        withBorder
      />
    )
  }

  const items = [
    {
      label: 'Plan publicitaire'
    },
    { label: 'Nouveau plan publicitaire' }
  ]

  addToPanels(
    panels,
    'Nom du plan publicitaire',
    [inputName],
    `Exemple: Je souhaite promouvoir mes produits locaux à l'approche des fêtes. Nommez-le "Mes produits pour les fêtes"`
  )
  addToPanels(
    panels,
    'Objectif(s)',
    [goalsSelection],
    `Exemple : Je souhaite attirer de nouveaux clients et inciter les internautes à acheter. Sélectionnez "prospection" et “ventes”`
  )
  panels.push(periodTips)
  addToPanels(
    panels,
    'Période de diffusion',
    [periods],
    `Exemple : Je souhaite attirer l'attention pendant la période des fêtes. Optez pour une diffusion du 15 novembre au 5 janvier.`
  )
  panels.push(audienceTips)
  addToPanels(
    panels,
    'Public cible',
    [audiencePanel],
    `Exemple : Je souhaite cibler des femmes et entreprises qui souhaitent acheter des produits Normands. Créez  “Femmes âgées de 30 à 40 ans, rouennaises” ”Directeur de communication interne rouennaises” `
  )
  addToPanels(
    panels,
    'Budget',
    [inputBudget],
    `Exemple : Pour attirer de nouveaux clients pendant les fêtes, j’investis 1000€ au total. `
  )

  const errorsNotEmpty = Object.values(errors).filter((e) => e)

  const errorsToDisplay =
    errorsNotEmpty.length > 0
      ? [errorsNotEmpty[0]]
      : errorFromApi
      ? [errorFromApi]
      : []

  return (
    <>
      <Breadcrumb items={items} />
      <CreationPageTemplate
        title={'ETAPE 1/3 – Nouveau plan publicitaire'}
        subTitle="Définissez ici les caractéristiques globales de votre plan publicitaire"
        panels={panels}
        errors={{
          errors: hasBeenSubmitted ? errorsToDisplay : []
        }}
        validation={{
          wording: 'Enregistrer et continuer',
          action: handleSubmitMediaPlan,
          disabled: hasBeenSubmitted && errorsNotEmpty.length > 0,
          isLoading: isSubmitLoading
        }}
        cancel={{
          wording: 'Annuler',
          action: () => {
            setIsCancelModalOpen(true)
          },
          disabled: false
        }}
      />
      {dataAudienceModal && (
        <ModalAudienceDetails
          title={"Détail de l'audience"}
          data={dataAudienceModal}
          handleClose={() => {
            setDataAudienceModal(null)
          }}
        />
      )}
    </>
  )
}

export default SelfEditorMediaPlanCreationDefaultFlow

import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import FontSize from '../../../constants/fontSize'
import Colors from '../../../constants/colors'
import { YETIC_NEW_FONT_FAMILY } from '../../../constants/fontFamily'

export const ItemContainer = styled.div<{
  isNotFlex?: boolean
  newFont?: boolean
}>`
  margin-top: ${Margin.m3};
  display: flex;
  flex-direction: ${({ isNotFlex }) => (isNotFlex ? 'column' : 'row')};
  gap: ${({ isNotFlex }) => (isNotFlex ? Margin.m2 : Margin.m0)};
  font-size: ${FontSize.YETIC_PANEL_TITLE};
  position: relative;
  width: fit-content;

  ${({ newFont }) =>
    newFont &&
    `
    font-family: ${YETIC_NEW_FONT_FAMILY};
  `}
`

export const Value = styled.span<{ isNotFlex?: boolean }>`
  margin-left: ${({ isNotFlex }) => (isNotFlex ? Margin.m6 : Margin.m2)};
  text-align: left;
  font-weight: bold;
`

export const ValueLine = styled.div`
  margin-bottom: ${Margin.m2};
`

export const ClickableValue = styled(ValueLine)`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const Label = styled.span`
  margin-right: ${Margin.m2};
  font-size: ${FontSize.YETIC_PANEL_TITLE};
`

export const Tooltip = styled.div`
  cursor: pointer;
  font-size: ${FontSize.YETIC_TEXT_VERY_SMALL};
  font-weight: bold;
  background-color: ${Colors.NEW_YETIC_BOX_INFO_TIP};
  color: white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -15px;
  top: -4px;
`

import React from 'react'

import H3 from '../../Text/Headlines/H3'

type SurveyQuestionProps = {
  text: string
}

const SurveyQuestion = ({ text }: SurveyQuestionProps) => {
  const textParts = text.split('<br/>')

  return textParts.map((text, i) => (
    <H3 style={{ fontWeight: '500', textAlign: 'left' }} key={text}>
      {text}
    </H3>
  ))
}

export default SurveyQuestion

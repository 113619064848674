import React, { ChangeEvent, useState } from 'react'

import SelfEditorAIFlowOpenedQuestionsContent from './Content'
import { OpenedQuestionType } from '../../../../components/Survey/types'

export type SelfEditorAIFlowOpenedQuestionsContainerProps = {
  openedQuestionsData: Record<string, OpenedQuestionType[]>
  questionsFlowId: string
  answers: string[]
  setAnswers: (inputs: string[]) => void
  onValidate: () => void
}

const SelfEditorAIFlowOpenedQuestionsContainer = ({
  openedQuestionsData,
  questionsFlowId,
  answers,
  setAnswers,
  onValidate
}: SelfEditorAIFlowOpenedQuestionsContainerProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentValue, setCurrentValue] = useState('')

  const currentData = openedQuestionsData[questionsFlowId]

  if (currentData == null || currentIndex >= currentData.length) {
    onValidate()
    return <></>
  }

  const handleInputChange = (newInputValue: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(newInputValue.target.value)
  }

  const handleNextAnswer = () => {
    const newAnswers = [...answers]
    newAnswers[currentIndex] = currentValue
    setAnswers(newAnswers)
    setCurrentValue('')
    setCurrentIndex((prev) => prev + 1)
  }

  return (
    <SelfEditorAIFlowOpenedQuestionsContent
      text={currentData[currentIndex].text}
      value={currentValue}
      handleInputChange={handleInputChange}
      handleNextAnswer={handleNextAnswer}
    />
  )
}

export { SelfEditorAIFlowOpenedQuestionsContainer }

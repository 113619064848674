import styled from '@emotion/styled'

export const KeywordsToSelectResultContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-content: flex-start;
`

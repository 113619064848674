export const checkUrl = (url: string) => {
  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
  return urlRegex.test(url)
}

export const checkMoreThanOneCharacter = (value: string) => value.length > 1

export const checkEmail = (email: string) => {
  const regex = /^([+a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
  return regex.test(email)
}

export const checkPassword = (password: string) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{12,}$/
  return regex.test(password)
}

export const checkPhoneNumber = (phoneNumber: string) => {
  const regex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
  return regex.test(phoneNumber)
}

export const checkIfEmptyErrors = (errors: Record<string, string>) => {
  return Object.values(errors).every((error) => error === '')
}

export const checkVoidFunctionHasStringParam = (
  func: any
): func is (value: string) => void => {
  return (
    typeof func === 'function' &&
    func.length === 1 &&
    typeof func('') === 'undefined'
  )
}

export const checkVoidFunctionHasNoParam = (func: any): func is () => void => {
  return typeof func === 'function' && func.length === 0
}

export const checkVoidFunctionHasNumberParam = (
  func: any
): func is (value: number) => void => {
  return (
    typeof func === 'function' &&
    func.length === 1 &&
    typeof func(0) === 'undefined'
  )
}

import React, { CSSProperties } from 'react'

import {
  StepperStep,
  StepperStepBubble,
  StyledStepper,
  doneColor
} from './styles'
import P from '../Text/Paragraph'
import Colors from '../../constants/colors'

type StepperProps = {
  steps: string[]
  currentIndex: number
  marginBottom?: string
  style?: CSSProperties
}

const Stepper = ({
  steps,
  currentIndex,
  marginBottom,
  style
}: StepperProps) => {
  return (
    <StyledStepper marginBottom={marginBottom} style={style}>
      {steps.map((step, index) => {
        const done = index < currentIndex
        const current = index === currentIndex

        return (
          <StepperStep key={step}>
            <StepperStepBubble
              current={index === currentIndex}
              done={index < currentIndex}
              last={index === steps.length - 1}
            >
              {index < currentIndex ? '✓' : ''}
            </StepperStepBubble>
            <P
              small
              style={{
                color: done
                  ? doneColor
                  : current
                  ? 'black'
                  : Colors.YETIC_GREY_DARK
              }}
            >
              {step}
            </P>
          </StepperStep>
        )
      })}
    </StyledStepper>
  )
}

export default Stepper

import React, { CSSProperties } from 'react'

import { StyledH3 } from './styles'

type H3Props = {
  children: string | number | string[]
  style?: CSSProperties
}

const H3 = ({ children, style }: H3Props) => {
  return <StyledH3 style={style}>{children}</StyledH3>
}

export default H3

import React, { useState } from 'react'

import {
  ScrollPageWrapper,
  SelfEditorAIFlowPaymentBody,
  SelfEditorAIFlowPaymentButtons,
  SelfEditorAIFlowPaymentColumn,
  StyledWrapper,
  selfEditorAIFlowInfoFirstItemTitleStyle,
  selfEditorAIFlowInfoItemTitleStyle
} from './styles'
import GooglePreview from '../../../../components/PreviewAds/Google/Text'
import Button from '../../../../components/Button'
import Margin from '../../../../constants/margin'
import P from '../../../../components/Text/Paragraph'
import ValidationModal from './ValidationModal'
import Card from '../components/Card'
import CardHeader from '../components/Card/Header'
import CardContent from '../components/Card/CardContent'
import Colors from '../../../../constants/colors'
import TagList from '../../../../components/Tag/new/TagList'
import Stepper from '../../../../components/Stepper'
import { aiFlowSteps } from '../constants'
import H2 from '../../../../components/Text/Headlines/H2'
import { roundToTwoDecimals } from '../../../../helpers/rounds'

export type SelfEditorAIFlowContentProps = {
  name: string
  url: string
  startDate: number
  endDate: number
  budget: number
  headlines: string[]
  keywords: string[]
  descriptions: string[]
  handlePrevious: () => void
  onValidate: () => void
  validationSuccessMessage?: string
  validationErrorMessage?: string
}

const SelfEditorAIFlowContent = ({
  name,
  url,
  startDate,
  endDate,
  budget,
  headlines,
  keywords,
  descriptions,
  handlePrevious,
  onValidate,
  validationSuccessMessage,
  validationErrorMessage
}: SelfEditorAIFlowContentProps) => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)

  const durationInDays = Math.round((endDate - startDate) / 1000 / 60 / 60 / 24)

  const handleOpenPaymentModal = () => {
    setIsPaymentModalOpen(true)
  }

  const handleClosePaymentModal = () => {
    setIsPaymentModalOpen(false)
  }

  return (
    <>
      <ScrollPageWrapper>
        <StyledWrapper>
          <Stepper
            steps={aiFlowSteps}
            currentIndex={validationSuccessMessage ? 6 : 5}
            marginBottom={Margin.m10}
          />
          <H2>{`Validation et paiement de la campagne`}</H2>
          <P style={{ marginTop: Margin.m4, marginBottom: Margin.m8 }}>
            {`Veuillez vérifier les informations de votre campagne publicitaire avant de procéder au paiement.`}
          </P>
          <SelfEditorAIFlowPaymentBody>
            <SelfEditorAIFlowPaymentColumn>
              <Card style={{ borderRadius: 0, boxShadow: 'none' }}>
                <CardHeader title="Aperçu de l'annonce" />
                <CardContent height="100%">
                  <GooglePreview
                    url={url}
                    title1={headlines[0]}
                    title2={headlines[1]}
                    title3={headlines[2]}
                    description={descriptions[0]}
                    description2={descriptions[1]}
                    description3={descriptions[2]}
                    style={{ width: 'fit-content' }}
                  />
                </CardContent>
              </Card>
            </SelfEditorAIFlowPaymentColumn>
            <SelfEditorAIFlowPaymentColumn>
              <Card
                style={{
                  borderRadius: 0,
                  boxShadow: 'none',
                  borderLeft: `1px solid ${Colors.YETIC_GREY_DARK}`
                }}
              >
                <CardHeader title="Récap des infos" />
                <CardContent>
                  <P style={selfEditorAIFlowInfoFirstItemTitleStyle}>
                    Période de diffusion
                  </P>
                  <P>{`${new Date(startDate).toLocaleDateString()} - ${new Date(
                    endDate
                  ).toLocaleDateString()} (${durationInDays} jours)`}</P>
                  <P style={selfEditorAIFlowInfoItemTitleStyle}>Budget</P>
                  <P>{`${roundToTwoDecimals(
                    budget * durationInDays
                  )} € (${budget}€/jour)`}</P>
                  <P style={selfEditorAIFlowInfoItemTitleStyle}>
                    Nom de la campagne
                  </P>
                  <P>{name}</P>
                  <P style={selfEditorAIFlowInfoItemTitleStyle}>Url</P>
                  <P style={{ fontStyle: 'italic' }}>{url}</P>
                  <P style={selfEditorAIFlowInfoItemTitleStyle}>Mots-clés</P>
                  <TagList tags={keywords} />
                  <P style={selfEditorAIFlowInfoItemTitleStyle}>Titres</P>
                  <TagList tags={headlines} />
                  <P style={selfEditorAIFlowInfoItemTitleStyle}>Descriptions</P>
                  {descriptions.map((description, index) => (
                    <P
                      key={description}
                      style={{ marginBottom: Margin.m3 }}
                    >{`${index + 1}. ${description}`}</P>
                  ))}
                </CardContent>
              </Card>
            </SelfEditorAIFlowPaymentColumn>
          </SelfEditorAIFlowPaymentBody>
          <SelfEditorAIFlowPaymentButtons>
            <Button
              type="secondary"
              width="fit-content"
              onClick={handlePrevious}
            >
              Retour
            </Button>
            <Button
              type="primary"
              width="fit-content"
              onClick={handleOpenPaymentModal}
            >
              Valider et payer
            </Button>
          </SelfEditorAIFlowPaymentButtons>
        </StyledWrapper>
      </ScrollPageWrapper>
      {isPaymentModalOpen && (
        <ValidationModal
          onClose={handleClosePaymentModal}
          handleSubmit={onValidate}
          isLoading={false}
          error={validationErrorMessage ?? ''}
          validationMessage={validationSuccessMessage ?? ''}
          budget={budget}
          name={name}
        />
      )}
    </>
  )
}

export default SelfEditorAIFlowContent

import React from 'react'

import TableList from '../../../../../components/new/TableList'

type AIFlowSummaryProps = {
  summary: Record<string, string>
}

const AIFlowSummary = ({ summary }: AIFlowSummaryProps) => {
  return <TableList data={summary} />
}

export default AIFlowSummary

import React, { useState } from 'react'

import {
  BudgetWrapper,
  DurationWrapper,
  RecapInfoItem,
  StyledContent,
  StyledWrapper,
  invalidSectionTextStyle,
  titleStyle
} from './styles'
import SelfEditorAIFlowCampaignParamsModal from './SelfEditorAIFlowCampaignParamsModal'
import SelectionInputWithOther, {
  SelectionOption
} from './SelectionInputWithOther'
import SelfEditorAIFlowCampaignParamsButtonsBar from './SelfEditorAIFlowCampaignParamsButtonsBar'
import Margin from '../../../../constants/margin'
import Stepper from '../../../../components/Stepper'
import { aiFlowSteps } from '../constants'
import Card from '../components/Card'
import CardContent from '../components/Card/CardContent'
import H2 from '../../../../components/Text/Headlines/H2'
import P from '../../../../components/Text/Paragraph'
import CardHeader from '../components/Card/Header'
import { formatDateToFrench } from '../../../../helpers/date'
import DatePicker from '../../../../components/new/DatePicker'

type SelfEditorAIFlowCampaignParamsContentProps = {
  startDate: number | null
  minStartDate: Date
  dailyBudget: number | null
  handleChangeStartDate: (date: number | null) => void
  handleChangeDailyBudget: (dailyBudget: number | null) => void
  handlePrevious: () => void
  handleNext: () => void
  isSummaryModalOpen: boolean
  handleToggleOpenModal: () => void
  durationOptions: SelectionOption[]
  durationInDays: number | null
  endDate: number | null
  handleChangeDuration: (
    duration: number | null,
    startDate: number | null
  ) => void
  dailyBudgetOptions: SelectionOption[]
  summary: Record<string, string>
  isSectionValid: boolean
  errorMessage: string
}

const SelfEditorAIFlowCampaignParamsContent = ({
  startDate,
  minStartDate,
  dailyBudget,
  handleChangeStartDate,
  handleChangeDailyBudget,
  handlePrevious,
  handleNext,
  isSummaryModalOpen,
  handleToggleOpenModal,
  durationOptions,
  durationInDays,
  endDate,
  handleChangeDuration,
  dailyBudgetOptions,
  summary,
  isSectionValid,
  errorMessage
}: SelfEditorAIFlowCampaignParamsContentProps) => {
  const [isDailyBudgetCustom, setIsDailyBudgetCustom] = useState(
    !!dailyBudget &&
      !dailyBudgetOptions.map((option) => option.value).includes(dailyBudget)
  )
  const [isDurationCustom, setIsDurationCustom] = useState(
    !!durationInDays &&
      !durationOptions.map((option) => option.value).includes(durationInDays)
  )

  const selectedDurationIndex = isDurationCustom
    ? durationOptions.length
    : durationOptions.findIndex((option) => option.value === durationInDays)

  const selectedBudgetIndex = isDailyBudgetCustom
    ? dailyBudgetOptions.length
    : dailyBudgetOptions.findIndex((option) => option.value === dailyBudget)

  const handleChangeSelectedDurationIndex = (selectedIndex: number) => {
    if (selectedIndex >= 0 && selectedIndex < durationOptions.length) {
      handleChangeDuration(durationOptions[selectedIndex].value, startDate)
      if (isDurationCustom) {
        setIsDurationCustom(false)
      }
    } else {
      handleChangeDuration(null, startDate)
      setIsDurationCustom(true)
    }
  }

  const handleChangeSelectedBudgetIndex = (selectedIndex: number) => {
    if (selectedIndex >= 0 && selectedIndex < dailyBudgetOptions.length) {
      handleChangeDailyBudget(dailyBudgetOptions[selectedIndex].value)
      if (isDailyBudgetCustom) {
        setIsDailyBudgetCustom(false)
      }
    } else {
      handleChangeDailyBudget(null)
      setIsDailyBudgetCustom(true)
    }
  }

  return (
    <>
      <StyledWrapper>
        <Stepper
          steps={aiFlowSteps}
          currentIndex={1}
          marginBottom={Margin.m8}
        />
        <H2 style={titleStyle}>Paramétrage de votre campagne</H2>
        <StyledContent>
          <Card>
            <CardHeader title="Date de début" />
            <CardContent>
              <DatePicker
                label="Date de début"
                onDateChange={handleChangeStartDate}
                minDate={minStartDate}
              />
            </CardContent>
          </Card>
          {startDate && (
            <Card>
              <CardHeader title="Durée" />
              <CardContent>
                <DurationWrapper>
                  <P>
                    Choisissez une durée parmi celles proposées ou définissez
                    votre propre durée pour votre campagne
                  </P>
                  <SelectionInputWithOther
                    options={durationOptions}
                    selectedIndex={selectedDurationIndex}
                    setSelectedIndex={handleChangeSelectedDurationIndex}
                    customValue={
                      durationInDays ? durationInDays.toString() : ''
                    }
                    handleCustomValueChange={(value) => {
                      handleChangeDuration(value, startDate)
                    }}
                    label="Durée (en jours)"
                    placeholder="14"
                    type="number"
                  />
                </DurationWrapper>
              </CardContent>
            </Card>
          )}
          {startDate && durationInDays && (
            <Card>
              <CardHeader title="Budget" />
              <CardContent>
                <BudgetWrapper>
                  <P>
                    Choisissez un budget journalier parmi ceux proposés ou
                    définissez votre propre budget journalier pour votre
                    campagne
                  </P>
                  <SelectionInputWithOther
                    options={dailyBudgetOptions}
                    selectedIndex={selectedBudgetIndex}
                    setSelectedIndex={handleChangeSelectedBudgetIndex}
                    customValue={dailyBudget ? dailyBudget.toString() : ''}
                    handleCustomValueChange={handleChangeDailyBudget}
                    label="Budget journalier (en euros)"
                    placeholder="50"
                    type="number"
                  />
                </BudgetWrapper>
              </CardContent>
            </Card>
          )}
          {startDate && durationInDays && endDate && dailyBudget && (
            <Card>
              <CardHeader title="Récapitulatif" />
              <CardContent>
                <P>
                  Votre campagne sera diffusée du{` `}
                  <RecapInfoItem>{`${formatDateToFrench(
                    new Date(startDate)
                  )}`}</RecapInfoItem>
                  {` `}au{` `}
                  <RecapInfoItem>{`${formatDateToFrench(
                    new Date(endDate)
                  )}`}</RecapInfoItem>
                </P>
                <P style={{ marginTop: Margin.m3 }}>
                  Budget total :{` `}
                  <RecapInfoItem>{`${
                    dailyBudget * durationInDays
                  }€`}</RecapInfoItem>
                </P>
              </CardContent>
            </Card>
          )}
        </StyledContent>
        <SelfEditorAIFlowCampaignParamsButtonsBar
          isSectionValid={isSectionValid}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          handleClickOpenModal={handleToggleOpenModal}
        />
        {!isSectionValid && (
          <P small style={invalidSectionTextStyle}>
            {errorMessage}
          </P>
        )}
      </StyledWrapper>
      {isSummaryModalOpen && (
        <SelfEditorAIFlowCampaignParamsModal
          onClose={handleToggleOpenModal}
          summary={summary}
        />
      )}
    </>
  )
}

export default SelfEditorAIFlowCampaignParamsContent

import React, { useEffect, useState } from 'react'

import {
  EditingTitleModalActions,
  EditingTitleModalContent,
  SelectedTitlesContainer
} from './styles'
import TagList from '../../../../../../components/Tag/new/TagList'
import { TagProps } from '../../../../../../components/Tag/new'
import Modal from '../../../../../../components/Modal'
import Input from '../../../../../../components/Input'
import P from '../../../../../../components/Text/Paragraph'
import Colors from '../../../../../../constants/colors'
import Button from '../../../../../../components/Button'
import { TagActionEnum } from '../../../../../../components/Tag/new/types'

type SelectedTitlesProps = {
  titles: string[]
  onTitlesChange: (titles: string[]) => void
}

const SelectedTitles = ({ titles, onTitlesChange }: SelectedTitlesProps) => {
  const [editingTitle, setEditingTitle] = useState<number | null>(null)
  const [newTitle, setNewTitle] = useState('')

  useEffect(() => {
    if (editingTitle !== null) {
      setNewTitle(titles[editingTitle])
    }
  }, [editingTitle, titles])

  const onEditingTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(e.target.value)
  }

  const handleOnSave = () => {
    if (editingTitle == null) return
    const updatedTitles = [...titles]
    updatedTitles[editingTitle] = newTitle
    onTitlesChange(updatedTitles)
    setEditingTitle(null)
  }

  const onEndEditing = () => {
    setEditingTitle(null)
  }

  const onTitleDelete = (index: number) => {
    const updatedTitles = [...titles]
    updatedTitles.splice(index, 1)
    onTitlesChange(updatedTitles)
  }

  const tagActions: TagProps['actions'] = [
    {
      onClick: (index: number) => {
        setEditingTitle(index)
      },
      type: TagActionEnum.EDIT
    },
    {
      onClick: (index: number) => {
        onTitleDelete(index)
      },
      type: TagActionEnum.DELETE
    }
  ]

  return (
    <>
      <SelectedTitlesContainer>
        <TagList tags={titles} actions={tagActions} />
      </SelectedTitlesContainer>
      {editingTitle !== null && (
        <Modal onClose={onEndEditing}>
          <P big style={{ marginBottom: '24px' }}>
            Modification du titre
          </P>
          <EditingTitleModalContent>
            <Input
              type="text"
              value={newTitle}
              onChange={onEditingTitleChange}
              style={{
                borderRadius: '5px',
                border: `1px solid ${Colors.YETIC_GREY_DARK}`
              }}
              containerStyle={{ width: '100%' }}
            />
            <EditingTitleModalActions>
              <Button type="secondary" onClick={onEndEditing}>
                Annuler
              </Button>
              <Button onClick={handleOnSave}>Enregistrer</Button>
            </EditingTitleModalActions>
          </EditingTitleModalContent>
        </Modal>
      )}
    </>
  )
}

export default SelectedTitles

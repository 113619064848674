import styled from '@emotion/styled'

import Colors from '../../../constants/colors'

export const StyledSurveyInput = styled.input<{
  width?: string
}>`
  box-shadow: rgba(10, 10, 10, 0.3) 0px 1px;
  font-size: 30px;
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  background-color: transparent;
  transition: background-color 1e8s ease 0s, box-shadow 0.1s ease-out 0s;
  transform: translateZ(0px);
  background-image: none;
  border-radius: 0px;
  border: none;
  color: rgb(10, 10, 10);
  width: ${(props: { width?: string }) => (props.width ? props.width : '100%')};
  padding-bottom: 8px;

  :focus {
    box-shadow: rgb(10, 10, 10) 0px 2px;
  }
  ::placeholder {
    color: ${Colors.YETIC_BLUE_DARK};
    opacity: 0.3;
  }
`

import React, { useState } from 'react'

import SelfEditorAIFlowDescriptionsGenModalContent from './Content'

type SelfEditorAIFlowDescriptionsGenModalProps = {
  onClose: () => void
  summary: Record<string, string>
  headlines: string[]
  keywords: string[]
  updateSummary: (summary: Record<string, string>) => void
}

const SelfEditorAIFlowDescriptionsGenModal = ({
  onClose,
  summary,
  headlines,
  keywords,
  updateSummary
}: SelfEditorAIFlowDescriptionsGenModalProps) => {
  const [isEditing, setIsEditing] = useState(false)

  const summaryWithHeadlinesAndKeywords = {
    ...summary,
    Titres: headlines.map((curr) => `${curr}`).join(' | '),
    'Mots-clés': keywords.map((curr) => `${curr}`).join(' | ')
  }

  return !isEditing ? (
    <SelfEditorAIFlowDescriptionsGenModalContent
      onClose={onClose}
      summary={summaryWithHeadlinesAndKeywords}
      updateSummary={updateSummary}
    />
  ) : (
    <div>Edition en cours...</div>
  )
}

export default SelfEditorAIFlowDescriptionsGenModal

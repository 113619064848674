enum FontSize {
  YETIC_TITLE = '24px',
  YETIC_SUBTITLE = '21px',
  YETIC_TITLE_THIN = '18px',
  YETIC_TEXT = '14px',
  YETIC_TEXT_MEDIUM = '14px',
  YETIC_TEXT_SMALL = '12px',
  YETIC_PANEL_TITLE = '14px',
  YETIC_PANEL_SUBTITLE = '12px',
  YETIC_INPUT_TEXT = '12px',
  YETIC_TEXT_VERY_SMALL = '10px',
  YETIC_HOME_BOX_TITLE = '16px',
  YETIC_TITLE_H2 = '16px',
  YETIC_TITLE_HUGE = '78px',

  NEW_YETIC_SMALL = '14px',
  NEW_YETIC_P = '18px',
  NEW_YETIC_H1 = '48px',
  NEW_YETIC_H2 = '32px',
  NEW_YETIC_H3 = '24px'
}

export default FontSize

enum Margin {
  auto = 'auto',
  m0 = '0px',
  m1 = '2px',
  m2 = '4px',
  m3 = '8px',
  m4 = '12px',
  m5 = '16px',
  m6 = '20px',
  m7 = '24px',
  m8 = '32px',
  m9 = '40px',
  m10 = '48px',
  m11 = '64px',
  m12 = '128px'
}

export enum MarginTitleEnum {
  marginBottom = '20px'
}

export enum MarginButtonEnum {
  marginLeft = '32px',
  marginBottom = '20px',
  marginTop = '20px'
}

export default Margin

import React from 'react'

import {
  SurveyChoiceButton,
  SurveyChoiceButtonContent,
  SurveyChoiceButtonsWrapper,
  surveyChoiceDescriptionStyle,
  surveyChoiceTitleStyle
} from '../styles'
import { SurveyNextType } from '../types'
import DefaultText from '../../DefaultText'

type SurveyNavigationProps = {
  updatedOnAnswerClick: (
    answerId: string,
    switchToOpenedQuestions?: boolean
  ) => void
  nextButtons: SurveyNextType[]
}

const SurveyNextButtons = ({
  updatedOnAnswerClick,
  nextButtons
}: SurveyNavigationProps) => {
  const numberOfAnswers = nextButtons.length ?? 0
  const choiceButtonFlex = numberOfAnswers ? 1 / numberOfAnswers : 1

  const renderChoiceText = (
    text: string | { title: string; description: string }
  ) => {
    if (typeof text === 'string') {
      return text
    }

    return (
      <SurveyChoiceButtonContent>
        <DefaultText style={surveyChoiceTitleStyle}>{text.title}</DefaultText>
        <DefaultText style={surveyChoiceDescriptionStyle}>
          {text.description}
        </DefaultText>
      </SurveyChoiceButtonContent>
    )
  }

  return (
    <SurveyChoiceButtonsWrapper>
      {nextButtons.map(
        ({
          id,
          activeBorderColor,
          activeBackgroundColor,
          text,
          switchToOpenedQuestions
        }) => (
          <SurveyChoiceButton
            onClick={() => {
              updatedOnAnswerClick(id, switchToOpenedQuestions)
            }}
            activeBorderColor={activeBorderColor}
            activeBackgroundColor={activeBackgroundColor}
            flex={choiceButtonFlex}
            key={`${Math.random()}-${id}`}
          >
            {renderChoiceText(text)}
          </SurveyChoiceButton>
        )
      )}
    </SurveyChoiceButtonsWrapper>
  )
}

export default SurveyNextButtons

import React, { useState } from 'react'

import SelfEditorAIFlowContent from '../SelfEditorAIFlowPayment/Content'

export type SelfEditorAIFlowPaymentContainerProps = {
  name: string
  url: string
  startDate: number | null
  endDate: number | null
  budget: number | null
  headlines: string[]
  keywords: string[]
  descriptions: string[]
  handlePrevious: () => void
}

const SelfEditorAIFlowPaymentContainer = ({
  name,
  url,
  startDate,
  endDate,
  budget,
  headlines,
  keywords,
  descriptions,
  handlePrevious
}: SelfEditorAIFlowPaymentContainerProps) => {
  const [validationSuccessMessage, setValidationSuccessMessage] = useState('')
  const [validationErrorMessage, setValidationErrorMessage] = useState('')

  const handleValidatePayment = () => {
    // TODO : call route to create mp and validate payment
    // setValidationErrorMessage('Une erreur est survenue lors de la validation de votre annonce. Veuillez réessayer.')
    setValidationSuccessMessage(
      'Votre annonce a bien été créée. Un email contenant les informations de paiement vous a été envoyé.'
    )
  }

  if (!startDate || !endDate || !budget) {
    return null
  }

  return (
    <SelfEditorAIFlowContent
      name={name}
      url={url}
      startDate={startDate}
      endDate={endDate}
      budget={budget}
      headlines={headlines}
      keywords={keywords}
      descriptions={descriptions}
      handlePrevious={handlePrevious}
      onValidate={handleValidatePayment}
      validationSuccessMessage={validationSuccessMessage}
      validationErrorMessage={validationErrorMessage}
    />
  )
}

export { SelfEditorAIFlowPaymentContainer }

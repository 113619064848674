import React, { CSSProperties, FC, ReactNode, MouseEventHandler } from 'react'

import {
  NewStyledButton,
  NewStyledButtonSecondary,
  NewStyledButtonTertiary
} from './styles'

export type ButtonPropsType = {
  children?: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  width?: string
  heigth?: string
  type?: 'primary' | 'secondary' | 'tertiary' | 'toggle' | 'textButton'
  active?: boolean
  disabled?: boolean
  marginBottom?: string
  marginTop?: string
  style?: CSSProperties
  padding?: string
  large?: boolean
}

const NewButton = ({
  children,
  onClick = () => {},
  width,
  heigth,
  type = 'primary',
  active = false,
  disabled = false,
  marginBottom = undefined,
  marginTop = undefined,
  padding,
  style = {},
  large
}: ButtonPropsType) => {
  if (type === 'secondary') {
    return (
      <NewStyledButtonSecondary
        onClick={onClick}
        style={style}
        heigth={heigth}
        width={width}
        disabled={disabled}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
        large={large}
      >
        {children}
      </NewStyledButtonSecondary>
    )
  } else if (type === 'tertiary') {
    return (
      <NewStyledButtonTertiary
        onClick={onClick}
        style={style}
        heigth={heigth}
        width={width}
        disabled={disabled}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
        large={large}
      >
        {children}
      </NewStyledButtonTertiary>
    )
  } else {
    return (
      <NewStyledButton
        onClick={onClick}
        style={style}
        heigth={heigth}
        width={width}
        disabled={disabled}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
        large={large}
      >
        {children}
      </NewStyledButton>
    )
  }
}

export default NewButton

import React, { ChangeEvent } from 'react'

import Button from '../../../../components/Button'
import { StyledCenteredDiv, StyledWrapper } from './styles'
import Margin from '../../../../constants/margin'
import Stepper from '../../../../components/Stepper'
import { aiFlowSteps } from '../constants'
import SurveyInput from '../../../../components/SurveyInput'
import H1 from '../../../../components/Text/Headlines/H1'
import P from '../../../../components/Text/Paragraph'

export type SelfEditorAIFlowOpenedQuestionsContentProps = {
  value: string
  handleInputChange: (value: ChangeEvent<HTMLInputElement>) => void
  handleSubmit: () => void
}

const SelfEditorAIFlowUrlContent = ({
  value,
  handleInputChange,
  handleSubmit
}: SelfEditorAIFlowOpenedQuestionsContentProps) => (
  <StyledWrapper>
    <Stepper steps={aiFlowSteps} currentIndex={0} marginBottom={Margin.m12} />
    <StyledCenteredDiv>
      <H1>{`URL de destination`}</H1>
      <P style={{ marginTop: Margin.m5, marginBottom: Margin.m5 }}>
        {`Lorsqu'un utilisateur va cliquer sur votre publicité, il sera redirigé vers une URL de votre choix.`}
      </P>
      <P style={{ marginBottom: Margin.m8 }}>
        {`Veuillez entrer l'URL de destination :`}
      </P>
      <SurveyInput
        placeHolder={`Entrez votre URL`}
        onChange={handleInputChange}
        value={value}
      />
      <Button newFont type="primary" onClick={handleSubmit}>
        Valider
      </Button>
    </StyledCenteredDiv>
  </StyledWrapper>
)

export default SelfEditorAIFlowUrlContent

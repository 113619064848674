import React from 'react'

import ConfirmationModal from '../../../../../components/Modal/ConfirmationModal'
import P from '../../../../../components/Text/Paragraph'
import { InputError } from '../../../../../components/Input/Styles'
import Margin from '../../../../../constants/margin'

type ValidateModalProps = {
  onClose: () => void
  // paymentType: number
  // handleChangePaymentType: (paymentType: number) => void
  isLoading: boolean
  error: string
  validationMessage: string
  handleSubmit: () => void
  budget: number
  name: string
}

const ValidateModal = ({
  onClose,
  // paymentType,
  // handleChangePaymentType,
  isLoading,
  error,
  validationMessage,
  handleSubmit,
  budget,
  name
}: ValidateModalProps) => {
  return (
    <ConfirmationModal
      onClose={onClose}
      onClickReject={onClose}
      onClickConfirm={handleSubmit}
      textConfirm="Valider et passer au paiement"
      isConfirmLoading={isLoading}
      textReject={validationMessage ? 'Fermer' : 'Annuler'}
      title="Validation de la campagne"
      confirmHidden={!!validationMessage}
      divButtonsStyle={{ justifyContent: 'flex-end' }}
      style={{ maxWidth: '400px' }}
    >
      <P
        style={{ marginBottom: Margin.m4 }}
      >{`Vous vous apprêtez à valider la campagne « ${name} » dont le budget s'élève à ${budget}€ HT.`}</P>
      <P>{`Vous recevrez par email un lien de paiement dont le montant total à régler inclura les 20% de TVA.`}</P>
      {/* <DefaultText size="small" style={{ marginBottom: Margin.m6 }}>
        Veuillez sélectionner un moyen de paiement pour valider votre plan média
      </DefaultText>
      <RadioListTmp
        values={[
          {
            label: 'Paiement en 1x',
            description: 'Paiement de la totalité du plan média en 1 fois'
          }
        ]}
        selectedValue={paymentType}
        onChange={handleChangePaymentType}
      /> */}
      {error && (
        <InputError style={{ marginTop: Margin.m6, marginLeft: 0 }}>
          {error}
        </InputError>
      )}
      {validationMessage && (
        <P style={{ fontSize: 14, color: 'green', marginTop: Margin.m6 }}>
          {validationMessage}
        </P>
      )}
    </ConfirmationModal>
  )
}

export default ValidateModal

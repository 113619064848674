import React from 'react'

import SelfEditorAIFlowIntroductionCampaignParamsContent from './Content'

export type SelfEditorAIFlowIntroductionCampaignParamsContainerProps = {
  handleClick: () => void
}

const SelfEditorAIFlowIntroductionCampaignParamsContainer = ({
  handleClick
}: SelfEditorAIFlowIntroductionCampaignParamsContainerProps) => {
  return (
    <SelfEditorAIFlowIntroductionCampaignParamsContent
      handleClick={handleClick}
    />
  )
}

export { SelfEditorAIFlowIntroductionCampaignParamsContainer }

import React, { useState } from 'react'

import SelfEditorAIFlowHeadlinesGenModalContent from './Content'

type SelfEditorAIFlowHeadlinesGenModalProps = {
  onClose: () => void
  summary: Record<string, string>
  updateSummary: (summary: Record<string, string>) => void
}

const SelfEditorAIFlowHeadlinesGenModal = ({
  onClose,
  summary,
  updateSummary
}: SelfEditorAIFlowHeadlinesGenModalProps) => {
  const [isEditing, setIsEditing] = useState(false)

  return !isEditing ? (
    <SelfEditorAIFlowHeadlinesGenModalContent
      onClose={onClose}
      summary={summary}
      updateSummary={updateSummary}
    />
  ) : (
    <div>Edition en cours...</div>
  )
}

export default SelfEditorAIFlowHeadlinesGenModal

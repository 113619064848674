import React, { ChangeEvent, useState } from 'react'

import SelfEditorAIFlowUrlContent from './Content'

export type SelfEditorAIFlowOpenedQuestionsContainerProps = {
  onValidate: (input: string) => void
}

const SelfEditorAIFlowUrlContainer = ({
  onValidate
}: SelfEditorAIFlowOpenedQuestionsContainerProps) => {
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (newInputValue: ChangeEvent<HTMLInputElement>) => {
    setInputValue(newInputValue.target.value)
  }

  const handleSubmit = () => {
    onValidate(inputValue)
  }

  return (
    <SelfEditorAIFlowUrlContent
      value={inputValue}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
    />
  )
}

export { SelfEditorAIFlowUrlContainer }

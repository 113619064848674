import React, { useState } from 'react'

import { IconWrapper, StyledWrapper } from './styles'
import DefaultText from '../../../../../components/DefaultText'
import Margin from '../../../../../constants/margin'
import Colors from '../../../../../constants/colors'
import { IconType } from '../../../../../types/icon'
import H3 from '../../../../../components/Text/Headlines/H3'
import P from '../../../../../components/Text/Paragraph'

type CreationFlowChoiceTileProps = {
  icon: ({ height, width, style, color }: IconType) => JSX.Element
  title: string
  description: string
  onClick: () => void
}

const CreationFlowChoiceTile = ({
  icon,
  title,
  description,
  onClick
}: CreationFlowChoiceTileProps) => {
  const Icon = icon

  return (
    <StyledWrapper onClick={onClick}>
      <IconWrapper backgroundColor={Colors.NEW_YETIC_GREY}>
        <Icon width="100%" height="100%" color={Colors.NEW_YETIC_BLUE_LIGHT} />
      </IconWrapper>
      <H3 style={{ textAlign: 'center' }}>{title}</H3>
      <P style={{ textAlign: 'center', marginTop: Margin.m5 }}>{description}</P>
    </StyledWrapper>
  )
}

export default CreationFlowChoiceTile

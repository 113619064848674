import styled from '@emotion/styled'

import Padding from '../../../../../constants/padding'
import Margin from '../../../../../constants/margin'

export const StyledWrapper = styled.div`
  padding: ${Padding.p3} ${Padding.p10} ${Padding.p10};
  margin-top: ${Margin.m8};
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
`

import React from 'react'

import { ButtonsBarRightPart, ButtonsBarWrapper } from './styles'
import Button from '../../../../../components/Button'

export type SelfEditorAIFlowHeadlinesGenContentProps = {
  isSectionValid: boolean
  handleNext: () => void
  handlePrevious: () => void
  handleClickOpenModal: () => void
}

const SelfEditorAIFlowHeadlinesGenButtonsBar = ({
  isSectionValid,
  handleNext,
  handlePrevious,
  handleClickOpenModal
}: SelfEditorAIFlowHeadlinesGenContentProps) => {
  return (
    <ButtonsBarWrapper>
      <Button
        newFont
        type="secondary"
        style={{ border: 0 }}
        onClick={handleClickOpenModal}
      >
        Voir données utilisées
      </Button>
      <ButtonsBarRightPart>
        <Button newFont type="secondary" onClick={handlePrevious}>
          Retour
        </Button>
        <Button newFont onClick={handleNext} disabled={!isSectionValid}>
          Continuer
        </Button>
      </ButtonsBarRightPart>
    </ButtonsBarWrapper>
  )
}

export default SelfEditorAIFlowHeadlinesGenButtonsBar

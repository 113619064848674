import React from 'react'

import H1 from '../../../../components/Text/Headlines/H1'
import P from '../../../../components/Text/Paragraph'
import {
  StarIconWrapper,
  StyledCenteredDiv,
  StyledContent,
  StyledWrapper
} from './styles'
import StarsIcon from '../../../../components/Icon/stars'
import Colors from '../../../../constants/colors'
import Button from '../../../../components/Button'
import Margin from '../../../../constants/margin'
import Stepper from '../../../../components/Stepper'
import { aiFlowSteps } from '../constants'

type SelfEditorAIFlowIntroductionGenContentProps = {
  handleClick: () => void
}

const SelfEditorAIFlowIntroductionGenContent = ({
  handleClick
}: SelfEditorAIFlowIntroductionGenContentProps) => (
  <StyledWrapper>
    <Stepper steps={aiFlowSteps} currentIndex={1} marginBottom={Margin.m8} />
    <StyledContent>
      <StarIconWrapper>
        <StarsIcon width="100%" height="100%" color={Colors.YETIC_BLUE_DARK} />
      </StarIconWrapper>
      <H1>{`Félicitations !`}</H1>
      <H1
        style={{ marginTop: Margin.m5 }}
      >{`Maintenant il ne reste plus qu'à laisser l'IA générer les textes de votre annonce`}</H1>
      <P style={{ marginTop: 16, marginBottom: 32 }}>
        {`L'IA du YAM générer 3 types de contenus : les titres, les mots-clés
        et les descriptions.`}<br />{`Commençons par générer les titres.`}
      </P>
      <StyledCenteredDiv>
        <Button
          newFont
          type="primary"
          onClick={handleClick}
          large
          style={{ width: 'fit-content' }}
        >
          Commencer
        </Button>
      </StyledCenteredDiv>
    </StyledContent>
  </StyledWrapper>
)

export default SelfEditorAIFlowIntroductionGenContent

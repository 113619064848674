import React, { useState } from 'react'

import SelfEditorAIFlowCampaignParamsModalContent from './Content'

type SelfEditorAIFlowCampaignParamsModalProps = {
  onClose: () => void
  summary: Record<string, string>
}

const SelfEditorAIFlowCampaignParamsModal = ({
  onClose,
  summary
}: SelfEditorAIFlowCampaignParamsModalProps) => {
  const [isEditing, setIsEditing] = useState(false)

  return !isEditing ? (
    <SelfEditorAIFlowCampaignParamsModalContent
      onClose={onClose}
      summary={summary}
    />
  ) : (
    <div>Edition en cours...</div>
  )
}

export default SelfEditorAIFlowCampaignParamsModal

export const isValidDate = (year: number, month: number, day: number) => {
  const d = new Date(year, month - 1, day)

  if (
    d.getFullYear() === year &&
    d.getMonth() + 1 === month &&
    d.getDate() === day
  ) {
    return true
  }
  return false
}

import React, { useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SelfEditorMediaPlanCreationDefaultFlow from './selfEditorDefaultFlow'
import {
  SelfEditorMediaPlanCreationFlowChoiceCardsWrapper,
  SelfEditorMediaPlanCreationFlowChoicePageWrapper
} from './styles'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import { AppContext } from '../../contexts/AppContext'
import Margin from '../../constants/margin'
import DefaultText from '../../components/DefaultText'
import CreationFlowChoiceCard from './SelfEditorAIFlow/components/CreationFlowChoiceCard'
import EditIcon from '../../components/Icon/edit'
import Colors from '../../constants/colors'
import StarsIcon from '../../components/Icon/stars'
import H1 from '../../components/Text/Headlines/H1'
import Subheadline from '../../components/Text/Subheadline'
import P from '../../components/Text/Paragraph'

enum MediaCreationFlow {
  'DEFAULT' = 'DEFAULT',
  'FLOW_SELECTION' = 'FLOW_SELECTION'
}

const SelfEditorMediaPlanCreation = () => {
  const [flow, setFlow] = useState<MediaCreationFlow | null>(
    MediaCreationFlow.FLOW_SELECTION
  )

console.log('flow : ', flow)
  const navigate = useNavigate()
  const pageRef = useRef<HTMLDivElement>(null)
  const { user } = useContext(AppContext)

  const handleSelectDefaultFlow = () => {
    setFlow(MediaCreationFlow.DEFAULT)
  }

  const handleSelectAIFlow = () => {
    navigate(`${window.location.pathname}/ai`)
  }

  const renderPage = () => {
    if (flow === MediaCreationFlow.DEFAULT) {
      return <SelfEditorMediaPlanCreationDefaultFlow />
    } else {
      return (
        <SelfEditorMediaPlanCreationFlowChoicePageWrapper>
          <H1>{`Quel flow de création voulez-vous choisir ?`}</H1>
          {/* <Subheadline style={{ marginTop: 4 }}>{`Là c'est un sous-titre t'as capté`}</Subheadline> */}
          <P
            style={{ marginTop: 16, marginBottom: 56 }}
          >{`Vous avez le choix entre utiliser l'IA pour vous guider dans la création de vos contenus (titres, mots-clés, descriptions, etc.) ou opter pour une approche manuelle. Dans ce dernier cas, vous rédigerez vous-même les contenus de votre campagne, mais aurez la liberté de personnaliser votre campagne et également la possibilité de créer des publicités images.`}</P>
          <SelfEditorMediaPlanCreationFlowChoiceCardsWrapper>
            <CreationFlowChoiceCard
              icon={StarsIcon}
              title="Création assistée par IA"
              description="Uniquement pour Google Ads Text"
              onClick={handleSelectAIFlow}
            />
            <CreationFlowChoiceCard
              icon={EditIcon}
              title="Création manuelle"
              description="Contenu à rédiger vous-même"
              onClick={handleSelectDefaultFlow}
            />
          </SelfEditorMediaPlanCreationFlowChoiceCardsWrapper>
        </SelfEditorMediaPlanCreationFlowChoicePageWrapper>
      )
    }
  }

  return (
    <AuthenticatedTemplate
      navigate={navigate}
      user={user}
      // TODO: update when isSelfEditor prop is added to AuthenticatedTemplate
      isEditor={false}
      pageRef={pageRef}
    >
      {renderPage()}
    </AuthenticatedTemplate>
  )
}

export default SelfEditorMediaPlanCreation

import React from 'react'

import Button from '../../Button'
import { ArrowBack } from '../../Icon'
import { SurveyNavigationWrapper } from '../styles'

type SurveyNavigationProps = {
  handleOnPreviousClick: () => void
}

const SurveyNavigation = ({ handleOnPreviousClick }: SurveyNavigationProps) => {
  return (
    <SurveyNavigationWrapper>
      <Button
        type="textButton"
        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
        onClick={handleOnPreviousClick}
      >
        <ArrowBack width="14px" />
        Revenir à la question précédente
      </Button>
    </SurveyNavigationWrapper>
  )
}

export default SurveyNavigation

import React from 'react'

import { IconType } from '../../types/icon'
import Colors from '../../constants/colors'

const Trash = ({
  height = '25px',
  width = '25px',
  style,
  color = Colors.YETIC_BLUE_DARK,
  weight = '1.5'
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={weight}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4 6H20L18.4199 20.2209C18.3074 21.2337 17.4512 22 16.4321 22H7.56786C6.54876 22 5.69264 21.2337 5.5801 20.2209L4 6Z" />
      <path d="M7.34491 3.14716C7.67506 2.44685 8.37973 2 9.15396 2H14.846C15.6203 2 16.3249 2.44685 16.6551 3.14716L18 6H6L7.34491 3.14716Z" />
      <path d="M2 6H22" />
      <path d="M10 11V16" />
      <path d="M14 11V16" />
    </svg>
  )
}

export default Trash

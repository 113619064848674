import React, { useState } from 'react'

import {
  KeywordsEmptyContainer,
  SelfEditorAIFlowKeywordsGenContentBody,
  SelfEditorAIFlowKeywordsGenContentGenerateButton,
  SelfEditorAIFlowKeywordsGenContentWrapper,
  SelfEditorAIFlowKeywordsGenCurrentContentColumn,
  invalidSectionTextStyle,
  titleStyle
} from './styles'
import Card from '../components/Card'
import CardHeader from '../components/Card/Header'
import Button from '../../../../components/Button'
import CardContent from '../components/Card/CardContent'
import HeaderItemsCount from '../components/Card/Header/HeaderItemsCount'
import AnimatedLoader from '../../../../components/AnimatedLoader'
import SelectedKeywords from '../components/Keywords/SelectedKeywords'
import KeywordsToSelect from '../components/Keywords/KeywordsToSelect'
import { maxNumberOfKeywords, minNumberOfKeywords } from '../mock/keywords'
import Colors from '../../../../constants/colors'
import StarsIcon from '../../../../components/Icon/stars'
import SelfEditorAIFlowKeywordsGenModal from './SelfEditorAIFlowKeywordsGenModal'
import SelfEditorAIFlowKeywordsGenButtonsBar from './SelfEditorAIFlowKeywordsGenButtonsBar'
import P from '../../../../components/Text/Paragraph'
import Stepper from '../../../../components/Stepper'
import { aiFlowSteps } from '../constants'
import Margin from '../../../../constants/margin'
import H2 from '../../../../components/Text/Headlines/H2'

export type SelfEditorAIFlowKeywordsGenContentProps = {
  isSectionValid: boolean
  isRecapModalOpen: boolean
  summary: Record<string, string>
  updateSummary: (summary: Record<string, string>) => void
  headlines: string[]
  keywords: string[]
  updateKeywords: (keywords: string[]) => void
  generatedPendingKeywords: string[]
  updateGeneratedPendingKeywords: (generatedPendingKeywords: string[]) => void
  handleGenerateKeywords: () => void
  isGenerationPending: boolean
  handleNext: () => void
  handlePrevious: () => void
  handleOpenModal: () => void
  handleCloseModal: () => void
}

const SelfEditorAIFlowKeywordsGenContent = ({
  isSectionValid,
  isRecapModalOpen,
  summary,
  updateSummary,
  headlines,
  keywords,
  updateKeywords,
  generatedPendingKeywords,
  updateGeneratedPendingKeywords,
  handleGenerateKeywords,
  isGenerationPending,
  handleNext,
  handlePrevious,
  handleOpenModal,
  handleCloseModal
}: SelfEditorAIFlowKeywordsGenContentProps) => {
  return (
    <SelfEditorAIFlowKeywordsGenContentWrapper>
      <Stepper steps={aiFlowSteps} currentIndex={3} marginBottom={Margin.m6} />
      <H2 style={titleStyle}>
        {`Génération des mots-clés de mon annonce`}
      </H2>
      {isRecapModalOpen && (
        <SelfEditorAIFlowKeywordsGenModal
          summary={summary}
          headlines={headlines}
          onClose={handleCloseModal}
          updateSummary={updateSummary}
        />
      )}
      <SelfEditorAIFlowKeywordsGenContentBody>
        <SelfEditorAIFlowKeywordsGenCurrentContentColumn>
          <Card style={{ borderRadius: 0, boxShadow: 'none' }}>
            <CardHeader
              title="Générer des mots-clés (3 minimum)"
              action={
                <Button newFont onClick={handleGenerateKeywords} width="130px">
                  {isGenerationPending ? (
                    <AnimatedLoader color="white" />
                  ) : (
                    <SelfEditorAIFlowKeywordsGenContentGenerateButton>
                      <StarsIcon color="white" width="16px" />
                      {generatedPendingKeywords.length > 0
                        ? 'Regénérer'
                        : 'Générer'}
                    </SelfEditorAIFlowKeywordsGenContentGenerateButton>
                  )}
                </Button>
              }
            />
            <CardContent height="300px">
              {generatedPendingKeywords.length > 0 ? (
                <KeywordsToSelect
                  pendingKeywords={generatedPendingKeywords}
                  onPendingKeywordsChange={updateGeneratedPendingKeywords}
                  selectedKeywords={keywords}
                  onSelectedKeywordsChange={updateKeywords}
                  selectedKeywordsMax={maxNumberOfKeywords}
                />
              ) : (
                <KeywordsEmptyContainer>
                  <P>{`Cliquez sur "Générer" pour obtenir des mots-clés pour votre annonce`}</P>
                </KeywordsEmptyContainer>
              )}
            </CardContent>
          </Card>
        </SelfEditorAIFlowKeywordsGenCurrentContentColumn>
        <SelfEditorAIFlowKeywordsGenCurrentContentColumn>
          <Card
            style={{
              borderRadius: 0,
              boxShadow: 'none',
              borderLeft: `1px solid ${Colors.YETIC_GREY_DARK}`
            }}
          >
            <CardHeader
              title="Mots-clés sélectionnés"
              action={
                <HeaderItemsCount
                  count={keywords.length}
                  max={maxNumberOfKeywords}
                  min={minNumberOfKeywords}
                />
              }
            />
            <CardContent height="300px">
              {keywords.length > 0 ? (
                <SelectedKeywords
                  keywords={keywords}
                  onKeywordsChange={updateKeywords}
                />
              ) : (
                <KeywordsEmptyContainer>
                  <P>{`Aucun mot-clé sélectionné`}</P>
                  <P>{`Veuillez ajouter des mots-clés depuis la liste de mots-clés générés`}</P>
                </KeywordsEmptyContainer>
              )}
            </CardContent>
          </Card>
        </SelfEditorAIFlowKeywordsGenCurrentContentColumn>
      </SelfEditorAIFlowKeywordsGenContentBody>
      <SelfEditorAIFlowKeywordsGenButtonsBar
        isSectionValid={isSectionValid}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        handleClickOpenModal={handleOpenModal}
      />
      {!isSectionValid && (
        <P small style={invalidSectionTextStyle}>
          {`Vous devez sélectionner au moins ${minNumberOfKeywords} mots-clés pour continuer.`}
        </P>
      )}
    </SelfEditorAIFlowKeywordsGenContentWrapper>
  )
}

export default SelfEditorAIFlowKeywordsGenContent

import React from 'react'
import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'

export const leftMenuWidth = 180
export const leftMenuPadding = 15
export const leftMenuWidthWithPadding = leftMenuWidth + leftMenuPadding * 2

export const MenuLeft = styled.nav`
  padding: ${leftMenuPadding}px;
  width: ${leftMenuWidth}px;
  flex-basis: ${leftMenuWidth}px;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  z-index: 1000;
`

export const MenuLeftContent = styled.div<{ newUi?: boolean }>`
  position: fixed;
  width: ${leftMenuWidthWithPadding}px;
  padding: 0 ${leftMenuPadding}px;
  box-sizing: border-box;

  ${(props) => {
    return props.newUi
      ? `background: ${Colors.YETIC_WHITE};
        box-shadow: 0px 0px 2px #142a4c40;`
      : `background-color: ${Colors.NEW_YETIC_GREY_BACKGROUND};`
  }}

  left: 0;
  top: 0;
  min-height: 100vh;

  @media screen and (min-width: 1600px) {
    transform: translateX(calc((100vw - 1600px) / 2));
  }
`

export const MenuLeftUl = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;
`

export const MenuLeftLi = styled.li<{
  expandable: boolean
  disabled: boolean
}>`
  height: 40px;
  color: #142a4c;
  font: 600 12px 'Montserrat';
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  position: relative;
  gap: 15px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : '')};

  &:hover {
    ${(props) =>
      !props.disabled
        ? `
        background: ${Colors.YETIC_GREY_DARK} 0% 0% no-repeat padding-box;
        border-radius: 10px;
    `
        : ''}
  }

  &.selected {
    background: ${Colors.YETIC_GREY_DARK} 0% 0% no-repeat padding-box;
    border-radius: 10px;
  }

  ${(props) =>
    props.expandable && !props.disabled
      ? `
      &:hover:after {
        content: '▸';
        margin-left: auto;
      }
    `
      : ''};
`

export const SymbolIcon = styled.span`
  margin-right: 15px;
`

export const companyNameStyle: React.CSSProperties = {
  margin: 0,
  height: '74px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
}

export const addButtonStyle: React.CSSProperties = {
  background: '#ffce00',
  borderRadius: '10px'
}

export const yamLogoStyle: React.CSSProperties = {
  width: '100px',
  cursor: 'pointer',
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  bottom: '20px'
}

import React, { ChangeEvent } from 'react'

import Button from '../../../../components/Button'
import { StyledCenteredDiv, StyledWrapper } from './styles'
import Margin from '../../../../constants/margin'
import Stepper from '../../../../components/Stepper'
import { aiFlowSteps } from '../constants'
import SurveyInput from '../../../../components/SurveyInput'
import P from '../../../../components/Text/Paragraph'

export type SelfEditorAIFlowOpenedQuestionsContentProps = {
  text: string
  value: string
  handleInputChange: (value: ChangeEvent<HTMLInputElement>) => void
  handleNextAnswer: () => void
}

const SelfEditorAIFlowOpenedQuestionsContent = ({
  text,
  value,
  handleInputChange,
  handleNextAnswer
}: SelfEditorAIFlowOpenedQuestionsContentProps) => (
  <StyledWrapper>
    <Stepper steps={aiFlowSteps} currentIndex={0} marginBottom={Margin.m12} />
    <StyledCenteredDiv>
      <P style={{ marginBottom: Margin.m8 }}>
        {text}
      </P>
      <SurveyInput
        placeHolder={`Entrez votre réponse ici`}
        width="45%"
        onChange={handleInputChange}
        value={value}
      />
      <Button newFont type="primary" onClick={handleNextAnswer}>
        Valider
      </Button>
    </StyledCenteredDiv>
  </StyledWrapper>
)

export default SelfEditorAIFlowOpenedQuestionsContent

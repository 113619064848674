import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useRef,
  useState
} from 'react'

import { checkValue } from './helpers/checkValue'
import { isMonthValid } from './helpers/isMonthValid'
import { isDayValid } from './helpers/isDayValid'
import DatePickerContent from './Content'
import { isValidDate } from './helpers/isValidDate'
import { roundDateToDay } from './helpers/roundDateToDay'

type DatePickerProps = {
  label: string
  onDateChange: (dateTime: number) => void
  minDate?: Date
  maxDate?: Date
}

const DatePicker = ({
  label,
  onDateChange,
  minDate,
  maxDate
}: DatePickerProps) => {
  const [pickedDate, setPickedDate] = useState<{
    day: string
    month: string
    year: string
  }>({ day: '', month: '', year: '' })

  useEffect(() => {
    if (
      pickedDate.day.length === 2 &&
      pickedDate.month.length === 2 &&
      pickedDate.year.length === 4
    ) {
      const date = new Date(
        `${pickedDate.year}-${pickedDate.month}-${pickedDate.day}`
      )

      if (
        date.getTime() &&
        !isNaN(date.getTime()) &&
        isValidDate(
          Number(pickedDate.year),
          Number(pickedDate.month),
          Number(pickedDate.day)
        )
      ) {
        const formatedMinDate = minDate ? roundDateToDay(minDate) : null

        const formatedMaxDate = maxDate ? roundDateToDay(maxDate) : null

        if (
          (!formatedMinDate || date >= formatedMinDate) &&
          (!formatedMaxDate || date <= formatedMaxDate)
        ) {
          onDateChange(date.getTime())
        }
      }
    }
  }, [pickedDate])

  const dayRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)
  const calendarInputRef = useRef<HTMLInputElement>(null)

  const handleDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value

    if (checkValue(val)) {
      if (isDayValid(val)) {
        if (val.length === 2 && monthRef.current) {
          monthRef.current.select()
        } else if (val.length === 3 && monthRef.current) {
          monthRef.current.select()
        }
      }

      setPickedDate({ ...pickedDate, day: val })
    } else if (val.length === 3) {
      const lastDigit = val.slice(2, 3)
      if (
        checkValue(lastDigit) &&
        monthRef.current &&
        isDayValid(val.slice(0, 2))
      ) {
        monthRef.current.focus()
        setPickedDate({ ...pickedDate, month: lastDigit })
      }
    }
  }

  const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    if (checkValue(val)) {
      if (isMonthValid(val)) {
        if (val.length === 2 && yearRef.current) {
          yearRef.current.select()
        } else if (val.length === 0 && dayRef.current) {
          dayRef.current.focus()
        }
      }

      setPickedDate({ ...pickedDate, month: val })
    } else if (val.length === 3) {
      const lastDigit = val.slice(2, 3)

      if (
        checkValue(lastDigit) &&
        yearRef.current &&
        isMonthValid(val.slice(0, 2))
      ) {
        yearRef.current.focus()
        setPickedDate({ ...pickedDate, year: lastDigit })
      }
    }
  }

  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value

    if (checkValue(val, true)) {
      if (val.length === 0 && monthRef.current) {
        monthRef.current.focus()
      }

      setPickedDate({ ...pickedDate, year: val })
    }
  }

  const handleCalendarClick = () => {
    calendarInputRef.current && calendarInputRef.current.showPicker()
  }

  const handleDateInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const [year, month, day] = e.target.value.split('-')
    setPickedDate({ day, month, year })
  }

  return (
    <DatePickerContent
      label={label}
      date={pickedDate}
      dayRef={dayRef}
      monthRef={monthRef}
      yearRef={yearRef}
      calendarInputRef={calendarInputRef}
      minDate={minDate}
      maxDate={maxDate}
      handleCalendarClick={handleCalendarClick}
      handleDateInputChange={handleDateInputChange}
      handleDayChange={handleDayChange}
      handleMonthChange={handleMonthChange}
      handleYearChange={handleYearChange}
    />
  )
}

export default DatePicker

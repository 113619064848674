import React from 'react'

import { TitlesToSelectContainer } from './styles'
import TagList from '../../../../../../components/Tag/new/TagList'
import { TagProps } from '../../../../../../components/Tag/new'
import { TagActionEnum } from '../../../../../../components/Tag/new/types'

type TitlesToSelectProps = {
  pendingTitles: string[]
  selectedTitles: string[]
  onPendingTitlesChange: (titles: string[]) => void
  onSelectedTitlesChange: (titles: string[]) => void
  selectedTitlesMax: number
}

const TitlesToSelect = ({
  pendingTitles,
  selectedTitles,
  onPendingTitlesChange,
  onSelectedTitlesChange,
  selectedTitlesMax
}: TitlesToSelectProps) => {
  const onTitleAddToSelection = (title: string, index: number) => {
    if (selectedTitles.length >= selectedTitlesMax) return
    onTitleDelete(index)
    onSelectedTitlesChange([...selectedTitles, title])
  }

  const onTitleDelete = (index: number) => {
    const updatedTitles = [...pendingTitles]
    updatedTitles.splice(index, 1)
    onPendingTitlesChange(updatedTitles)
  }

  const tagActions: TagProps['actions'] = [
    {
      onClick: (index: number) => {
        onTitleAddToSelection(pendingTitles[index], index)
      },
      type: TagActionEnum.ADD,
      disabled: selectedTitles.length >= selectedTitlesMax
    }
  ]

  return (
    <TitlesToSelectContainer>
      <TagList tags={pendingTitles} actions={tagActions} />
    </TitlesToSelectContainer>
  )
}

export default TitlesToSelect

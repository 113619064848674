import styled from '@emotion/styled'

export const StyledAIFlowSummaryTitle = styled.td`
  font-weight: bold;
  text-align: left;
`

export const StyledAIFlowSummaryResult = styled.td`
  text-align: left;
`

export const StyledAIFlowSummaryResultContainer = styled.div`
  margin-left: 45px;
`
export const SelfEditorAIFlowSummaryContainer = styled.tbody`
  line-height: 1.5;
`

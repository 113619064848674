import React, { useEffect, useState } from 'react'

import SelfEditorAIFlowKeywordsGenContent from './Content'
import {
  mockedKeywords,
  maxNumberOfKeywords,
  minNumberOfKeywords
} from '../mock/keywords'

export type SelfEditorAIFlowKeywordsGenContainerProps = {
  summary: Record<string, string>
  updateSummary: (summary: Record<string, string>) => void
  headlines: string[]
  keywords: string[]
  updateKeywords: (keywords: string[]) => void
  handleNext: () => void
  handlePrevious: () => void
}

const SelfEditorAIFlowKeywordsGenContainer = ({
  summary,
  updateSummary,
  headlines,
  keywords,
  updateKeywords,
  handleNext,
  handlePrevious
}: SelfEditorAIFlowKeywordsGenContainerProps) => {
  const [generatedPendingKeywords, setGeneratedPendingKeywords] = useState<
    string[]
  >([])
  const [isGenerationPending, setIsGenerationPending] = useState(false)
  const isSectionValid =
    keywords.length >= minNumberOfKeywords &&
    keywords.length <= maxNumberOfKeywords
  const [isRecapModalOpen, setIsRecapModalOpen] = useState(false)

  useEffect(() => {
    if (isGenerationPending) {
      setTimeout(() => {
        setGeneratedPendingKeywords(mockedKeywords)
        setIsGenerationPending(false)
      }, 1000)
    }
  }, [isGenerationPending])

  const handleGenerateKeywords = () => {
    setIsGenerationPending(true)
  }

  const handleOpenModal = () => {
    setIsRecapModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsRecapModalOpen(false)
  }

  return (
    <SelfEditorAIFlowKeywordsGenContent
      isSectionValid={isSectionValid}
      isRecapModalOpen={isRecapModalOpen}
      summary={summary}
      updateSummary={updateSummary}
      headlines={headlines}
      keywords={keywords}
      updateKeywords={updateKeywords}
      generatedPendingKeywords={generatedPendingKeywords}
      updateGeneratedPendingKeywords={setGeneratedPendingKeywords}
      handleGenerateKeywords={handleGenerateKeywords}
      isGenerationPending={isGenerationPending}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
    />
  )
}

export { SelfEditorAIFlowKeywordsGenContainer }

import React, { useState } from 'react'

import SelfEditorAIFlowCampaignParamsContent from './Content'
import { SelectionOption } from './SelectionInputWithOther'

export type SelfEditorAIFlowCampaignParamsContainerProps = {
  startDate: number | null
  endDate: number | null
  dailyBudget: number | null
  handleChangeStartDate: (date: number | null) => void
  handleChangeEndDate: (endDate: number | null) => void
  handleChangeDailyBudget: (dailyBudget: number | null) => void
  handlePrevious: () => void
  handleNext: () => void
  summary: Record<string, string>
}

const SelfEditorAIFlowCampaignParamsContainer = ({
  startDate,
  endDate,
  dailyBudget,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeDailyBudget,
  handlePrevious,
  handleNext,
  summary
}: SelfEditorAIFlowCampaignParamsContainerProps) => {
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false)

  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 3)

  const durationInDays = endDate
    ? Math.round((endDate - (startDate ?? 0)) / (1000 * 60 * 60 * 24))
    : null

  const isSectionValid = !!(
    startDate &&
    endDate &&
    dailyBudget &&
    dailyBudget > 0 &&
    durationInDays &&
    durationInDays >= 14
  )

  const errorMessage =
    !startDate && !endDate && !dailyBudget
      ? 'Veuillez renseigner une date de début, une durée et un budget pour continuer.'
      : !startDate && !endDate && dailyBudget
      ? 'Veuillez renseigner une date de début et une durée pour continuer.'
      : !startDate && endDate && !dailyBudget
      ? 'Veuillez renseigner une date de début et un budget pour continuer.'
      : startDate && !endDate && !dailyBudget
      ? 'Veuillez renseigner une durée et un budget pour continuer.'
      : startDate && endDate && !dailyBudget
      ? 'Veuillez renseigner un budget pour continuer.'
      : startDate && !endDate && dailyBudget
      ? 'Veuillez renseigner une durée pour continuer.'
      : !startDate && endDate && dailyBudget
      ? 'Veuillez renseigner une date de début pour continuer.'
      : durationInDays && durationInDays < 14
      ? "La durée de votre campagne doit être d'au moins 14 jours."
      : ''

  const durationOptions: SelectionOption[] = [
    {
      title: 'Minimum',
      description: 'Lancez votre campagne avec un essai de deux semaines',
      value: 14,
      displayedValue: '2 semaines'
    },
    {
      title: 'Court',
      description: 'Évaluez les performances initiales de votre campagne',
      value: 31,
      displayedValue: '1 mois'
    },
    {
      title: 'Moyen',
      description: 'Étendez la portée de votre campagne',
      value: 92,
      displayedValue: '3 mois'
    },
    {
      title: 'Long',
      description: 'Établissez une présence renforcée',
      value: 183,
      displayedValue: '6 mois'
    }
  ]

  const dailyBudgetOptions: SelectionOption[] = [
    {
      title: 'Découverte',
      description:
        'Idéal pour tester la publicité sur Google Ads avec un budget limité',
      displayedValue: '10€',
      displayedValueComplement: '/jour',
      value: 10
    },
    {
      title: 'Visibilité',
      description:
        'Obtenez une visibilité plus importante sur les résultats de recherche Google',
      displayedValue: '50€',
      displayedValueComplement: '/jour',
      value: 50
    },
    {
      title: 'Performance',
      description:
        'Maximisez votre visibilité et votre retour sur investissement',
      displayedValue: '100€',
      displayedValueComplement: '/jour',
      value: 100
    },
    {
      title: 'Performance ++',
      description: 'Dominez votre marché et obtenez une visibilité maximale',
      displayedValue: '300€',
      displayedValueComplement: '/jour',
      value: 300
    }
  ]

  const handleChangeDurationInDays = (
    duration: number | null,
    startDate: number | null
  ) => {
    if (duration) {
      const newEndDate = new Date(startDate ?? 0)
      newEndDate.setDate(newEndDate.getDate() + duration)
      handleChangeEndDate(newEndDate.getTime())
      return
    }

    handleChangeEndDate(null)
  }

  const updatedHandleChangeStartDate = (date: number | null) => {
    if (durationInDays) {
      handleChangeDurationInDays(durationInDays, date)
    }
    handleChangeStartDate(date)
  }

  const handleOpenOrCloseModal = () => {
    setIsSummaryModalOpen((prev) => !prev)
  }

  return (
    <SelfEditorAIFlowCampaignParamsContent
      startDate={startDate}
      minStartDate={minDate}
      dailyBudget={dailyBudget}
      handleChangeStartDate={updatedHandleChangeStartDate}
      handleChangeDailyBudget={handleChangeDailyBudget}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      isSummaryModalOpen={isSummaryModalOpen}
      handleToggleOpenModal={handleOpenOrCloseModal}
      durationInDays={durationInDays}
      endDate={endDate}
      handleChangeDuration={handleChangeDurationInDays}
      durationOptions={durationOptions}
      dailyBudgetOptions={dailyBudgetOptions}
      summary={summary}
      isSectionValid={isSectionValid}
      errorMessage={errorMessage}
    />
  )
}

export { SelfEditorAIFlowCampaignParamsContainer }

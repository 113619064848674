export const isMonthValid = (month: string) => {
  const numMonth = Number(month)

  if (month.length < 2) {
    return true
  }

  if (!isNaN(numMonth)) {
    if (numMonth > 0 && numMonth < 13) {
      return true
    }
  }

  return false
}

import styled from '@emotion/styled'

import Margin from '../../../../constants/margin'
import FontSize from '../../../../constants/fontSize'
import Colors from '../../../../constants/colors'
import Padding from '../../../../constants/padding'

export const StyledWrapper = styled.div`
  display: flex;
  align-items: end;
`

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const StyledTop = styled.div`
  display: flex;
`

export const StyledMiddle = styled.div`
  display: flex;
`

export const StyledInputContainer = styled.div`
  display: flex;
  width: 84px;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: ${Padding.p5};
`

export const StyledInput = styled.input<{ isError: boolean }>`
  border: none;
  font-size: ${FontSize.NEW_YETIC_H3};
  width: 40px;
  border-bottom: solid black 2px;
  border-color: ${(props) =>
    props.isError ? Colors.YETIC_RED : Colors.YETIC_GREY_DARK};
  padding-bottom: ${Padding.p3};
  transition: 0.2s;
  height: fit-content;

  &:focus {
    border-color: ${(props) =>
      props.isError ? Colors.YETIC_RED : Colors.YETIC_BLUE_DARK};
  }
`
export const StyledCalendarContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: end;
  box-sizing: border-box;
  margin-left: ${Margin.m5};
  cursor: pointer;
  position: relative;
`

export const StyledDateInput = styled.input`
  width: 0;
  height: 0;
  border: none;
  transform: translateY(10px);
`

import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Padding from '../../../../constants/padding'
import Margin from '../../../../constants/margin'
import Colors from '../../../../constants/colors'
import { YETIC_NEW_FONT_FAMILY } from '../../../../constants/fontFamily'

export const StyledTag = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${Padding.p4};
  margin: 0 4px 4px 0;
  border: 1px solid ${Colors.YETIC_BLUE_DARK};
  border-radius: 10px;
  width: fit-content;
  font-family: ${YETIC_NEW_FONT_FAMILY};
  font-size: 14px;
`

export const textTagStyle: CSSProperties = {
  padding: `6px ${Padding.p4} 6px 0`
}

export const TagActions = styled.div`
  display: flex;
`

export const TagActionButton = styled.button<{
  backgroundColor: string
  hoverBackgroundColor: string
  disabled?: boolean
}>`
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 26px;
  height: 26px;
  padding: 0;
  margin-right: ${Margin.m2};
  border-radius: 50%;
  background-color: ${({ backgroundColor, disabled }) =>
    disabled ? 'transparent' : backgroundColor};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ hoverBackgroundColor, disabled }) =>
      disabled ? 'transparent' : hoverBackgroundColor};
    transition: background-color 0.3s;
  }
`

import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import PopupMenu, { Section } from './PopupMenu'
import {
  MenuLeft,
  MenuLeftContent,
  MenuLeftLi,
  MenuLeftUl,
  addButtonStyle,
  companyNameStyle,
  yamLogoStyle
} from './styles'
import {
  checkIfCustomerNames,
  getCustomers
} from '../../../helpers/queries/customer/getCustomers'
import { getMediaPlansByCompany } from '../../../helpers/queries/mediaPlan/getMediaPlansByCompany'
import { ViewEnum } from '../../../types/axios/common'
import { checkIfMediaPlanNameOnlyArray } from '../../../types/mediaPlan/mediaPlan'
import { getCampaignsByMediaPlan } from '../../../helpers/queries/campaign/getCampaignsByMediaPlan'
import { checkIfCampaignNameOnlyArray } from '../../../types/campaign/campaign'
import { getAdsByCampaign } from '../../../helpers/queries/ad/getAdsByCampaign'
import { checkIfAdNameOnlyArray } from '../../../types/ad/ad'
import AddPicto from '../../../components/Icon/addPicto'
import AnalyticsPicto from '../../../components/Icon/analyticsPicto'
import CustomersPicto from '../../../components/Icon/customersPicto'
import HomePicto from '../../../components/Icon/homePicto'
import { AppContext } from '../../../contexts/AppContext'
import DefaultText from '../../../components/DefaultText'
import YamLogo from '../../../components/Icon/yamLogo'
import { MediaPlanPicto } from '../../../components/Icon/mediaPlanPicto'
import CampaignCreationPlatformModal from '../CampaignCreationPlatformModal'
import { NamedResource } from '../../../types/common'
import { getAddButtonSections } from './helpers/addButtonSections'
import { getMediaPlanSections } from './helpers/mediaPlanSections'
import { getCustomerSections } from './helpers/customerSections/editor'
import { Status, getAddSubitemsStatuses } from '../../../types/status'
import Colors from '../../../constants/colors'

export enum LeftMenuElementToCreateEnum {
  MEDIA_PLAN = 'mediaPlan',
  CAMPAIGN = 'campaign',
  AD = 'ad'
}

export enum MenuEnum {
  NEW = 'new',
  CUSTOMERS = 'customers'
}

export type SectionId = 'new' | 'customers' | 'mediaPlan' | 'campaign' | 'ad'

export type SelectedItemsId = {
  new: string
  customers: string
  mediaPlan: string
  campaign: string
  ad: string
}

const LeftMenu = ({ newUi }: { newUi?: boolean }) => {
  const [isAddMenuOpened, setIsAddMenuOpened] = useState(false)
  const [isCustomersMenuOpened, setIsCustomersMenuOpened] = useState(false)
  const [isMediaPlansMenuOpened, setIsMediaPlansMenuOpened] = useState(false)

  const [customers, setCustomers] = useState<NamedResource[] | null>(null)
  const [mediaPlans, setMediaPlans] = useState<NamedResource[] | null>(null)
  const [campaigns, setCampaigns] = useState<NamedResource[] | null>(null)
  const [ads, setAds] = useState<NamedResource[] | null>(null)

  const [elementToCreate, setElementToCreate] =
    useState<LeftMenuElementToCreateEnum | null>(null)

  const [selectedItemsId, setSelectedItemsId] = useState<SelectedItemsId>({
    new: '',
    customers: '',
    mediaPlan: '',
    ad: '',
    campaign: ''
  })

  const [
    campaignCreationModalMediaPlanId,
    setCampaignCreationModalMediaPlanId
  ] = useState('')

  const navigate = useNavigate()
  const appContext = useContext(AppContext)
  const { companyId, companyName, isEditor, isSelfEditor } = appContext

  useEffect(() => {
    if (isCustomersMenuOpened) {
      ;(async function () {
        const result = await getCustomers({ view: ViewEnum.NAME })
        if (checkIfCustomerNames(result)) {
          setCustomers(result)
        }
      })()
    }
  }, [isCustomersMenuOpened])

  useEffect(() => {
    if (isMediaPlansMenuOpened) {
      ;(async function () {
        const result = await getMediaPlansByCompany({
          companyId: companyId ?? '',
          view: ViewEnum.NAME
        })
        if (checkIfMediaPlanNameOnlyArray(result)) {
          setMediaPlans(result)
        }
      })()
    }
  }, [isMediaPlansMenuOpened])

  const cleanData = () => {
    setCustomers(null)
    setMediaPlans(null)
    setCampaigns(null)
    setAds(null)
    setSelectedItemsId({
      new: '',
      customers: '',
      mediaPlan: '',
      ad: '',
      campaign: ''
    })
  }

  const closeMenu = () => {
    setIsAddMenuOpened(false)
    setIsCustomersMenuOpened(false)
    setIsMediaPlansMenuOpened(false)
    cleanData()
  }

  const handleOpenAddMenu = () => {
    setIsAddMenuOpened(true)
    setIsCustomersMenuOpened(false)
    setIsMediaPlansMenuOpened(false)
    cleanData()
  }

  const handleOpenCustomersMenu = () => {
    setIsAddMenuOpened(false)
    setIsCustomersMenuOpened(true)
    setIsMediaPlansMenuOpened(false)
    cleanData()
  }

  const handleOpenMediaPlansMenu = () => {
    setIsAddMenuOpened(false)
    setIsCustomersMenuOpened(false)
    setIsMediaPlansMenuOpened(true)
    cleanData()
  }

  const fetchCustomers = async (id: string) => {
    const currentCustomers = await getCustomers({
      view: ViewEnum.NAME
    })
    if (
      checkIfCustomerNames(currentCustomers) &&
      (Object.values(LeftMenuElementToCreateEnum) as string[]).includes(id)
    ) {
      setCustomers(currentCustomers)
      setElementToCreate(id as LeftMenuElementToCreateEnum)
      setMediaPlans(null)
      setCampaigns(null)
      setAds(null)
    }
  }

  const fetchMediaPlans = async (id: string, isAddSection?: boolean) => {
    if (isEditor) {
      const mediaPlans = await getMediaPlansByCompany({
        companyId: id,
        view: ViewEnum.NAME,
        status: isAddSection
          ? getAddSubitemsStatuses({ isSelfEditor: false })
          : undefined
      })
      if (checkIfMediaPlanNameOnlyArray(mediaPlans)) {
        setMediaPlans(mediaPlans)
        setCampaigns(null)
        setAds(null)
      }
    } else if (isSelfEditor) {
      const mediaPlans = await getMediaPlansByCompany({
        companyId: companyId ?? '',
        view: ViewEnum.NAME,
        status: isAddSection
          ? getAddSubitemsStatuses({ isSelfEditor })
          : undefined
      })
      if (checkIfMediaPlanNameOnlyArray(mediaPlans)) {
        setMediaPlans(mediaPlans)
        setElementToCreate(id as LeftMenuElementToCreateEnum)
        setCampaigns(null)
        setAds(null)
      }
    }
  }

  const fetchCampaigns = async (id: string) => {
    const campaigns = await getCampaignsByMediaPlan({
      mediaPlanId: id,
      view: ViewEnum.NAME
    })
    if (checkIfCampaignNameOnlyArray(campaigns)) {
      setCampaigns(campaigns)
      setAds(null)
    }
  }

  const fetchAds = async (id: string) => {
    const ads = await getAdsByCampaign({ campaignId: id, view: ViewEnum.NAME })
    if (checkIfAdNameOnlyArray(ads)) {
      setAds(ads)
    }
  }

  const sections: Section[] = []

  isAddMenuOpened &&
    getAddButtonSections({
      sections,
      isAddMenuOpened,
      isEditor: isEditor ?? false,
      isSelfEditor: isSelfEditor ?? false,
      companyId: companyId ?? '',
      customers,
      mediaPlans,
      campaigns,
      ads,
      elementToCreate,
      setCampaignCreationModalMediaPlanId,
      fetchCustomers,
      fetchMediaPlans: (id: string) => fetchMediaPlans(id, true),
      fetchCampaigns,
      fetchAds,
      navigate
    })

  isCustomersMenuOpened &&
    isEditor &&
    getCustomerSections({
      sections,
      customers,
      mediaPlans,
      campaigns,
      ads,
      fetchMediaPlans,
      fetchCampaigns,
      fetchAds,
      navigate
    })

  isMediaPlansMenuOpened &&
    getMediaPlanSections({
      sections,
      isEditor: isEditor ?? false,
      isSelfEditor: isSelfEditor ?? false,
      mediaPlans,
      campaigns,
      ads,
      fetchCampaigns,
      fetchAds,
      navigate
    })

  return (
    <>
      <MenuLeft>
        <MenuLeftContent newUi={newUi}>
          <DefaultText
            bold
            style={companyNameStyle}
            onClick={() => {
              navigate('/')
            }}
          >
            {companyName}
          </DefaultText>
          <MenuLeftUl>
            <MenuLeftLi
              expandable
              disabled={!isEditor && !isSelfEditor}
              style={addButtonStyle}
              onClick={(event) => {
                event.stopPropagation()
                if (isEditor || isSelfEditor) handleOpenAddMenu()
              }}
            >
              <AddPicto />
              {isEditor || isSelfEditor ? 'Nouveau' : 'Nouvelle agence'}
            </MenuLeftLi>
            <PopupMenu
              isSeeAllDisplayed={false}
              sections={sections}
              isOpen={isAddMenuOpened}
              updateMenuProps={handleOpenAddMenu}
              closeMenu={closeMenu}
              selectedItemsId={selectedItemsId}
              setSelectedItems={setSelectedItemsId}
              isCustomerMenuOpened={isCustomersMenuOpened}
              isAddMenuOpened={isAddMenuOpened}
              isMediaPlansMenuOpened={isMediaPlansMenuOpened}
              setCampaignCreationModalMediaPlanId={
                setCampaignCreationModalMediaPlanId
              }
              isEditor={isEditor ?? false}
              isSelfEditor={isSelfEditor ?? false}
              companyId={companyId ?? ''}
            />
            <MenuLeftLi
              disabled={false}
              expandable={false}
              onClick={() => {
                navigate('/')
              }}
            >
              <HomePicto />
              Accueil
            </MenuLeftLi>
            <MenuLeftLi
              expandable
              disabled={!isEditor && !isSelfEditor}
              onClick={(event) => {
                event.stopPropagation()
                if (isEditor) {
                  handleOpenCustomersMenu()
                } else {
                  handleOpenMediaPlansMenu()
                }
              }}
            >
              {isEditor ? (
                <>
                  <CustomersPicto />
                  Clients
                </>
              ) : isSelfEditor ? (
                <>
                  <MediaPlanPicto />
                  Mes projets
                </>
              ) : (
                <>
                  <MediaPlanPicto />
                  Plans média
                </>
              )}
            </MenuLeftLi>
            {isEditor ? (
              <PopupMenu
                isSeeAllDisplayed={true}
                sections={sections}
                isOpen={isCustomersMenuOpened}
                updateMenuProps={handleOpenCustomersMenu}
                closeMenu={closeMenu}
                selectedItemsId={selectedItemsId}
                setSelectedItems={setSelectedItemsId}
                isCustomerMenuOpened={isCustomersMenuOpened}
                isAddMenuOpened={isAddMenuOpened}
                isMediaPlansMenuOpened={isMediaPlansMenuOpened}
                setCampaignCreationModalMediaPlanId={
                  setCampaignCreationModalMediaPlanId
                }
                isEditor={true}
                isSelfEditor={false}
                companyId={companyId ?? ''}
              />
            ) : isSelfEditor ? (
              <PopupMenu
                // isSeeAllDisplayed={true}
                isSeeAllDisplayed={false}
                sections={sections}
                isOpen={isMediaPlansMenuOpened}
                updateMenuProps={handleOpenMediaPlansMenu}
                closeMenu={closeMenu}
                selectedItemsId={selectedItemsId}
                setSelectedItems={setSelectedItemsId}
                isCustomerMenuOpened={isCustomersMenuOpened}
                isAddMenuOpened={isAddMenuOpened}
                isMediaPlansMenuOpened={isMediaPlansMenuOpened}
                setCampaignCreationModalMediaPlanId={
                  setCampaignCreationModalMediaPlanId
                }
                isEditor={false}
                isSelfEditor={true}
                companyId={companyId ?? ''}
              />
            ) : (
              <PopupMenu
                isSeeAllDisplayed={true}
                sections={sections}
                isOpen={isMediaPlansMenuOpened}
                updateMenuProps={handleOpenMediaPlansMenu}
                closeMenu={closeMenu}
                selectedItemsId={selectedItemsId}
                setSelectedItems={setSelectedItemsId}
                isCustomerMenuOpened={isCustomersMenuOpened}
                isAddMenuOpened={isAddMenuOpened}
                isMediaPlansMenuOpened={isMediaPlansMenuOpened}
                setCampaignCreationModalMediaPlanId={
                  setCampaignCreationModalMediaPlanId
                }
                isEditor={false}
                isSelfEditor={false}
                companyId={companyId ?? ''}
              />
            )}

            <MenuLeftLi
              expandable={false}
              disabled={false}
              onClick={() => {
                navigate('/analytics')
              }}
              onMouseOver={(event) => {
                event.stopPropagation()
                closeMenu()
              }}
            >
              <AnalyticsPicto />
              Analytics
            </MenuLeftLi>
          </MenuLeftUl>
          <YamLogo
            handleClick={() => {
              navigate('/')
            }}
            style={yamLogoStyle}
          />
        </MenuLeftContent>
      </MenuLeft>
      {campaignCreationModalMediaPlanId && (
        <CampaignCreationPlatformModal
          onClose={() => {
            setCampaignCreationModalMediaPlanId('')
          }}
          mediaPlanId={campaignCreationModalMediaPlanId}
        />
      )}
    </>
  )
}

export default LeftMenu

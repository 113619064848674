import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { HomeContainer } from './styles'
import HomeEditorContent from './editor'
import HomeAdvertiserContent from './advertiser'
import {
  Status,
  StatusTranslation,
  getStatusTranslationByRole,
  getStatusKey
} from '../../types/status'
import {
  AdvertiserHomeDataType,
  EditorHomeDataType,
  SelfEditorHomeDataType,
  checkIfAdvertiserHomeData,
  checkIfEditorHomeData,
  checkIfSelfEditorHomeData,
  getHomeDataFromDateRange
} from '../../helpers/queries/getHomeData'
import { TableListFilter } from '../../components/TableList'
import { getCampaigns } from '../../helpers/queries/campaign/getCampaigns'
import { getMediaPlans } from '../../helpers/queries/mediaPlan/getMediaPlans'
import { Option } from '../../components/Dropdown'
import { AppContext } from '../../contexts/AppContext'
import {
  SelfEditorStrategyTranslation,
  StrategyTranslation
} from '../../types/strategy'
import AuthenticatedTemplate from '../../templates/AuthenticatedTemplate'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import ModalReasonOfReject from '../parts/ModalReasonOfReject'
import {
  EditorItemType,
  checkIfEditorItemTypeArray,
  AdvertiserItemType,
  checkIfAdvertiserItemTypeArray,
  checkIfSelfEditorItemTypeArray,
  SelfEditorItemType
} from './types'
import HomeSelfEditorContent from './selfEditor'
import Widget from '../parts/Widget'

const Home = () => {
  const { user, isEditor, isSelfEditor, companyId } = useContext(AppContext)
  console.log('home')
  const [data, setData] = useState<
    EditorHomeDataType | AdvertiserHomeDataType | SelfEditorHomeDataType | null
  >(null)
  const [hasFetchError, setHasFetchError] = useState(false)
  const [selectedInProgressType, setSelectedInProgressType] = useState(
    StrategyTranslation.CAMPAIGN
  )
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null)
  const [filteredData, setFilteredData] = useState<
    (EditorItemType | AdvertiserItemType | SelfEditorItemType)[]
  >([])

  const [dateRangeValue, setDateRangeValue] = useState('CURRENT_YEAR')

  const [isModalReasonOpen, setIsModalReasonOpen] = useState(false)
  const [mediaPlanName, setMediaPlanName] = useState('')
  const [rejectionReason, setRejectionReason] = useState('')
  const [totalItems, setTotalItems] = useState(0)
  const [isTutorialHidden, setIsTutorialHidden] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    ;(async function () {
      if (user && isEditor != null) {
        const result = await getHomeDataFromDateRange('CURRENT_YEAR')
        if (result != null) {
          if (isEditor && checkIfEditorHomeData(result)) {
            const campaigns = result.campaigns?.map((campaign) => ({
              id: campaign.id,
              inProgressType: StrategyTranslation.CAMPAIGN,
              name: campaign.name,
              customerName: campaign.customerName,
              mediaPlanId: campaign.mediaPlanId,
              status: campaign.status,
              updatedAt: campaign.updatedAt.toString(),
              feedback: campaign.feedback
            }))
            setData(result)
            setFilteredData(campaigns)
            setSelectedInProgressType(StrategyTranslation.CAMPAIGN)
            setSelectedStatus(StatusTranslation.DRAFT)
            if (campaigns.length === 0) {
              setIsTutorialHidden(false)
            }
          }
          if (isSelfEditor && checkIfSelfEditorHomeData(result)) {
            const mediaPlans = result.mediaPlans?.map((mediaPlan) => ({
              id: mediaPlan.id,
              inProgressType: SelfEditorStrategyTranslation.MEDIA_PLAN,
              name: mediaPlan.name,
              editorName: '',
              budget: mediaPlan.budget,
              status: mediaPlan.status,
              updatedAt: mediaPlan.updatedAt.toString()
            }))
            setData(result)
            setFilteredData(mediaPlans)
            setSelectedInProgressType(SelfEditorStrategyTranslation.MEDIA_PLAN)
            setSelectedStatus(StatusTranslation.DRAFT)
            if (mediaPlans.length === 0) {
              setIsTutorialHidden(false)
            }
          }
          if (!isEditor && !isSelfEditor && checkIfAdvertiserHomeData(result)) {
            const mediaPlans = result.mediaPlans?.map((mediaPlan) => ({
              id: mediaPlan.id,
              inProgressType: StrategyTranslation.MEDIA_PLAN,
              name: mediaPlan.name,
              editorName: mediaPlan.editorName,
              status: mediaPlan.status,
              updatedAt: mediaPlan.updatedAt.toString()
            }))
            setData(result)
            setFilteredData(mediaPlans)
            setSelectedInProgressType(StrategyTranslation.MEDIA_PLAN)
            setSelectedStatus(StatusTranslation.PENDING)
            if (mediaPlans.length === 0) {
              setIsTutorialHidden(false)
            }
          }
        } else {
          setHasFetchError(true)
        }
      }
    })()
  }, [user, isEditor])

  useEffect(() => {
    if (data && (selectedInProgressType || selectedStatus)) {
      ;(async function () {
        let filteredDataResult, totalItemsResult
        if (selectedInProgressType === StrategyTranslation.CAMPAIGN) {
          const response = await getCampaigns({
            status: selectedStatus
              ? (getStatusKey(selectedStatus) as Status)
              : undefined,
            skip: 0
          })
          filteredDataResult = response?.campaigns
          totalItemsResult = response?.navigation?.campaignsCount || 0
        } else {
          const response = await getMediaPlans({
            status: selectedStatus
              ? (getStatusKey(selectedStatus) as Status)
              : undefined,
            skip: 0
          })
          filteredDataResult = response?.mediaPlans
          totalItemsResult = response?.navigation?.mediaPlansCount || 0
        }
        if (
          filteredDataResult &&
          (checkIfAdvertiserItemTypeArray(filteredDataResult) ||
            checkIfEditorItemTypeArray(filteredDataResult) ||
            checkIfSelfEditorItemTypeArray(filteredDataResult))
        ) {
          setFilteredData(filteredDataResult)
        }
        setTotalItems(totalItemsResult)
      })()
    }
  }, [selectedInProgressType, selectedStatus, data])

  const filters: TableListFilter[] = [
    {
      title: 'Type',
      options: [
        StrategyTranslation.CAMPAIGN,
        isSelfEditor
          ? SelfEditorStrategyTranslation.MEDIA_PLAN
          : StrategyTranslation.MEDIA_PLAN
      ],
      seeAll: false,
      filterState: selectedInProgressType,
      handleFilterChange: (option: Option | null) => {
        setSelectedInProgressType(option?.label ?? '')
      }
    },
    {
      title: 'Statut',
      options: getStatusTranslationByRole({
        isEditor: isEditor ?? false,
        isSelfEditor: isSelfEditor ?? false
      }),
      seeAll: true,
      filterState: selectedStatus,
      handleFilterChange: (option) => {
        setSelectedStatus(option?.label ?? '')
      }
    }
  ]

  const handleEdit = (id: string) => {
    if (
      selectedInProgressType === StrategyTranslation.MEDIA_PLAN ||
      selectedInProgressType === SelfEditorStrategyTranslation.MEDIA_PLAN
    ) {
      navigate(`/media-plan/${id}/edit`)
    } else {
      navigate(`/campaign/${id}/edit`)
    }
  }

  if (hasFetchError) {
    return <ErrorPage />
  }
  if (data == null) {
    return <LoadingPage />
  }

  const fetchDataOnClickDate = async (daterange: string) => {
    const result = await getHomeDataFromDateRange(daterange)
    if (result != null) {
      setData(result)
    }
  }

  const handleOnRowClickEditor = (id: string) => {
    const currentData = filteredData as (EditorItemType | SelfEditorItemType)[]
    const status = currentData.filter((item) => item.id === id)?.[0].status
      ? StatusTranslation[
          currentData.filter((item) => item.id === id)?.[0].status as Status
        ]
      : StatusTranslation[Status.IN_PROGRESS]

    if (
      selectedInProgressType === StrategyTranslation.MEDIA_PLAN ||
      selectedInProgressType === SelfEditorStrategyTranslation.MEDIA_PLAN
    ) {
      if (
        status === StatusTranslation[Status.DRAFT] ||
        status === StatusTranslation[Status.REJECTED]
      ) {
        navigate(`/media-plan/${id}/review`)
      } else {
        navigate(`/media-plan/${id}/details`)
      }
    } else {
      if (
        status === StatusTranslation[Status.DRAFT] ||
        status === StatusTranslation[Status.REJECTED]
      ) {
        navigate(`/campaign/${id}/review`)
      } else {
        navigate(`/campaign/${id}/details`)
      }
    }
  }

  const handleOnRowClickAdvertiser = (id: string) => {
    if (selectedInProgressType === StrategyTranslation.MEDIA_PLAN) {
      navigate(`/media-plan/${id}/review`)
    } else {
      navigate(`/campaign/${id}/review`)
    }
  }

  const handleShowReason = (event: React.MouseEvent, id: string) => {
    event.stopPropagation()
    const item = filteredData.find((item) => item.id === id) as EditorItemType
    if (item.feedback && item.status === Status.REJECTED) {
      setRejectionReason(item.feedback)
      setIsModalReasonOpen(true)
      setMediaPlanName(item.name)
    }
  }

  const handleChangePage = async (page: number) => {
    const newSkip = page - 1
    if (data && (selectedInProgressType || selectedStatus)) {
      let filteredDataResult, totalItemsResult
      if (selectedInProgressType === StrategyTranslation.CAMPAIGN) {
        const response = await getCampaigns({
          status: selectedStatus
            ? (getStatusKey(selectedStatus) as Status)
            : undefined,
          skip: newSkip
        })
        filteredDataResult = response?.campaigns
        totalItemsResult = response?.navigation?.campaignsCount || 0
      } else {
        const response = await getMediaPlans({
          status: selectedStatus
            ? (getStatusKey(selectedStatus) as Status)
            : undefined,
          skip: newSkip
        })
        filteredDataResult = response?.mediaPlans
        totalItemsResult = response?.navigation?.mediaPlansCount || 0
      }
      setFilteredData(
        filteredDataResult as (EditorItemType | AdvertiserItemType)[]
      )
      setTotalItems(totalItemsResult)
    }
  }

  return (
    <AuthenticatedTemplate
      isEditor={isEditor != null ? isEditor : true}
      navigate={navigate}
    >
      <Widget>
        <HomeContainer>
          {isEditor ? (
            <HomeEditorContent
              data={data as EditorHomeDataType}
              filteredData={filteredData as EditorItemType[]}
              selectedInProgressType={selectedInProgressType}
              filters={filters}
              handleOnRowClick={handleOnRowClickEditor}
              handleRowAction={handleEdit}
              dateRangeValue={dateRangeValue}
              setDateRangeValue={setDateRangeValue}
              fetchDataOnClickDate={fetchDataOnClickDate}
              handleShowReason={handleShowReason}
              totalItems={totalItems}
              onChangePage={(page) => {
                handleChangePage(page)
              }}
              currentPage={1}
              isTutorialHidden={isTutorialHidden}
              setIsTutorialHidden={setIsTutorialHidden}
            />
          ) : isSelfEditor ? (
            <HomeSelfEditorContent
              data={data as SelfEditorHomeDataType}
              filteredData={filteredData as SelfEditorItemType[]}
              selectedInProgressType={selectedInProgressType}
              filters={filters}
              handleOnRowClick={handleOnRowClickEditor}
              dateRangeValue={dateRangeValue}
              setDateRangeValue={setDateRangeValue}
              fetchDataOnClickDate={fetchDataOnClickDate}
              totalItems={totalItems}
              onChangePage={(page) => {
                handleChangePage(page)
              }}
              currentPage={1}
              isTutorialHidden={isTutorialHidden}
              setIsTutorialHidden={setIsTutorialHidden}
              companyId={companyId}
            />
          ) : (
            <HomeAdvertiserContent
              data={data as AdvertiserHomeDataType}
              filteredData={filteredData as AdvertiserItemType[]}
              selectedInProgressType={selectedInProgressType}
              filters={filters}
              handleOnRowClick={handleOnRowClickAdvertiser}
              dateRangeValue={dateRangeValue}
              setDateRangeValue={setDateRangeValue}
              fetchDataOnClickDate={fetchDataOnClickDate}
              totalItems={totalItems}
              onChangePage={(page) => {
                handleChangePage(page)
              }}
              currentPage={1}
            />
          )}
          {isModalReasonOpen && (
            <ModalReasonOfReject
              onClose={() => {
                setIsModalReasonOpen(!isModalReasonOpen)
              }}
              mediaPlanName={mediaPlanName}
              rejectionReason={rejectionReason}
            />
          )}
        </HomeContainer>
      </Widget>
    </AuthenticatedTemplate>
  )
}

export default Home

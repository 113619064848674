import React from 'react'

import { TagsListContainer } from './styles'
import Tag, { TagProps } from '..'
import { isStringArray } from '../../../../helpers/nativeTypes'

type TagListProps = {
  tags: { id: string; name: string }[] | string[]
  actions?: TagProps['actions']
}

const TagList = ({ tags, actions }: TagListProps) => {
  if (isStringArray(tags)) {
    return (
      <TagsListContainer>
        {tags.map((tag, index) => (
          <Tag key={tag} id={index} actions={actions}>
            {tag}
          </Tag>
        ))}
      </TagsListContainer>
    )
  }

  return (
    <TagsListContainer>
      {tags.map((tag) => (
        <Tag key={tag.id} id={tag.id} actions={actions}>
          {tag.name}
        </Tag>
      ))}
    </TagsListContainer>
  )
}

export default TagList

import React from 'react'

import SelfEditorAIFlowIntroductionGenContent from './Content'

export type SelfEditorAIFlowIntroductionGenContainerProps = {
  handleClick: () => void
}

const SelfEditorAIFlowIntroductionGenContainer = ({
  handleClick
}: SelfEditorAIFlowIntroductionGenContainerProps) => {
  return <SelfEditorAIFlowIntroductionGenContent handleClick={handleClick} />
}

export { SelfEditorAIFlowIntroductionGenContainer }

import React from 'react'

import { KeywordsToSelectResultContainer } from './styles'
import { TagProps } from '../../../../../../components/Tag/new'
import TagList from '../../../../../../components/Tag/new/TagList'
import { TagActionEnum } from '../../../../../../components/Tag/new/types'

type KeywordsToSelectProps = {
  pendingKeywords: string[]
  selectedKeywords: string[]
  onPendingKeywordsChange: (keywords: string[]) => void
  onSelectedKeywordsChange: (keywords: string[]) => void
  selectedKeywordsMax: number
}

const KeywordsToSelect = ({
  pendingKeywords,
  selectedKeywords,
  onPendingKeywordsChange,
  onSelectedKeywordsChange,
  selectedKeywordsMax
}: KeywordsToSelectProps) => {
  const onKeywordAddToSelection = (keyword: string, index: number) => {
    if (selectedKeywords.length >= selectedKeywordsMax) return
    onKeywordDelete(index)
    onSelectedKeywordsChange([...selectedKeywords, keyword])
  }

  const onKeywordDelete = (index: number) => {
    const updatedKeywords = [...pendingKeywords]
    updatedKeywords.splice(index, 1)
    onPendingKeywordsChange(updatedKeywords)
  }

  const tagActions: TagProps['actions'] = [
    {
      onClick: (index: number) => {
        onKeywordAddToSelection(pendingKeywords[index], index)
      },
      type: TagActionEnum.ADD,
      disabled: selectedKeywords.length >= selectedKeywordsMax
    }
  ]

  return (
    <KeywordsToSelectResultContainer>
      <TagList tags={pendingKeywords} actions={tagActions} />
    </KeywordsToSelectResultContainer>
  )
}

export default KeywordsToSelect

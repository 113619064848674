import React, { useEffect, useState } from 'react'

import SelfEditorAIFlowDescriptionsGenContent from './Content'
import { mockedDescriptions } from '../mock/descriptions'

export type SelfEditorAIFlowDescriptionsGenContainerProps = {
  summary: Record<string, string>
  updateSummary: (summary: Record<string, string>) => void
  headlines: string[]
  keywords: string[]
  descriptions: string[]
  updateDescriptions: (descriptions: string[]) => void
  handleNext: () => void
  handlePrevious: () => void
}

const SelfEditorAIFlowDescriptionsGenContainer = ({
  summary,
  updateSummary,
  headlines,
  keywords,
  descriptions,
  updateDescriptions,
  handleNext,
  handlePrevious
}: SelfEditorAIFlowDescriptionsGenContainerProps) => {
  const [generatedPendingDescriptions, setGeneratedPendingDescriptions] =
    useState<string[]>([])
  const [isGenerationPending, setIsGenerationPending] = useState(false)
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false)

  useEffect(() => {
    if (isGenerationPending) {
      setTimeout(() => {
        setGeneratedPendingDescriptions(mockedDescriptions)
        setIsGenerationPending(false)
      }, 1000)
    }
  }, [isGenerationPending])

  const handleGenerateDescription = () => {
    setIsGenerationPending(true)
  }

  const handleOpenOrCloseModal = () => {
    setIsSummaryModalOpen((prev) => !prev)
  }

  return (
    <SelfEditorAIFlowDescriptionsGenContent
      summary={summary}
      updateSummary={updateSummary}
      headlines={headlines}
      keywords={keywords}
      descriptions={descriptions}
      updateDescriptions={updateDescriptions}
      generatedPendingDescriptions={generatedPendingDescriptions}
      updateGeneratedPendingDescriptions={setGeneratedPendingDescriptions}
      handleGenerateDescription={handleGenerateDescription}
      isGenerationPending={isGenerationPending}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      isSummaryModalOpen={isSummaryModalOpen}
      handleToggleOpenModal={handleOpenOrCloseModal}
    />
  )
}

export { SelfEditorAIFlowDescriptionsGenContainer }

import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Margin from '../../../../../constants/margin'
import Colors from '../../../../../constants/colors'

export const ScrollPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.NEW_YETIC_GREY};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: ${Colors.YETIC_WHITE};
  }
`

export const StyledWrapper = styled.div`
  padding: 20px 80px 100px;
  margin-top: ${Margin.m8};
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SelfEditorAIFlowPaymentButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: ${Margin.m7};
  justify-content: end;
`

export const SelfEditorAIFlowPaymentBody = styled.div`
  width: 100%;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
`

export const SelfEditorAIFlowPaymentColumn = styled.div`
  display: flex;
  flex: 1;
`

export const selfEditorAIFlowInfoItemTitleStyle: CSSProperties = {
  fontWeight: 'bold',
  marginTop: Margin.m8,
  marginBottom: Margin.m3
}

export const selfEditorAIFlowInfoFirstItemTitleStyle: CSSProperties = {
  fontWeight: 'bold',
  marginTop: 0,
  marginBottom: Margin.m3
}

import React from 'react'

import {
  DescriptionsEmptyContainer,
  SelfEditorAIFlowDescriptionsGenContentBody,
  SelfEditorAIFlowDescriptionsGenContentGenerateButton,
  SelfEditorAIFlowDescriptionsGenContentWrapper,
  SelfEditorAIFlowDescriptionsGenCurrentContentColumn,
  invalidSectionTextStyle,
  titleStyle
} from './styles'
import Card from '../components/Card'
import CardHeader from '../components/Card/Header'
import Button from '../../../../components/Button'
import CardContent from '../components/Card/CardContent'
import HeaderItemsCount from '../components/Card/Header/HeaderItemsCount'
import AnimatedLoader from '../../../../components/AnimatedLoader'
import {
  maxNumberOfDescriptions,
  minNumberOfDescriptions
} from '../mock/descriptions'
import Colors from '../../../../constants/colors'
import StarsIcon from '../../../../components/Icon/stars'
import SelectedDescriptions from '../components/Descriptions/SelectedDescriptions'
import DescriptionsToSelect from '../components/Descriptions/DescriptionsToSelect'
import P from '../../../../components/Text/Paragraph'
import SelfEditorAIFlowDescriptionsGenButtonsBar from './SelfEditorAIFlowDescriptionsGenButtonsBar'
import SelfEditorAIFlowDescriptionsGenModal from './SelfEditorAIFlowDescriptionsGenModal'
import Stepper from '../../../../components/Stepper'
import { aiFlowSteps } from '../constants'
import Margin from '../../../../constants/margin'
import H2 from '../../../../components/Text/Headlines/H2'

export type SelfEditorAIFlowDescriptionsGenContentProps = {
  summary: Record<string, string>
  updateSummary: (summary: Record<string, string>) => void
  headlines: string[]
  keywords: string[]
  descriptions: string[]
  updateDescriptions: (descriptions: string[]) => void
  generatedPendingDescriptions: string[]
  updateGeneratedPendingDescriptions: (
    generatedPendingDescriptions: string[]
  ) => void
  handleGenerateDescription: () => void
  isGenerationPending: boolean
  handleNext: () => void
  handlePrevious: () => void
  isSummaryModalOpen: boolean
  handleToggleOpenModal: () => void
}

const SelfEditorAIFlowDescriptionsGenContent = ({
  summary,
  updateSummary,
  headlines,
  keywords,
  descriptions,
  updateDescriptions,
  generatedPendingDescriptions,
  updateGeneratedPendingDescriptions,
  handleGenerateDescription,
  isGenerationPending,
  handleNext,
  handlePrevious,
  isSummaryModalOpen,
  handleToggleOpenModal
}: SelfEditorAIFlowDescriptionsGenContentProps) => {
  const isSectionValid =
    descriptions.length >= minNumberOfDescriptions &&
    descriptions.length <= maxNumberOfDescriptions

  return (
    <SelfEditorAIFlowDescriptionsGenContentWrapper>
      <Stepper steps={aiFlowSteps} currentIndex={4} marginBottom={Margin.m6} />
      <H2 style={titleStyle}>
        {`Génération des descriptions de mon annonce`}
      </H2>
      <SelfEditorAIFlowDescriptionsGenContentBody>
        <SelfEditorAIFlowDescriptionsGenCurrentContentColumn>
          <Card style={{ borderRadius: 0, boxShadow: 'none' }}>
            <CardHeader
              title="Générer des descriptions (2 minimum)"
              action={
                <Button
                  newFont
                  onClick={handleGenerateDescription}
                  width="130px"
                >
                  {isGenerationPending ? (
                    <AnimatedLoader color="white" />
                  ) : (
                    <SelfEditorAIFlowDescriptionsGenContentGenerateButton>
                      <StarsIcon color="white" width="16px" />
                      {generatedPendingDescriptions.length > 0
                        ? 'Regénérer'
                        : 'Générer'}
                    </SelfEditorAIFlowDescriptionsGenContentGenerateButton>
                  )}
                </Button>
              }
            />
            <CardContent height="300px">
              {generatedPendingDescriptions.length > 0 ? (
                <DescriptionsToSelect
                  pendingDescriptions={generatedPendingDescriptions}
                  onPendingDescriptionsChange={
                    updateGeneratedPendingDescriptions
                  }
                  selectedDescriptions={descriptions}
                  onSelectedDescriptionsChange={updateDescriptions}
                  selectedDescriptionsMax={maxNumberOfDescriptions}
                />
              ) : (
                <DescriptionsEmptyContainer>
                  <P>{`Cliquez sur "Générer" pour obtenir des descriptions pour votre annonce`}</P>
                </DescriptionsEmptyContainer>
              )}
            </CardContent>
          </Card>
        </SelfEditorAIFlowDescriptionsGenCurrentContentColumn>
        <SelfEditorAIFlowDescriptionsGenCurrentContentColumn>
          <Card
            style={{
              borderRadius: 0,
              boxShadow: 'none',
              borderLeft: `1px solid ${Colors.YETIC_GREY_DARK}`
            }}
          >
            <CardHeader
              title="Descriptions sélectionnées"
              action={
                <HeaderItemsCount
                  count={descriptions.length}
                  max={maxNumberOfDescriptions}
                  min={minNumberOfDescriptions}
                />
              }
            />
            <CardContent height="300px">
              {descriptions.length > 0 ? (
                <SelectedDescriptions
                  descriptions={descriptions}
                  onDescriptionsChange={updateDescriptions}
                />
              ) : (
                <DescriptionsEmptyContainer>
                  <P>{`Aucune description séléctionnée`}</P>
                  <P>{`Veuillez ajouter des descriptions depuis celles générées`}</P>
                </DescriptionsEmptyContainer>
              )}
            </CardContent>
          </Card>
        </SelfEditorAIFlowDescriptionsGenCurrentContentColumn>
      </SelfEditorAIFlowDescriptionsGenContentBody>
      <SelfEditorAIFlowDescriptionsGenButtonsBar
        isSectionValid={isSectionValid}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleClickOpenModal={handleToggleOpenModal}
      />
      {!isSectionValid && (
        <P small style={invalidSectionTextStyle}>
          {`Vous devez sélectionner au moins ${minNumberOfDescriptions} descriptions pour continuer.`}
        </P>
      )}
      {isSummaryModalOpen && (
        <SelfEditorAIFlowDescriptionsGenModal
          onClose={handleToggleOpenModal}
          headlines={headlines}
          keywords={keywords}
          summary={summary}
          updateSummary={updateSummary}
        />
      )}
    </SelfEditorAIFlowDescriptionsGenContentWrapper>
  )
}

export default SelfEditorAIFlowDescriptionsGenContent

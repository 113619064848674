import { OpenedQuestionsDataType, SurveyContentType } from '../types'

// @ts-ignore
export const mockedTechSurveyData: SurveyContentType = [
  {
    "id": "g1",
    "group": "productGoal",
    "text": "La publicité doit-elle promouvoir :<br/>un produit",
    "next": [
      {
        "id": "g1N-g2",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1N-g2",
    "group": "locationGoal",
    "text": "La publicité doit-elle promouvoir :<br/>un établissement ou un magasin",
    "next": [
      {
        "id": "g1N-g2N-g3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1N-g2Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1N-g2N-g3",
    "group": "brandGoal",
    "text": "La publicité doit-elle promouvoir :<br/>une marque ou une entreprise",
    "next": [
      {
        "id": "g1N-g2N-g3N-g4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1N-g2Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1N-g2N-g3N-g4",
    "group": "not-handled-group",
    "text": "Nous ne gérons que 3 cas : celui où la publicité promouvoit un ensemble de produits, celui où elle promouvoit un établissement et celui où elle promouvoit une marque",
    "btn": {
      "text": "Recommencer",
      "onClickId": "restart"
    }
  },
  {
    "id": "g1Y-ct",
    "group": "category",
    "text": "A quelle catégorie correspond le plus votre produit ?",
    "next": [
      {
        "id": "g1Y-ct-ct1",
        "text": "Restauration"
      },
      {
        "id": "g1Y-ct-ct2",
        "text": "Magasin (vêtement, high-tech, maison, électro-ménager ou général)"
      },
      {
        "id": "g1Y-ct-ct3",
        "text": "Epicerie (vente de nourriture)"
      },
      {
        "id": "g1Y-ct-ct4",
        "text": "Boutique (fleuriste, opticien, bijouterie, librairie, parfumerie)"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1",
    "group": "categoryRestaurants",
    "text": "A quelle catégorie correspond le plus votre restaurant (ou vos restaurants) ?",
    "next": [
      {
        "id": "g1Y-ct-ct1a-",
        "text": "Bar"
      },
      {
        "id": "g1Y-ct-ct1b-sct1",
        "text": "Brasserie"
      },
      {
        "id": "g1Y-ct-ct1c-",
        "text": "Pizzeria"
      },
      {
        "id": "g1Y-ct-ct1d-",
        "text": "Fast food"
      },
      {
        "id": "g1Y-ct-ct1e-",
        "text": "Restauration asiatique"
      },
      {
        "id": "g1Y-ct-ct1f-",
        "text": "Restauration gastronomique"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct2",
    "group": "categoryStore",
    "text": "A quelle catégorie correspond le plus votre magasin (ou vos magasins) ?",
    "next": [
      {
        "id": "g1Y-ct-ct2a-",
        "text": "Vente de vêtements"
      },
      {
        "id": "g1Y-ct-ct2b-",
        "text": "High-tech"
      },
      {
        "id": "g1Y-ct-ct2c-",
        "text": "Maison/meubles"
      },
      {
        "id": "g1Y-ct-ct2d-",
        "text": "Electro-ménager"
      },
      {
        "id": "g1Y-ct-ct2e-",
        "text": "Magasin généraliste (exemple: supermarché)"
      },
      {
        "id": "g1Y-ct-ct2f-",
        "text": "Autre"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct3",
    "group": "categoryGrocery",
    "text": "A quelle catégorie correspond le plus votre épicerie (ou vos épiceries) ?",
    "next": [
      {
        "id": "g1Y-ct-ct3a-",
        "text": "Chocolaterie"
      },
      {
        "id": "g1Y-ct-ct3b-",
        "text": "Boulangerie"
      },
      {
        "id": "g1Y-ct-ct3c-",
        "text": "Boucherie"
      },
      {
        "id": "g1Y-ct-ct3d-",
        "text": "Epicerie fine"
      },
      {
        "id": "g1Y-ct-ct3e-",
        "text": "Autre"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4",
    "group": "categoryShop",
    "text": "A quelle catégorie correspond le plus votre boutique (ou vos boutiques) ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1",
        "text": "Fleuriste"
      },
      {
        "id": "g1Y-ct-ct4b-",
        "text": "Librairie"
      },
      {
        "id": "g1Y-ct-ct4c-",
        "text": "Opticien"
      },
      {
        "id": "g1Y-ct-ct4d-",
        "text": "Parfumerie"
      },
      {
        "id": "g1Y-ct-ct4e-",
        "text": "Bijouterie"
      },
      {
        "id": "g1Y-ct-ct4f-",
        "text": "Autre"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1",
    "group": "delivery",
    "text": "Est-il possible de se faire livrer ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2",
    "group": "deliveryFree",
    "text": "La livraison est-elle gratuite ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4",
        "text": "Oui à partir d'un certain montant",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5",
    "group": "onSiteCollection",
    "text": "Y a-t-il possibilité d'un retrait en magasin ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5",
    "group": "onSiteCollection",
    "text": "Y a-t-il possibilité d'un retrait en magasin ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5Y-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4Y-d5N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5Y-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4Y-d5N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2N-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2N-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2N-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2N-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2N-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2N-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2N-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2Y-m3N-m4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2Y-m3Y-m4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2Y-m3N-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2Y-m3N-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2Y-m3Y-m4",
    "group": "guarantee",
    "text": "Voulez-vous mettre en avant une durée de garantie ?",
    "next": [
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2Y-m3Y-m4N-",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct4a-d1N-d4N-d8N-m1Y-m2Y-m3Y-m4Y-",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1",
    "group": "menu",
    "text": "Voulez-vous mettre en avant un menu ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1",
    "group": "delivery",
    "text": "Est-il possible de se faire livrer ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2",
    "group": "deliveryFree",
    "text": "La livraison est-elle gratuite ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4",
        "text": "Oui à partir d'un certain montant",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1",
    "group": "delivery",
    "text": "Est-il possible de se faire livrer ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2",
    "group": "deliveryFree",
    "text": "La livraison est-elle gratuite ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4",
        "text": "Oui à partir d'un certain montant",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4",
    "group": "onlineBuy",
    "text": "Est-il possible de commander en ligne ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8",
    "group": "displayCity",
    "text": "Voulez-vous mettre en avant le nom d'une ville/région/pays dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1",
    "group": "discount",
    "text": "Voulez-vous mettre en avant une réduction dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2",
    "group": "discountPercentage",
    "text": "La réduction que vous voulez mettre en avant s'exprime-t-elle en pourcentage ? (si oui, alors on vous demandera des pourcentages, si non, on vous demandera des montants en euros.)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3",
    "group": "minPrice",
    "text": "Voulez-vous mettre en avant un prix minimum dans la publicité ? (par exemple 'A partir de X euros')",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5",
    "group": "exactPrice",
    "text": "Voulez-vous entrer le prix du menu dans la publicité ?",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5",
    "group": "frenchMeat",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La viande soit d'origine française",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7",
    "group": "foodHomeMade",
    "text": "Voulez-vous mettre en avant le fait que :<br/>La cuisine soit faite maison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9",
    "group": "foodNew",
    "text": "Voulez-vous mettre en avant une nouvelle recette ? (si oui, il vous sera demandé à la fin le nom du produit correspondant à cette recette ou ce plat)",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10",
    "group": "seasonal",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les fruits/légumes soient de saison",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050"
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850"
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  },
  {
    "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11",
    "group": "fresh",
    "text": "Voulez-vous mettre en avant le fait que :<br/>Les produits soient frais",
    "next": [
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out",
        "text": "Non",
        "activeBorderColor": "#bf060630",
        "activeBackgroundColor": "#ffe0e050",
        "switchToOpenedQuestions": true
      },
      {
        "id": "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out",
        "text": "Oui",
        "activeBorderColor": "#0cab0340",
        "activeBackgroundColor": "#dbedd850",
        "switchToOpenedQuestions": true
      }
    ]
  }
]

export const mockedTechOpenedQuestions: OpenedQuestionsDataType = {
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4Y-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8Y-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2N-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1N-d1N-d4N-d8N-m1Y-m2Y-m3Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2c-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2Y-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1Y-d2N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4Y-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8Y-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "display-city",
      "text": "Quel est le nom de la ville/région/pays que vous souhaitez mettre en avant ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2N-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-not-percentage",
      "text": "Quel est le montant de la réduction, en euros, à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3N-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5Y-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "exact-price",
      "text": "Quel est le prix exact du menu ? (en euros)"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5Y-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7N-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9N-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10N-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11N-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ],
  "g1Y-ct-ct1b-sct1Y-d1N-d4N-d8N-m1Y-m2Y-m3Y-m5N-sp5N-sp7Y-sp9Y-sp10Y-sp11Y-out": [
    {
      "id": "name-ct1b",
      "text": "Quel est le nom de votre enseigne ?"
    },
    {
      "id": "menu",
      "text": "Quel est le nom du menu que vous souhaitez mettre en avant ?"
    },
    {
      "id": "discount-percentage",
      "text": "Quel est le % de réduction à afficher dans la publicité ?"
    },
    {
      "id": "food-new",
      "text": "Quel est le nom de la nouvelle recette ou du nouveau plat que vous souhaitez mettre en avant ?"
    }
  ]
}

import React from 'react'

import Survey from '../../../../components/Survey'
import { SurveyContentType } from '../../../../components/Survey/types'
import Margin from '../../../../constants/margin'
import { StyledWrapper } from './styles'
import Stepper from '../../../../components/Stepper'
import { aiFlowSteps } from '../constants'

export type SelfEditorAIFlowContentProps = {
  content: SurveyContentType
  currentQuestionId: string
  updateCurrentQuestionId: (questionId: string) => void
  onAnswerClick: (answerId: string) => void
  onClose: () => void
  onValidate: (id: string) => void
}

const SelfEditorAIFlowContent = ({
  content,
  currentQuestionId,
  updateCurrentQuestionId,
  onAnswerClick,
  onClose,
  onValidate
}: SelfEditorAIFlowContentProps) => (
  <StyledWrapper>
    <Stepper steps={aiFlowSteps} currentIndex={0} marginBottom={Margin.m12} />
    <Survey
      content={content}
      currentQuestionId={currentQuestionId}
      updateCurrentQuestionId={updateCurrentQuestionId}
      onAnswerClick={onAnswerClick}
      onClose={onClose}
      onValidate={onValidate}
    />
  </StyledWrapper>
)

export default SelfEditorAIFlowContent

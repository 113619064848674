import styled from '@emotion/styled'

import Padding from '../../../../../constants/padding'
import Margin from '../../../../../constants/margin'

export const StyledWrapper = styled.div`
  padding: ${Padding.p3} ${Padding.p10} ${Padding.p10};
  margin-top: ${Margin.m8};
  overflow-y: auto;
  overflow-x: hidden;
`

export const StyledCenteredDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 25%;
  align-items: flex-start;
  gap: ${Margin.m6};
  margin-top: ${Margin.m11};
`

import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Margin from '../../../../../constants/margin'
import Padding from '../../../../../constants/padding'

export const SelfEditorAIFlowHeadlinesGenContentWrapper = styled.div`
  padding: ${Padding.p3} ${Padding.p10} ${Padding.p10};
  margin-top: ${Margin.m8};
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`

export const titleStyle: CSSProperties = {
  textAlign: 'center',
  marginTop: Margin.m8,
  marginBottom: Margin.m7
}

export const SelfEditorAIFlowHeadlinesGenContentBody = styled.div`
  width: 100%;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
`

export const SelfEditorAIFlowHeadlinesGenContentColumn = styled.div<{
  height?: string
}>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: 1;
  height: ${({ height }) => height || 'auto'};
  min-height: 200px;
`

export const SelfEditorAIFlowHeadlinesGenContentGenerateButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`

export const SelfEditorAIFlowHeadlinesGenContentButtons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: ${Margin.m7};
  align-self: flex-end;
`

export const TitlesEmptyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 32px;
`

export const invalidSectionTextStyle: CSSProperties = {
  marginTop: Margin.m4,
  textAlign: 'end'
}

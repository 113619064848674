import styled from '@emotion/styled'

import Margin from '../../../constants/margin'
import Colors from '../../../constants/colors'
import Padding from '../../../constants/padding'

export const doneColor = Colors.YETIC_GREEN_LIGHT
const bubbleSize = 30
const lineHeight = 2

export const StyledStepper = styled.div<{ marginBottom?: string }>`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '0'};
  text-align: center;
`

export const StepperStep = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${Margin.m1};
  align-items: center;
  width: 200px;
  box-sizing: border-box;
  padding: 0 ${Padding.p5};
`

export const StepperStepBubble = styled.div<{
  done?: boolean
  current?: boolean
  last?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ done, current }) =>
    done ? doneColor : current ? 'transparent' : Colors.NEW_YETIC_GREY_DARK};
  color: white;
  border-radius: 50%;
  ${({ current }) => current && `box-shadow: inset 0 0 0 5px black;`}
  width: ${bubbleSize}px;
  height: ${bubbleSize}px;

  ${({ last, done }) =>
    !last &&
    `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(${bubbleSize}px, ${(bubbleSize - lineHeight) / 2}px);
      background-color: ${done ? doneColor : Colors.YETIC_GREY_DARK};
      width: calc(100% - ${2 * bubbleSize}px);
      height: ${lineHeight}px;
    }
  `}
`

import React, { useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppContext } from '../../../contexts/AppContext'
import SelfEditorAIFlowExplantion from './SelfEditorAIFlowExplanation'
import AuthenticatedTemplate from '../../../templates/AuthenticatedTemplate'
import SelfEditorAIFlowQuestionsContainer from './SelfEditorAIFlowQuestions'
import SelfEditorAIFlowIntroductionGen from './SelfEditorAIFlowIntroductionGen'
import SelfEditorAIFlowHeadlinesGenContainer from './SelfEditorAIFlowHeadlinesGen'
import { mockedSummary } from './mock/summary'
import SelfEditorAIFlowKeywordsGenContainer from './SelfEditorAIFlowKeywordsGen'
import SelfEditorAIFlowOpenedQuestionsContainer from './SelfEditorAIFlowOpenedQuestions'
import SelfEditorAIFlowDescriptionsGenContainer from './SelfEditorAIFlowDescriptionsGen'
import {
  mockedTechOpenedQuestions,
  mockedTechSurveyData
} from '../../../components/Survey/mockedData/retail2'
import {
  mockedCommonOpenedQuestions,
  mockedCommonSurveyData
} from '../../../components/Survey/mockedData/common'
import SelfEditorAIFlowPaymentContainer from './SelfEditorAIFlowPayment'
import SelfEditorAIFlowCampaignParamsContainer from './SelfEditorAIFlowCampaignParams'
import SelfEditorAIFlowEstimatedTime from './SelfEditorAIFlowEstimatedTime'
import { SelfEditorAIFlowUrlContainer } from './SelfEditorAIFlowUrl/Container'
import SelfEditorAIFlowIntroductionCampaignParams from './SelfEditorAIFlowIntroductionCampaignParams'

enum SelectedStep {
  EXPLANATION = 'EXPLANATION',
  ESTIMATED_TIME = 'ESTIMATED_TIME',
  QUESTIONS = 'QUESTIONS',
  OPENED_QUESTIONS = 'OPENED_QUESTIONS',
  COMMON_QUESTIONS = 'COMMON_QUESTIONS',
  COMMON_OPENED_QUESTIONS = 'COMMON_OPENED_QUESTIONS',
  URL_INPUT = 'URL_INPUT',
  INTRODUCTION_CAMPAIGN_PARAMETERS = 'INTRODUCTION_CAMPAIGN_PARAMETERS',
  CAMPAIGN_PARAMETERS = 'CAMPAIGN_PARAMETERS',
  SUMMARY = 'SUMMARY',
  HEADLINES_GENERATION = 'HEADLINES_GENERATION',
  KEYWORDS_GENERATION = 'KEYWORDS_GENERATION',
  DESCRIPTIONS_GENERATION = 'DESCRIPTIONS_GENERATION',
  PAYMENT = 'PAYMENT'
}

const SelfEditorAIFlow = () => {
  const [currentStep, setCurrentStep] = useState<SelectedStep>(
    SelectedStep.EXPLANATION
  )
  const [questionsFlowId, setQuestionsFlowId] = useState<string | null>(null)
  const [answers, setAnswers] = useState<string[]>([])
  const [url, setUrl] = useState('')
  const [headlines, setHeadlines] = useState<string[]>([])
  const [summary, setSummary] = useState<Record<string, string>>(mockedSummary)
  const [keywords, setKeywords] = useState<string[]>([])
  const [descriptions, setDescriptions] = useState<string[]>([])
  const [startDate, setStartDate] = useState<number | null>(null)
  const [endDate, setEndDate] = useState<number | null>(null)
  const [dailyBudget, setDailyBudget] = useState<number | null>(null)

  const navigate = useNavigate()
  const pageRef = useRef<HTMLDivElement>(null)
  const { user, companyId } = useContext(AppContext)

  const handleGoToEstimatedTime = () => {
    setCurrentStep(SelectedStep.ESTIMATED_TIME)
  }

  const handleGoToQuestions = () => {
    setCurrentStep(SelectedStep.QUESTIONS)
  }

  const handleGoToOpenQuestions = (id: string) => {
    setQuestionsFlowId(id)
    setCurrentStep(SelectedStep.OPENED_QUESTIONS)
  }

  const handleGoToCommonQuestions = () => {
    setQuestionsFlowId(null)
    setCurrentStep(SelectedStep.COMMON_QUESTIONS)
  }

  const handleGoToCommonOpenQuestions = (id: string) => {
    setQuestionsFlowId(id)
    setCurrentStep(SelectedStep.COMMON_OPENED_QUESTIONS)
  }

  const handleGoToUrlPage = () => {
    setCurrentStep(SelectedStep.URL_INPUT)
  }

  const handleGoToCampaignParameters = () => {
    setCurrentStep(SelectedStep.CAMPAIGN_PARAMETERS)
  }

  const handleUpdateHeadlines = (newHeadlines: string[]) => {
    setHeadlines(newHeadlines)
  }

  const handleUpdateSummary = (newSummary: Record<string, string>) => {
    setSummary(newSummary)
  }

  const handleUpdateKeywords = (newKeywords: string[]) => {
    setKeywords(newKeywords)
  }

  const handleUpdateDescriptions = (newDescriptions: string[]) => {
    setDescriptions(newDescriptions)
  }

  const handleGoToIntroductionCampaignParameters = () => {
    setCurrentStep(SelectedStep.INTRODUCTION_CAMPAIGN_PARAMETERS)
  }

  const handleGoToSummary = () => {
    setCurrentStep(SelectedStep.SUMMARY)
  }

  const handleGoToHeadlinesGeneration = () => {
    setCurrentStep(SelectedStep.HEADLINES_GENERATION)
  }

  const handleGoToKeywordsGeneration = () => {
    setCurrentStep(SelectedStep.KEYWORDS_GENERATION)
  }

  const handleGoToDescriptionsGeneration = () => {
    setCurrentStep(SelectedStep.DESCRIPTIONS_GENERATION)
  }

  const handleGoToPayment = () => {
    setCurrentStep(SelectedStep.PAYMENT)
  }

  const handleSaveUrl = (url: string) => {
    setUrl(url)
    handleGoToIntroductionCampaignParameters()
  }

  const renderPage = () => {
    if (currentStep === SelectedStep.EXPLANATION) {
      return (
        <SelfEditorAIFlowExplantion handleClick={handleGoToEstimatedTime} />
      )
    } else if (currentStep === SelectedStep.ESTIMATED_TIME) {
      return <SelfEditorAIFlowEstimatedTime handleClick={handleGoToQuestions} />
    } else if (currentStep === SelectedStep.QUESTIONS) {
      return (
        <SelfEditorAIFlowQuestionsContainer
          surveyData={mockedTechSurveyData}
          onValidate={handleGoToOpenQuestions}
        />
      )
    } else if (currentStep === SelectedStep.OPENED_QUESTIONS) {
      return (
        <SelfEditorAIFlowOpenedQuestionsContainer
          openedQuestionsData={mockedTechOpenedQuestions}
          questionsFlowId={questionsFlowId ?? ''}
          answers={answers}
          setAnswers={setAnswers}
          onValidate={handleGoToCommonQuestions}
        />
      )
    } else if (currentStep === SelectedStep.COMMON_QUESTIONS) {
      return (
        <SelfEditorAIFlowQuestionsContainer
          surveyData={mockedCommonSurveyData}
          onValidate={handleGoToCommonOpenQuestions}
        />
      )
    } else if (currentStep === SelectedStep.COMMON_OPENED_QUESTIONS) {
      return (
        <SelfEditorAIFlowOpenedQuestionsContainer
          openedQuestionsData={mockedCommonOpenedQuestions}
          questionsFlowId={questionsFlowId ?? ''}
          answers={answers}
          setAnswers={setAnswers}
          onValidate={handleGoToUrlPage}
        />
      )
    } else if (currentStep === SelectedStep.URL_INPUT) {
      return <SelfEditorAIFlowUrlContainer onValidate={handleSaveUrl} />
    } else if (currentStep === SelectedStep.INTRODUCTION_CAMPAIGN_PARAMETERS) {
      return (
        <SelfEditorAIFlowIntroductionCampaignParams
          handleClick={handleGoToCampaignParameters}
        />
      )
    } else if (currentStep === SelectedStep.CAMPAIGN_PARAMETERS) {
      return (
        <SelfEditorAIFlowCampaignParamsContainer
          startDate={startDate}
          endDate={endDate}
          dailyBudget={dailyBudget}
          handleChangeStartDate={setStartDate}
          handleChangeEndDate={setEndDate}
          handleChangeDailyBudget={setDailyBudget}
          handleNext={handleGoToSummary}
          handlePrevious={handleGoToIntroductionCampaignParameters}
          summary={summary}
        />
      )
    } else if (currentStep === SelectedStep.SUMMARY) {
      return (
        <SelfEditorAIFlowIntroductionGen
          handleClick={handleGoToHeadlinesGeneration}
        />
      )
    } else if (currentStep === SelectedStep.HEADLINES_GENERATION) {
      return (
        <SelfEditorAIFlowHeadlinesGenContainer
          summary={summary}
          updateSummary={handleUpdateSummary}
          headlines={headlines}
          updateHeadlines={handleUpdateHeadlines}
          handleNext={handleGoToKeywordsGeneration}
          handlePrevious={handleGoToCampaignParameters}
        />
      )
    } else if (currentStep === SelectedStep.KEYWORDS_GENERATION) {
      return (
        <SelfEditorAIFlowKeywordsGenContainer
          summary={summary}
          updateSummary={handleUpdateSummary}
          headlines={headlines}
          keywords={keywords}
          updateKeywords={handleUpdateKeywords}
          handleNext={handleGoToDescriptionsGeneration}
          handlePrevious={handleGoToHeadlinesGeneration}
        />
      )
    } else if (currentStep === SelectedStep.DESCRIPTIONS_GENERATION) {
      return (
        <SelfEditorAIFlowDescriptionsGenContainer
          summary={summary}
          updateSummary={handleUpdateSummary}
          headlines={headlines}
          keywords={keywords}
          descriptions={descriptions}
          updateDescriptions={handleUpdateDescriptions}
          handleNext={handleGoToPayment}
          handlePrevious={handleGoToKeywordsGeneration}
        />
      )
    } else if (currentStep === SelectedStep.PAYMENT) {
      return (
        <SelfEditorAIFlowPaymentContainer
          // TODO : name
          name="Test"
          url={url}
          startDate={startDate}
          endDate={endDate}
          budget={dailyBudget}
          headlines={headlines}
          keywords={keywords}
          descriptions={descriptions}
          handlePrevious={handleGoToDescriptionsGeneration}
        />
      )
    }
  }

  return (
    <AuthenticatedTemplate
      navigate={navigate}
      user={user}
      companyId={companyId ?? ''}
      isEditor={false}
      pageRef={pageRef}
      newUi
    >
      {renderPage()}
    </AuthenticatedTemplate>
  )
}

export default SelfEditorAIFlow

import React from 'react'

import { SelectedKeywordsListContainer } from './styles'
import { TagProps } from '../../../../../../components/Tag/new'
import TagList from '../../../../../../components/Tag/new/TagList'
import { TagActionEnum } from '../../../../../../components/Tag/new/types'

type SelectedKeywordsProps = {
  keywords: string[]
  onKeywordsChange: (keywords: string[]) => void
}

const SelectedKeywords = ({
  keywords,
  onKeywordsChange
}: SelectedKeywordsProps) => {
  const onKeywordDelete = (index: number) => {
    const updatedKeywords = [...keywords]
    updatedKeywords.splice(index, 1)
    onKeywordsChange(updatedKeywords)
  }

  const tagActions: TagProps['actions'] = [
    {
      onClick: (index: number) => {
        onKeywordDelete(index)
      },
      type: TagActionEnum.DELETE
    }
  ]

  return (
    <SelectedKeywordsListContainer>
      <TagList tags={keywords} actions={tagActions} />
    </SelectedKeywordsListContainer>
  )
}

export default SelectedKeywords

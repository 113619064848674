import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  LeftMenuAvatar,
  LeftMenuContainer,
  LeftMenuFirstIcon,
  LeftMenuIcon,
  Tooltip
} from './styles'
import HomePicto from '../../components/Icon/homePicto'
import AddPicto from '../../components/Icon/addPicto'
import AnalyticsPicto from '../../components/Icon/analyticsPicto'
import { MediaPlanPicto } from '../../components/Icon/mediaPlanPicto'
import Avatar from '../../components/Avatar'
import ErrorPage from '../../pages/ErrorPage'

type LeftMenuProps = {
  activeIndex?: number
  userInitials?: string
  companyId?: string
}

const LeftMenu = ({ activeIndex, userInitials, companyId }: LeftMenuProps) => {
  const navigate = useNavigate()

  const handleAddClick = () => {
    if (!companyId) {
      return (
        <ErrorPage
          message={'Une erreur est survenue'}
          action={{
            text: "Retour à la page d'accueil",
            onClick: () => {
              navigate('/')
            }
          }}
        />
      )
    }
    navigate(`/company/${companyId}/media-plan/create/ai`)
  }

  const handleHomeClick = () => {
    navigate('/')
  }

  const handleMediaPlanClick = () => {
    if (!companyId) {
      return (
        <ErrorPage
          message={'Une erreur est survenue'}
          action={{
            text: "Retour à la page d'accueil",
            onClick: () => {
              navigate('/')
            }
          }}
        />
      )
    }
    navigate(`/company/${companyId}/media-plan/list`)
  }

  const handleAnalyticsClick = () => {
    navigate('/analytics')
  }

  const handleClickProfile = () => {
    navigate('/profile')
  }

  return (
    <LeftMenuContainer>
      <LeftMenuFirstIcon onClick={handleAddClick} isActive={activeIndex === 0}>
        <AddPicto style={{ display: 'block' }} width="16px" height="16px" />
        <Tooltip>Nouveau</Tooltip>
      </LeftMenuFirstIcon>
      <LeftMenuIcon onClick={handleHomeClick} isActive={activeIndex === 1}>
        <HomePicto style={{ display: 'block' }} width="16px" height="16px" />
        <Tooltip>Accueil</Tooltip>
      </LeftMenuIcon>
      <LeftMenuIcon onClick={handleMediaPlanClick} isActive={activeIndex === 2}>
        <MediaPlanPicto
          style={{ display: 'block' }}
          width="16px"
          height="16px"
        />
        <Tooltip>Plan Média</Tooltip>
      </LeftMenuIcon>
      <LeftMenuIcon onClick={handleAnalyticsClick} isActive={activeIndex === 3}>
        <AnalyticsPicto
          style={{ display: 'block' }}
          width="16px"
          height="16px"
        />
        <Tooltip>Analytics</Tooltip>
      </LeftMenuIcon>
      <LeftMenuAvatar>
        <Avatar
          character={userInitials !== undefined ? userInitials : 'FB'}
          onClick={handleClickProfile}
        />
      </LeftMenuAvatar>
    </LeftMenuContainer>
  )
}

export default LeftMenu

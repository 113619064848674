import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Padding from '../../../../../constants/padding'
import Margin from '../../../../../constants/margin'

export const StyledWrapper = styled.div`
  padding: ${Padding.p3} ${Padding.p10} ${Padding.p10};
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: ${Margin.m8};
  display: flex;
  flex-direction: column;
`

export const titleStyle: CSSProperties = {
  textAlign: 'center',
  marginTop: Margin.m8,
  marginBottom: Margin.m7
}

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Margin.m8};
`

export const DurationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Margin.m4};
`

export const BudgetWrapper = styled(DurationWrapper)``

export const inputStyle: CSSProperties = {
  width: '100%',
  maxWidth: '400px'
}

export const invalidSectionTextStyle: CSSProperties = {
  marginTop: Margin.m4,
  textAlign: 'end'
}

export const RecapInfoItem = styled.span`
  font-weight: bold;
`

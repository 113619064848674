import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'
import { YETIC_NEW_FONT_FAMILY } from '../../../../constants/fontFamily'

export type NewStyledButtonProps = {
  marginBottom?: string
  marginTop?: string
  width?: string
  heigth?: string
  padding?: string
  large?: boolean
}

const NewStyledButton = styled.button<NewStyledButtonProps>`
  background-color: ${Colors.YETIC_BLUE_DARK};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;

  font-family: ${YETIC_NEW_FONT_FAMILY};
  font-size: ${(props) => (props.large ? '18px' : '14px')};
  font-weight: 500;

  line-height: 24px;

  width: ${(props) => (props.width ? props.width : 'fit-content')};
  height: ${(props) => (props.heigth ? props.heigth : 'fit-content')};

  padding: ${(props) =>
    props.padding ? props.padding : props.large ? '12px 24px' : '8px 16px'};
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}` : '0px'};
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}` : '')};

  border-radius: 6px;
  border: none;
  cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: background-color 0.3s ease;

  ${(props) =>
    props.disabled
      ? ''
      : `&:hover {
          background-color: ${Colors.YETIC_BLUE_DARKER};
        }`}
`

const NewStyledButtonSecondary = styled(NewStyledButton)<NewStyledButtonProps>`
  background-color: transparent;
  color: ${Colors.YETIC_BLUE_DARK};

  border: 1px solid ${Colors.YETIC_BLUE_DARK};

  ${(props) =>
    props.disabled
      ? ''
      : `&:hover {
        background-color: rgba(0, 0, 0, 0.05);
        color: ${Colors.YETIC_BLUE_DARK};
      }`}
`

const NewStyledButtonTertiary = styled(NewStyledButton)<NewStyledButtonProps>`
  background-color: ${Colors.YETIC_YELLOW_DARK};
  color: ${Colors.YETIC_BLUE_DARK};

  ${(props) =>
    props.disabled
      ? ''
      : `&:hover {
        background-color: ${Colors.YETIC_YELLOW_LIGHT};
      }`}
`

export { NewStyledButton, NewStyledButtonSecondary, NewStyledButtonTertiary }

import React from 'react'

import { StyledTag, TagActionButton, TagActions, textTagStyle } from './styles'
import P from '../../Text/Paragraph'
import {
  checkVoidFunctionHasNoParam,
  checkVoidFunctionHasNumberParam,
  checkVoidFunctionHasStringParam
} from '../../../helpers/checkers'
import { TagActionEnum } from './types'
import { actionStyleByEnum } from './styles/action'

export type TagProps = {
  id: string | number
  children: string | number
  actions?: {
    onClick: (id: any) => void
    type: TagActionEnum
    disabled?: boolean
  }[]
}

const Tag = ({ id, children, actions }: TagProps) => {
  return (
    <StyledTag>
      <P small style={textTagStyle}>
        {children}
      </P>
      {actions && (
        <TagActions>
          {actions.map((action) => {
            const actionStyle = actionStyleByEnum[action.type]
            const ActionIcon = actionStyle.icon
            const onClick = action.onClick
            const updatedOnClick = action.disabled
              ? undefined
              : () => {
                  if (
                    typeof id === 'string' &&
                    checkVoidFunctionHasStringParam(onClick)
                  ) {
                    onClick(id)
                  } else if (
                    typeof id === 'number' &&
                    checkVoidFunctionHasNumberParam(onClick)
                  ) {
                    onClick(id)
                  } else if (checkVoidFunctionHasNoParam(onClick)) {
                    onClick()
                  }
                }

            return (
              <TagActionButton
                key={Math.random()}
                onClick={updatedOnClick}
                backgroundColor={actionStyle.backgroundColor}
                hoverBackgroundColor={actionStyle.hoverBackgroundColor}
                disabled={action.disabled}
              >
                <ActionIcon />
              </TagActionButton>
            )
          })}
        </TagActions>
      )}
    </StyledTag>
  )
}

export default Tag

/*
export const mockedKeywords = [
  'gestion RH',
  'solution IA',
  'intelligence artificielle RH',
  'optimisation des RH',
  'logiciel RH',
  'gestion des ressources humaines',
  'outil IA RH',
  'recrutement IA',
  'formation RH',
  'évaluation des performances',
  'efficacité RH',
  'productivité RH',
  'gestion des employés',
  'automatisation RH',
  'système RH',
  'logiciel de recrutement',
  'plateforme RH',
  'technologie RH'
]
*/
export const mockedKeywords = [
  'brasserie Rouen',
  'yéti du comptoir',
  'brasserie livraison',
  'poutine livraison',
  'poutine rouen',
  'commandez poutine',
  'brasserie comptoir rouen',
  'poutine maison rouen',
  'livraison repas rouen',
  'brasserie',
  'poutine à emporter rouen',
  'brasserie rouen livraison',
  'restaurant livraison rouen',
  'restaurant poutine rouen',
  'repas à emporter rouen'
]

export const minNumberOfKeywords = 3
export const maxNumberOfKeywords = 15

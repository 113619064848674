export const isDayValid = (day: string) => {
  const numDay = Number(day)

  if (day.length < 2) {
    return true
  }

  if (!isNaN(numDay)) {
    if (numDay > 0 && numDay < 32) {
      return true
    }
  }

  return false
}

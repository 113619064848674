import styled from '@emotion/styled'

import Colors from '../../../../../../../constants/colors'

export const SelectedTitlesContainer = styled.div`
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.NEW_YETIC_GREY};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: ${Colors.YETIC_WHITE};
  }
`

export const EditingTitleModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  width: 300px;
`

export const EditingTitleModalActions = styled.div`
  display: flex;
  gap: 16px;
`

import React from 'react'

import {
  SelfEditorAIFlowSummaryContainer,
  StyledAIFlowSummaryResult,
  StyledAIFlowSummaryResultContainer,
  StyledAIFlowSummaryTitle
} from './styles'

type TableListProps = {
  data: Record<string, string>
}

const TableList = ({ data }: TableListProps) => {
  return (
    <table>
      <SelfEditorAIFlowSummaryContainer>
        {Object.entries(data).map(([key, value]: [string, string]) => (
          <tr key={key}>
            <StyledAIFlowSummaryTitle>
              {`${key[0].toUpperCase() + key.slice(1).toLocaleLowerCase()}:`}
            </StyledAIFlowSummaryTitle>
            <StyledAIFlowSummaryResultContainer>
              <StyledAIFlowSummaryResult>{value}</StyledAIFlowSummaryResult>
            </StyledAIFlowSummaryResultContainer>
          </tr>
        ))}
      </SelfEditorAIFlowSummaryContainer>
    </table>
  )
}

export default TableList

import React, { useState } from 'react'

import Modal from '../../../../../components/Modal'
import H1 from '../../../../../components/Text/Headlines/H1'
import AIFlowSummary from '../../components/Summary/Content'

type SelfEditorAIFlowCampaignParamsModalContentProps = {
  onClose: () => void
  summary: Record<string, string>
}

const SelfEditorAIFlowCampaignParamsModalContent = ({
  onClose,
  summary
}: SelfEditorAIFlowCampaignParamsModalContentProps) => {
  return (
    <Modal onClose={onClose} style={{ width: '40vw', height: 'fit-content' }}>
      <H1
        style={{ marginBottom: '24px' }}
      >{`Récapitulatif des données utilisées par l'IA :`}</H1>
      <AIFlowSummary summary={summary} />
    </Modal>
  )
}

export default SelfEditorAIFlowCampaignParamsModalContent

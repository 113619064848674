import React from 'react'

import Button from '../../../../components/Button'
import { StyledCenteredDiv, StyledContent, StyledWrapper } from './styles'
import H1 from '../../../../components/Text/Headlines/H1'
import P from '../../../../components/Text/Paragraph'
import Colors from '../../../../constants/colors'
import Stepper from '../../../../components/Stepper'
import { aiFlowSteps } from '../constants'
import Margin from '../../../../constants/margin'
import { StarIconWrapper } from '../SelfEditorAIFlowIntroductionGen/styles'
import { ChronometerIcon } from '../../../../components/Icon'

const SelfEditorAIFlowEstimatedTime = ({
  handleClick
}: {
  handleClick: () => void
}) => (
  <StyledWrapper>
    <Stepper steps={aiFlowSteps} currentIndex={0} marginBottom={Margin.m8} />
    <StyledContent>
      <StarIconWrapper>
        <ChronometerIcon
          width="100%"
          height="100%"
          color={Colors.YETIC_BLUE_DARK}
        />
      </StarIconWrapper>
      <H1>{`Questionnaire IA`}</H1>
      <P style={{ marginTop: Margin.m5, marginBottom: Margin.m5 }}>
        {`C'est facile ! Vous avez juste à répondre à quelques questions.`}
        <br />
        {`Cela vous prendra `}
        <span style={{ fontWeight: 'bold', marginLeft: Margin.m1 }}>
          5 minutes
        </span>.
      </P>
      <StyledCenteredDiv>
        <Button
          newFont
          type="primary"
          onClick={handleClick}
          large
          style={{ width: 'fit-content' }}
        >
          Commencer
        </Button>
      </StyledCenteredDiv>
    </StyledContent>
  </StyledWrapper>
)

export default SelfEditorAIFlowEstimatedTime
